import has from './has';

const alphanumSort = require('alphanum-sort');
const arraySort = require('array-sort');

function withCompare(param, opts) {
  function WithCompare(a, b) {
    return alphanumSort([a[param], b[param]], opts)[0] === a[param] ? -1 : 1;
  }
  return WithCompare;
}

function compareDevices(a, b) {
  const s0 = has.call(a, 'channel')
    ? `${a.lane}:${a.address}:${a.channel}:${a.instance.id}`
    : `${a.lane}:${a.address}:${a.instance.id}`;
  const s1 = has.call(b, 'channel')
    ? `${b.lane}:${b.address}:${b.channel}:${b.instance.id}`
    : `${b.lane}:${b.address}:${b.instance.id}`;
  return alphanumSort([s0, s1])[0] === s0 ? -1 : 1;
}

export function sortByName(items, nameProp = 'name') {
  return arraySort(items, withCompare(nameProp, { insensitive: true }));
}

export function sortDevices(items) {
  return arraySort(items, compareDevices);
}

import PropTypes from 'prop-types';
import React from 'react';
import AnalogOutsAddMulti from './AnalogOutsAddMulti';
import AnalogOutsAddSingle from './AnalogOutsAddSingle';
import { apiAddAnalogOuts, apiGetZones } from './utils/api';

class AnalogOutsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zones: [],
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    apiGetZones().then((zones) => {
      if (this.mounted) {
        this.setState({
          zones,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSaveClick(value) {
    const { addCount, handleSaveClick: handleSaveClickProp } = this.props;

    apiAddAnalogOuts(value).then((data) => {
      if (this.mounted) {
        if (addCount === 1 && data) {
          handleSaveClickProp(data.config.id);
        } else {
          handleSaveClickProp();
        }
      }
    });
  }

  render() {
    const { addCount, handleCloseClick } = this.props;
    const { zones } = this.state;

    return addCount === 1 ? (
      <AnalogOutsAddSingle handleCloseClick={handleCloseClick} handleSaveClick={this.handleSaveClick} zones={zones} />
    ) : (
      <AnalogOutsAddMulti
        addCount={addCount}
        handleCloseClick={handleCloseClick}
        handleSaveClick={this.handleSaveClick}
      />
    );
  }
}

AnalogOutsAdd.propTypes = {
  addCount: PropTypes.number.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default AnalogOutsAdd;

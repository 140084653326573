import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  spacing: {
    marginLeft: theme.spacing(1),
  },
});

function SpacedText(props) {
  const { classes, text1, text2 } = props;

  return (
    <>
      {text1}
      <span className={classes.spacing}>{text2}</span>
    </>
  );
}

SpacedText.propTypes = {
  classes: PropTypes.object.isRequired,
  text1: PropTypes.node.isRequired,
  text2: PropTypes.node.isRequired,
};

export default withStyles(styles)(SpacedText);

import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  scroll: {
    overflowY: 'auto',
  },
};

class Scroll extends React.Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
    this.scrollTop = 0;

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { forwardRef, initialScrollTop } = this.props;

    if (forwardRef) {
      if (typeof forwardRef === 'function') {
        forwardRef(this.scrollRef.current);
      } else {
        forwardRef.current = this.scrollRef.current;
      }
    }

    this.scrollRef.current.scrollTop = initialScrollTop === -1
      ? this.scrollRef.current.scrollHeight - this.scrollRef.current.offsetHeight
      : initialScrollTop;
    this.scrollRef.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const { handleSaveScrollTop } = this.props;

    this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    if (handleSaveScrollTop) handleSaveScrollTop(this.scrollTop);
  }

  handleScroll(event) {
    this.scrollTop = event.target.scrollTop;
  }

  render() {
    const { children, classes, className } = this.props;

    return (
      <div className={clsx(classes.scroll, className)} ref={this.scrollRef}>
        {children}
      </div>
    );
  }
}

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  handleSaveScrollTop: PropTypes.func,
  initialScrollTop: PropTypes.number,
};

Scroll.defaultProps = {
  forwardRef: undefined,
  handleSaveScrollTop: undefined,
  initialScrollTop: 0,
};

const WrappedScroll = withStyles(styles)(Scroll);

export default React.forwardRef((props, ref) => <WrappedScroll forwardRef={ref} {...props} />);

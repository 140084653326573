import { createBrowserHistory } from 'history';

class History {
  constructor() {
    this.history = createBrowserHistory();
    this.listeners = [];

    this.addListener = this.addListener.bind(this);
    this.defaultState = this.defaultState.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleOnBeforeUnload = this.handleOnBeforeUnload.bind(this);
    this.notifyListeners = this.notifyListeners.bind(this);
    this.push = this.push.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.replace = this.replace.bind(this);

    this.unlisten = this.history.listen(this.handleLocationChanged);

    window.addEventListener('onbeforeunload', this.handleOnBeforeUnload, false);
  }

  addListener(listener) {
    this.listeners.push(listener);
    listener(this.history.location.state || {});
  }

  defaultState(state) {
    this.history.replace('', { ...state, ...(this.history.location.state || {}) });
  }

  goBack() {
    this.history.goBack();
  }

  handleLocationChanged(location) {
    this.notifyListeners(location.state || {});
  }

  handleOnBeforeUnload() {
    this.unlisten();
    window.removeEventListener('onbeforeunload', this.handleOnBeforeUnload, false);
  }

  notifyListeners(state) {
    this.listeners.slice(0).forEach((item) => {
      if (this.listeners.includes(item)) item(state);
    });
  }

  push(state) {
    this.history.push('', state);
  }

  pushAppend(state) {
    this.history.push('', { ...(this.history.location.state || {}), ...state });
  }

  removeListener(listener) {
    const index = this.listeners.indexOf(listener);
    if (index > -1) this.listeners.splice(index, 1);
  }

  replace(state) {
    this.history.replace('', state);
  }

  replaceInline(state) {
    this.history.replace('', { ...(this.history.location.state || {}), ...state });
  }
}

export default new History();

import MuiListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  subheader: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
});

function ListSubheader(props) {
  const {
    children, classes, className, ...other
  } = props;

  return (
    <MuiListSubheader className={clsx(classes.subheader, className)} disableSticky {...other}>
      {children}
    </MuiListSubheader>
  );
}

ListSubheader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ListSubheader.defaultProps = {
  className: undefined,
};

export default withStyles(styles)(ListSubheader);

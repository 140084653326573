import PropTypes from 'prop-types';
import React from 'react';
import EditDialog from './components/EditDialog';
import EditKeyboard from './components/EditKeyboard';
import SensorTypeName from './components/SensorTypeName';
import SensorsDetails from './SensorsDetails';
import { apiDeleteSensor, apiGetSettings, apiGetZones, apiModifySensor } from './utils/api';
import deepCopy from './utils/deepCopy';
import has from './utils/has';
import history from './utils/history';
import { optionsChannel, optionsChannel2, optionsLane, optionsSensorType } from './utils/options';
import { patternDecimal1, patternNumber } from './utils/patterns';
import { valueText } from './utils/utils';

class Sensors extends React.Component {
  constructor(props) {
    super(props);

    const { sensor } = props;

    this.state = {
      edit: undefined,
      metadata: null,
      options: [],
      sensor,
    };

    this.description = this.description.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditItemClick = this.handleEditItemClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.subtitle = this.subtitle.bind(this);
    this.units = this.units.bind(this);
    this.value = this.value.bind(this);
    this.valueDialog = this.valueDialog.bind(this);

    history.defaultState({ metadata: null });
  }

  componentDidMount() {
    this.mounted = true;

    history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    this.mounted = false;

    history.removeListener(this.handleLocationChanged);
  }

  description() {
    const { metadata, sensor } = this.state;

    if (metadata && sensor) {
      const { index, indexId } = metadata;
      if (index === 'alarms') {
        const defaultAlarm = sensor.config.sensorType.alarms.find(item => item.id === indexId);
        return defaultAlarm
          ? `Default set point is ${valueText(defaultAlarm.setPoint, 1, sensor.config.sensorType.units)}`
          : undefined;
      }

      if (index === 'alarmPoints') {
        const defaultAlarmPoints = sensor.config.sensorType.alarms.length;
        return defaultAlarmPoints
          ? `Default alarm points is ${sensor.config.sensorType.alarms.length}`
          : undefined;
      }

    }
    return undefined;
  }

  handleChangeItem(value, item) {
    const { id } = this.props;
    const { options, sensor } = this.state;

    const closeDialogOrGoBack = () => {
      if (options.length !== 0) {
        this.setState({ edit: false });
      } else {
        history.goBack();
      }
    };

    if (!item) {
      closeDialogOrGoBack();
      return;
    }

    const { index, index2, indexId } = item;
    let value2 = {};

    if (index === 'alarms') {
      const newValue = deepCopy(sensor.config[index]).reduce((accumulator, item2) => {
        if (item2.id === indexId) {
          if (value !== '') accumulator.push({ ...item2, 'delay': 0, [index2]: Number(value) });
        } else {
          accumulator.push(item2);
        }
        return accumulator;
      }, []);
      if (value !== '' && !newValue.find(item2 => item2.id === indexId)) {
        newValue.push({ id: indexId, 'delay': 0, [index2]: Number(value) });
      }
      if (JSON.stringify(newValue) === JSON.stringify(sensor.config[index])) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { [index]: newValue };
    } else if (index === 'alarmPoints') {
      const newValue = value === '' ? null : Number(value);
      if (newValue === sensor.config[index]) {
        closeDialogOrGoBack();
        return;
      }
      const newAlarm = deepCopy(sensor.config['alarms']);
      
      let newSetPoint = 0;
      if (sensor.config.sensorType.alarms.length === 1) {
        newSetPoint = sensor.config.sensorType.alarms[0].setPoint;
      }
      else {
        newSetPoint = sensor.config.sensorType.alarms[1].setPoint;
      }

      if (newValue > sensor.config.sensorType.alarms.length) {
        newAlarm.push({ id: Number(newValue), 'delay': 0, 'setPoint': Number(newSetPoint)});
        value2 = { 'alarms': newAlarm };
      }
      else {
        value2 = { 'alarms': [] };
      }
      apiModifySensor(id, value2);
      value2 = { [index]: newValue };
    }
    else if (index === 'zone') {
      const newValue = value === '' ? null : Number(value);
      if (
        (newValue === null && sensor.config[index] === null)
        || (sensor.config[index] !== null && newValue === sensor.config[index].id)
      ) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { zoneId: newValue };
    } else {
      const newValue = ['enabled', 'type'].includes(index) ? value : Number(value);
      if (newValue === sensor.config[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { [index]: newValue };
    }

    if (has.call(item, 'value')) {
      Object.assign(value2, item.value);
    }

    if (index === 'type' && ((value2.type === 'BC8AII') || (value2.type === 'DTR'))) {
      this.handleEditItemClick({ label: 'Modbus channel', index: 'channel', value: value2 });
      return;
    }

    apiModifySensor(id, value2).then((data) => {
      if (this.mounted) {
        if (data) this.setState({ sensor: data });
        if (index !== 'enabled') closeDialogOrGoBack();
      }
    });
  }

  handleDeleteClick() {
    const { handleBackClick, id } = this.props;

    apiDeleteSensor(id).then(() => {
      if (this.mounted) {
        this.setState({ edit: false });
      }
    });

    handleBackClick();
  }

  handleEditItemClick(item) {
    const { sensor } = this.state;

    const { index } = item;

    if (index === 'channel') {
      if (sensor.config.type === 'DTR') {
        this.setState({ edit: true, metadata: item, options: optionsChannel2 });
      }
      else {
        this.setState({ edit: true, metadata: item, options: optionsChannel });
      }
    } else if (index === 'lane') {
      this.setState({ edit: true, metadata: item, options: optionsLane });
    } else if (index === 'sensorTypeId') {
      apiGetSettings().then((settings) => {
        if (this.mounted) {
          this.setState({
            edit: true,
            metadata: item,
            options: settings.sensorTypes
              .filter(
                value => sensor.config.type !== 'AMC-400'
                  || (has.call(value, 'amc400Id') && value.amc400Id !== undefined && value.amc400Id !== null),
              )
              .map(value => ({
                label: <SensorTypeName name={value.name} />,
                secondary: value.description,
                value: value.id,
              })),
          });
        }
      });
    } else if (index === 'type') {
      this.setState({ edit: true, metadata: item, options: optionsSensorType });
    } else if (index === 'zone') {
      apiGetZones().then((zones) => {
        if (this.mounted) {
          const options = zones.map(value => ({ label: value.name, value: value.id }));
          options.push({ label: 'None', value: '' });
          this.setState({
            edit: true,
            metadata: item,
            options,
          });
        }
      });
    } else {
      history.pushAppend({ metadata: item, options: [] });
    }
  }

  handleLocationChanged(value) {
    const { metadata, options } = value;

    if (metadata) {
      this.setState({ edit: true, metadata, options });
    } else {
      this.setState({ edit: false });
    }
  }

  handleSaveClick(value) {
    const { metadata } = this.state;

    return this.handleChangeItem(value, metadata);
  }

  inputCheck(value) {
    const { metadata, sensor } = this.state;

    if (metadata.index === 'address') {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    if (metadata.index === 'type' && value === 'AMC-400') {
      if (
        !has.call(sensor.config.sensorType, 'amc400Id')
        || sensor.config.sensorType.amc400Id === undefined
        || sensor.config.sensorType.amc400Id === null
      ) {
        return 'AMC-400 device must be associated with a sensor type which has a valid AMC-400 type.';
      }
    }

    if (metadata.index === 'alarmPoints') {
      const points = Number(value);
      if ( points < 1 || points > 3) {
        return 'Invalid number of alarm points. Valid range is 1 to 3.';
      }
    }

    return null;
  }

  inputPattern() {
    const { metadata } = this.state;

    if (metadata) {
      if (metadata.index === 'address') {
        return patternNumber;
      }

      if (metadata.index2 === 'id') {
        return patternDecimal1;
      }

      if (metadata.index2 === 'setPoint') {
        return patternDecimal1;
      }
    }

    return undefined;
  }

  subtitle() {
    const { metadata } = this.state;

    if (metadata) {
      const { index2, indexId } = metadata;

      if (index2 === 'id') {
        return `Alarm Points ${indexId} override`;
      }
  
      if (index2 === 'setPoint') {
        return `Alarm ${indexId} override`;
      }
    }

    return undefined;
  }

  units() {
    const { metadata, sensor } = this.state;

    if (metadata && sensor) {
      const { index2 } = metadata;
      if (index2 === 'setPoint') {
        return sensor.config.sensorType.units;
      }
    }

    return undefined;
  }

  value() {
    const { metadata, sensor } = this.state;

    if (metadata && sensor) {
      const { index, index2, indexId } = metadata;
      return index === 'alarms'
        ? valueText(
          (has.call(sensor.config, index)
            && sensor.config[index].find(item => item.id === indexId)
            && sensor.config[index].find(item => item.id === indexId)[index2])
          || NaN,
          1,
        ) 

        : ( index === 'alarmPoints'
            ? sensor.config.sensorType.alarms.length
            : sensor.config[index] 
          )

    }

    return '';
  }

  valueDialog() {
    const { metadata, options, sensor } = this.state;

    if (metadata && sensor && options.length !== 0) {
      const { index, indexId } = metadata;
      if (index === 'zone') {
        return sensor.config[index] === null ? '' : sensor.config[index].id;
      }
      if (indexId !== undefined) {
        return sensor.config[index].find(item => item.id === indexId).id;
      }
      return sensor.config[index] === null || sensor.config[index] === undefined || sensor.config[index].length === 0
        ? ''
        : sensor.config[index];
    }

    return '';
  }

  render() {
    const { handleBackClick, id } = this.props;
    const {
      edit, metadata, options, sensor,
    } = this.state;

    if (edit === undefined) return null;

    return (
      <>
        {edit && options.length === 0 && (
          <EditKeyboard
            allowNone={metadata.index === 'alarms' || metadata.index === 'alarmPoints'}
            description={this.description()}
            handleCloseClick={() => {
              history.goBack();
            }}
            handleSaveClick={this.handleSaveClick}
            inputCheck={this.inputCheck}
            inputPattern={this.inputPattern()}
            label={metadata.label}
            subtitle={this.subtitle()}

            title={
              metadata.index === 'alarmPoints'
              ? ( `Edit Sensor ${id} Alarms Points` )
              : ( `Edit Sensor ${id}` )
              }
            units={this.units()}
            value={this.value()}

          />
        )}
        {(!edit || (edit && options.length !== 0)) && (
          <SensorsDetails
            handleBackClick={handleBackClick}
            handleChangeItem={this.handleChangeItem}
            handleDeleteClick={this.handleDeleteClick}
            handleEditItemClick={this.handleEditItemClick}
            id={id}
            sensor={sensor}
          />
        )}
        <EditDialog
          handleCloseClick={() => {
            this.setState({ edit: false });
          }}
          handleSaveClick={this.handleSaveClick}
          inputCheck={this.inputCheck}
          label={metadata && has.call(metadata, 'label') ? metadata.label : ''}
          open={edit && options.length !== 0}
          options={options}
          value={this.valueDialog()}
        />
      </>
    );
  }
}

Sensors.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  sensor: PropTypes.object.isRequired,
};

export default Sensors;

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import TextField from '@material-ui/core/TextField';
import { apiAccountExists, apiSignIn } from './utils/api';
import { validEmail } from './utils/email';
import { themeButton } from './utils/theme';

const styles = theme => ({
  button: {
    borderRadius: 80,
    marginTop: 44,
    padding: '12px 32px',
  },
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: 384,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(10.5),
  },
  input: {
    display: 'none',
  },
  textField: {
    fontSize: theme.typography.pxToRem(24),
  },
  logo: {
    fontSize: 56,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: undefined,
      password: '',
      showPassword: false,
      step: 0,
    };

    this.inputRef = React.createRef();
    this.selection = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleVisibilityClick = this.handleVisibilityClick.bind(this);
  }

  componentDidUpdate() {
    const { step } = this.state;

    if (step === 1) {
      if (this.selection) {
        const { selectionStart, selectionEnd, selectionDirection } = this.selection;
        this.inputRef.current.focus();
        this.inputRef.current.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
        this.selection = null;
      } else {
        this.inputRef.current.focus();
      }
    }
  }

  handleChange(param) {
    return (event) => {
      if (param === 'email') {
        this.handleEmailChange(event.target.value);
      } else {
        this.setState({ [param]: event.target.value });
      }
    };
  }

  handleClick() {
    const { email, password, step } = this.state;

    if (step === 0) {
      if (email === '') {
        this.setState({ error: 'Enter an email' });
        this.inputRef.current.focus();
        return;
      }

      if (!validEmail(email)) {
        this.setState({ error: 'Enter a valid email' });
        this.inputRef.current.focus();
        return;
      }

      apiAccountExists(email)
        .then(exists => this.setState({
          step: exists ? 1 : 0,
          error: exists ? undefined : "Couldn't find your account",
        }))
        .catch(() => {
          this.setState({ error: undefined });
        });
    } else {
      if (password === '') {
        this.setState({ error: 'Enter a password' });
        this.inputRef.current.focus();
        return;
      }

      apiSignIn(email, password).catch(() => {
        this.setState({ error: 'Wrong password. Try again.' });
        this.inputRef.current.focus();
      });
    }
  }

  handleEmailChange(email) {
    this.setState({ email: email.toLowerCase() });
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleClick();
    }
  }

  handleVisibilityClick() {
    const { showPassword } = this.state;

    const { selectionStart, selectionEnd, selectionDirection } = this.inputRef.current;
    this.selection = { selectionStart, selectionEnd, selectionDirection };
    this.setState({ showPassword: !showPassword });
  }

  render() {
    const { classes } = this.props;
    const {
      email, error, password, showPassword, step,
    } = this.state;

    return (
      <div className={classes.container}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <div className={classes.content}>
          <Typography className={classes.logo}>Monitor Configuration Tool</Typography>
          {step === 0 && (
            <div className={classes.form}>
              <TextField
                autoComplete="email"
                autoFocus
                className={classes.textField}
                error={error !== undefined}
                fullWidth
                helperText={error}
                InputProps={{
                  inputProps: {
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                  },
                }}
                inputRef={this.inputRef}
                onChange={this.handleChange('email')}
                placeholder="Enter email"
                type="email"
                value={email}
              />
              <ThemeProvider theme={themeButton}>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={this.handleClick}
                  size="large"
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                >
                  Next
                </Button>
              </ThemeProvider>
            </div>
          )}
          {step === 1 && (
            <form className={classes.form}>
              <input autoComplete="email" className={classes.input} readOnly value={email} />
              <TextField
                autoComplete="current-password"
                className={classes.textField}
                error={error !== undefined}
                fullWidth
                helperText={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleVisibilityClick}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                  },
                }}
                inputRef={this.inputRef}
                onChange={this.handleChange('password')}
                placeholder="Enter password"
                type={showPassword ? 'text' : 'password'}
                value={password}
              />
              <ThemeProvider theme={themeButton}>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={this.handleClick}
                  size="large"
                  variant="contained"
                  disableRipple
                  fullWidth
                >
                  Next
                </Button>
              </ThemeProvider>
            </form>
          )}
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import AppBar2 from './AppBar2';

const styles = theme => ({
  form: {
    width: 448,
    paddingTop: theme.spacing(10.5),
  },
  formSubtitle: {
    paddingTop: theme.spacing(4),
  },
  formWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - 230px)`,
    display: 'flex',
    justifyContent: 'center',
    ...theme.mixins.gutters(),
  },
  input: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: 4,
  },
  label: {
    color: theme.palette.secondary.main,
    lineHeight: 1,
  },
  subtitle: {
    marginBottom: 13,
  },
  wrapper: {
    height: '100%',
  },
});

class EditKeyboard extends React.Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      error: null,
      value,
    };

    this.inputRef = React.createRef();

    this.disabledSave = this.disabledSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidUpdate() {
    this.inputRef.current.focus();
  }

  disabledSave() {
    const { allowNone } = this.props;
    const { value } = this.state;

    return value === '' && !allowNone;
  }

  handleChange(event) {
    const { inputPattern } = this.props;

    const { value } = event.target;

    if (value.length === 0 || inputPattern === undefined || inputPattern.test(value)) {
      this.setState({ value });
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.disabledSave()) this.handleSaveClick();
    }
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp, inputCheck } = this.props;
    const { value } = this.state;

    if (inputCheck) {
      const error = inputCheck(value);
      this.setState({ error });
      if (error) {
        return;
      }
    }

    handleSaveClickProp(value);
    this.setState({ value: '' });
  }

  render() {
    const {
      buttonText,
      classes,
      description,
      handleCloseClick,
      helperText,
      label,
      subtitle,
      title,
      units,
    } = this.props;
    const { error, value } = this.state;

    return (
      <div className={classes.wrapper}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <AppBar2
          buttonText={buttonText}
          disabledSave={this.disabledSave()}
          handleCloseClick={handleCloseClick}
          handleSaveClick={this.handleSaveClick}
          title={title}
        />
        <div className={classes.formWrapper}>
          <div className={clsx(classes.form, subtitle && classes.formSubtitle)}>
            {subtitle && (
              <Typography align="center" className={classes.subtitle} noWrap variant="h6">
                {subtitle}
              </Typography>
            )}
            <Typography className={classes.label} variant="caption">
              {label}
            </Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              endAdornment={units !== undefined ? <InputAdornment position="end">{units}</InputAdornment> : null}
              fullWidth
              inputProps={{
                autoCapitalize: 'off',
                autoCorrect: 'off',
                spellCheck: false,
              }}
              inputRef={this.inputRef}
              onChange={this.handleChange}
              type="text"
              value={value}
            />
            {error || helperText ? (
              <Typography color="error" variant="caption">
                {error || helperText}
              </Typography>
            ) : (
              <>
                {description && (
                  <Typography color="textSecondary" variant="caption">
                    {description}
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EditKeyboard.propTypes = {
  allowNone: PropTypes.bool,
  buttonText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  inputCheck: PropTypes.func,
  inputPattern: PropTypes.object,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.any,
};

EditKeyboard.defaultProps = {
  allowNone: false,
  buttonText: undefined,
  description: undefined,
  helperText: undefined,
  inputCheck: undefined,
  inputPattern: undefined,
  subtitle: undefined,
  units: undefined,
  value: '',
};

export default withStyles(styles)(EditKeyboard);

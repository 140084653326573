import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import ListSubheader from './components/ListSubheader';
import Scroll from './components/Scroll';
import SensorTypeName from './components/SensorTypeName';
import { AbilityContext } from './utils/auth';
import { delayText, displayCalibrationText, methodText } from './utils/utils';
import { optionsTimezone } from './utils/options';

const styles = theme => ({
  divider: {
    padding: theme.spacing(0, 2),
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  info: {
    color: theme.palette.secondary.main,
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    paddingBottom: theme.spacing(1.5),
  },
  noValue: {
    fontStyle: 'italic',
  },
  paper: {
    width: 352,
  },
  wrapper: {
    height: '100%',
  },
});

class SettingsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.scrollTarget = undefined;
  }

  render() {
    const {
      classes,
      handleListItemClick,
      handleBackClick,
      handleEditItemClick,
      handleSaveScrollTop,
      handleShowAllClick,
      initialScrollTop,
      settings,
      lanes,
    } = this.props;

    const { label : timeZoneLabel } = optionsTimezone.find(item => item.value === settings.defaultTimeZone);
 
    let sensorTypes = [];

    if (settings) {
      sensorTypes = settings.sensorTypes.reduce((accumulator, item) => {
        if (item.sensorCount) accumulator.push(item);
        return accumulator;
      }, []);
      if (sensorTypes.length === 0) {
        sensorTypes.push(settings.sensorTypes.find(item => item.name === 'CO'));
        sensorTypes.push(settings.sensorTypes.find(item => item.name === 'NO_2'));
      }
    }

    return (
      <AbilityContext.Consumer>
        {(ability) => {
          const disabled = !ability.can('update', 'settings');
          const color = disabled ? 'textSecondary' : 'initial';
          return (
            <div className={classes.wrapper}>
              <ElevationScroll target={this.scrollTarget}>
                <AppBar handleBackClick={handleBackClick} title="Settings" />
              </ElevationScroll>
              <Scroll
                className={classes.listWrapper}
                handleSaveScrollTop={handleSaveScrollTop}
                initialScrollTop={initialScrollTop}
                ref={(node) => {
                  if (node) {
                    this.scrollTarget = node;
                  }
                }}
              >
                {settings && settings.sensorTypes ? (
                  <>
                    <List className={classes.list}>
                      <div className={classes.listContent}>
                        <ListSubheader disableSticky>General Settings</ListSubheader>
                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Site name', index: 'siteName' })}
                          >
                            <ListItemText
                              disableTypography
                              primary={<Typography color={color}>Site name</Typography>}
                              secondary={
                                settings.siteName ? (
                                  <Typography variant="body2" color="textSecondary">
                                    {settings.siteName}
                                  </Typography>
                                ) : (
                                  <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                    None
                                  </Typography>
                                )
                              }
                            />
                          </ButtonBase>
                        </ListItem>

                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Default Time Zone', index: 'defaultTimeZone' })
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={<Typography color={color}>Default Time Zone</Typography>}
                              secondary= {
                                settings.defaultTimeZone ? (
                                  <Typography variant="body2" color="textSecondary">
                                    {timeZoneLabel}                               
                                  </Typography>
                                ) : (
                                  <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                    none
                                  </Typography>
                                )
                              }
                            />
                          </ButtonBase>
                        </ListItem>

                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Power up alarm delay', index: 'powerUpAlarmDelay' })
                            }
                          >
                            <ListItemText
                              primary="Power up alarm delay"
                              primaryTypographyProps={{ color }}
                              secondary={delayText(settings.powerUpAlarmDelay)}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Calibration', index: 'displayCalibration' })}
                          >
                            <ListItemText
                              primary="Calibration"
                              primaryTypographyProps={{ color }}
                              secondary={displayCalibrationText(settings.displayCalibration)}
                            />
                          </ButtonBase>
                        </ListItem>
                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Fault relay', index: 'fault', index2: 'relay' })
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={<Typography color={color}>Fault relay</Typography>}
                              secondary={
                                settings.fault.relay !== null ? (
                                  <Typography variant="body2" color="textSecondary">
                                    {`Relay ${settings.fault.relay}`}
                                  </Typography>
                                ) : (
                                  <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                    None
                                  </Typography>
                                )
                              }
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Fault delay', index: 'fault', index2: 'delay' })
                            }
                          >
                            <ListItemText
                              primary="Fault delay"
                              primaryTypographyProps={{ color }}
                              secondary={delayText(settings.fault.delay)}
                            />
                          </ButtonBase>
                        </ListItem>
                      </div>
                    </List>

                    <div className={classes.divider}>
                      <Divider />
                    </div>
                    <List className={classes.list}>
                      <div className={classes.listContent}>
                        <ListSubheader disableSticky>Network</ListSubheader>
                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({
                              label: 'network IP assignment',
                              lowerCase: false,
                              index: 'network',
                              index2: 'method',
                            })
                            }
                          >
                            <ListItemText
                              primary="IP assignment"
                              primaryTypographyProps={{ color }}
                              secondary={methodText(settings.network.method)}
                            />
                          </ButtonBase>
                        </ListItem>
                        {settings.network.method === 'manual' && (
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Network IPv4 address',
                                index: 'network',
                                index2: 'address',
                              })
                              }
                            >
                              <ListItemText
                                primary="IPv4 address"
                                primaryTypographyProps={{ color }}
                                secondary={settings.network.address}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Network gateway',
                                index: 'network',
                                index2: 'gateway',
                              })
                              }
                            >
                              <ListItemText
                                primary="Gateway"
                                primaryTypographyProps={{ color }}
                                secondary={settings.network.gateway}
                              />
                            </ButtonBase>
                          </ListItem>
                        )}
                      </div>
                    </List>

                    <div className={classes.divider}>
                      <Divider />
                    </div>
                    <List className={classes.list}>
                      <div className={classes.listContent}>
                        <ListSubheader disableSticky>BACnet</ListSubheader>
                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({
                              label: 'BACnet device name',
                              index: 'bacnet',
                              index2: 'deviceName',
                            })
                            }
                          >
                            <ListItemText
                              primary="Device name"
                              primaryTypographyProps={{ color }}
                              secondary={settings.bacnet.deviceName}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'BACnet device ID', index: 'bacnet', index2: 'deviceId' })
                            }
                          >
                            <ListItemText
                              primary="Device ID"
                              primaryTypographyProps={{ color }}
                              secondary={settings.bacnet.deviceId}
                            />
                          </ButtonBase>
                        </ListItem>
                        <ListItem>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'BACnet port', index: 'bacnet', index2: 'port' })
                            }
                          >
                            <ListItemText
                              primary="Port"
                              primaryTypographyProps={{ color }}
                              secondary={settings.bacnet.port}
                            />
                          </ButtonBase>
                        </ListItem>
                      </div>
                    </List>


                    <div className={classes.divider}>
                      <Divider />
                    </div>
                      <List className={classes.list}>
                      <div className={classes.listContent}>
                        <ListSubheader disableSticky>MODBUS Lane</ListSubheader>
                        <ListItem>
                          <ListItemText
                            className={classes.listItem}
                            primary="ID"
                            primaryTypographyProps={{ color }}
                            secondary={'1'}
                          />
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Baud Rate', index: 'baud', indexId: 0 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                primary={<Typography color={color}>Baud Rate</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[0] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[0].baud}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Parity', index: 'parity', indexId: 0 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                primary={<Typography color={color}>Parity</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[0] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[0].parity}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Byte Size', index: 'bytesize', indexId: 0 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                primary={<Typography color={color}>Byte Size</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[0] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[0].bytesize}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Stop Bits', index: 'stopbits', indexId: 0 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                primary={<Typography color={color}>Stop Bits</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[0] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[0].stopbits}
                                  </Typography>
                                )}
                             />
                          </ButtonBase>
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            className={classes.listItem}
                            secondary={'2'}
                          />
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'BAUD Rate', index: 'baud', indexId: 1 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[1] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[1].baud}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>  
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Parity', index: 'parity', indexId: 1 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    classNae={clsx(lanes[1] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[1].parity}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Byte Size', index: 'bytesize', indexId: 1 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[1] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[1].bytesize}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Stop Bits', index: 'stopbits', indexId: 1 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[1] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[1].stopbits}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            className={classes.listItem}
                            secondary={'3'}
                          />
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'BAUD Rate', index: 'baud', indexId: 2 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[2] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[2].baud}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Parity', index: 'parity', indexId: 2 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[2] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[2].parity}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Byte Size', index: 'bytesize', indexId: 2 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[2] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[2].bytesize}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Stop Bits', index: 'stopbits', indexId: 2 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[2] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[2].stopbits}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            className={classes.listItem}
                            secondary={'4'}
                          />
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'BAUD Rate', index: 'baud', indexId: 3 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[3] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[3].baud}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Parity', index: 'parity', indexId: 3 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[3] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[3].parity}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Byte Size', index: 'bytesize', indexId: 3 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[3] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[3].bytesize}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.listItem}
                            disabled={disabled}
                            onClick={() => handleEditItemClick({ label: 'Stop Bits', index: 'stopbits', indexId: 3 })}
                          >
                            <ListItemText
                              className={classes.listItem}
                                secondary={(
                                  <Typography
                                    className={clsx(lanes[3] && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                  {lanes[3].stopbits}
                                  </Typography>
                                )}
                            />
                          </ButtonBase>
                        </ListItem>
                      </div>
                    </List>
                    
                    <div className={classes.divider}>
                      <Divider />
                    </div>
                    <List className={classes.list}>
                      <div className={classes.listContent}>
                        <ListSubheader disableSticky>Sensor types</ListSubheader>
                        <ListItem className={classes.flexWrap}>
                          {sensorTypes.map(item => (
                            <ListItemText
                              className={classes.listItem}
                              key={item.id.toString()}
                              onClick={() => handleListItemClick(item.id)}
                              primary={<SensorTypeName name={item.name} />}
                              secondary={item.description}
                            />
                          ))}
                        </ListItem>
                        <ListItem>
                          <ButtonBase className={classes.listItem} onClick={handleShowAllClick}>
                            <ListItemText className={classes.info} primary="Show all sensor types" />
                          </ButtonBase>
                        </ListItem>
                      </div>
                    </List>
                  </>
                ) : (
                  <div />
                )}
              </Scroll>
            </div>
          );
        }}
      </AbilityContext.Consumer>
    );
  }
}

SettingsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleEditItemClick: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  handleShowAllClick: PropTypes.func.isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  settings: PropTypes.object,
};

SettingsDetails.defaultProps = {
  settings: undefined,
};

export default withStyles(styles)(SettingsDetails);

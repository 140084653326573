export const chipSmall = {
  fontSize: '16px',
};

export const iconSize = {
  height: 30,
  width: 30,
};

export const iconFabSize = {
  height: 36,
  width: 36,
};

export const tableHead = {
  fontSize: '14px',
};

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import AppBar2 from './AppBar2';

const styles = theme => ({
  form: {
    width: 448,
    paddingTop: theme.spacing(10.5),
  },
  formSubtitle: {
    paddingTop: theme.spacing(4),
  },
  formWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - 230px)`,
    display: 'flex',
    justifyContent: 'center',
    ...theme.mixins.gutters(),
  },
  input: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: 4,
  },
  label: {
    color: theme.palette.secondary.main,
    lineHeight: 1,
  },
  subtitle: {
    marginBottom: 13,
  },
  wrapper: {
    height: '100%',
  },
});

class AddKeyboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.inputRef = React.createRef();

    this.disabledNext = this.disabledNext.bind(this);
    this.disabledSave = this.disabledSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidUpdate() {
    this.inputRef.current.focus();
  }

  disabledNext() {
    const { disabledNext, handleNextClick, value } = this.props;

    return disabledNext || (handleNextClick && value === '');
  }

  disabledSave() {
    const { disabledSave, handleSaveClick, value } = this.props;
    return disabledSave || (handleSaveClick && value === '');
  }

  handleChange(event) {
    const { handleChange: handleChangeProp, inputPattern } = this.props;

    const { value } = event.target;

    if (value.length === 0 || inputPattern === undefined || inputPattern.test(value)) {
      handleChangeProp(value);
    }
  }

  handleBackClick() {
    const { handleBackClick: handleBackClickProp, inputCheck, value } = this.props;

    if (handleBackClickProp) {
      if (inputCheck) {
        const error = inputCheck(value);
        this.setState({ error });
        if (error) {
          return;
        }
      }
      handleBackClickProp(value);
    }
  }

  handleKeyDown(event) {
    const { handleNextClick, handleSaveClick } = this.props;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.disabledNext() && handleNextClick) this.handleNextClick();
      if (!this.disabledSave() && handleSaveClick) this.handleSaveClick();
    }
  }

  handleNextClick() {
    const { handleNextClick: handleNextClickProp, inputCheck, value } = this.props;

    if (handleNextClickProp) {
      if (inputCheck) {
        const error = inputCheck(value);
        this.setState({ error });
        if (error) {
          return;
        }
      }
      handleNextClickProp(value);
    }
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp, inputCheck, value } = this.props;

    if (handleSaveClickProp) {
      if (inputCheck) {
        const error = inputCheck(value);
        this.setState({ error });
        if (error) {
          return;
        }
      }
      handleSaveClickProp(value);
    }
  }

  render() {
    const {
      classes,
      disabledBack,
      handleBackClick,
      handleCloseClick,
      handleNextClick,
      handleSaveClick,
      label,
      subtitle,
      title,
      units,
      value,
    } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.wrapper}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <AppBar2
          disabledBack={disabledBack}
          disabledNext={this.disabledNext()}
          disabledSave={this.disabledSave()}
          handleBackClick={handleBackClick ? this.handleBackClick : undefined}
          handleCloseClick={handleCloseClick}
          handleNextClick={handleNextClick ? this.handleNextClick : undefined}
          handleSaveClick={handleSaveClick ? this.handleSaveClick : undefined}
          title={title}
        />
        <div className={classes.formWrapper}>
          <div className={clsx(classes.form, subtitle && classes.formSubtitle)}>
            {subtitle && (
              <Typography align="center" className={classes.subtitle} noWrap variant="h6">
                {subtitle}
              </Typography>
            )}
            <Typography className={classes.label} variant="caption">
              {label}
            </Typography>
            <Input
              autoFocus
              className={classes.input}
              disableUnderline
              endAdornment={units !== undefined ? <InputAdornment position="end">{units}</InputAdornment> : null}
              fullWidth
              inputProps={{
                autoCapitalize: 'off',
                autoCorrect: 'off',
                spellCheck: false,
              }}
              inputRef={this.inputRef}
              onChange={this.handleChange}
              value={value}
            />
            {error && (
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddKeyboard.propTypes = {
  classes: PropTypes.object.isRequired,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  disabledSave: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  inputCheck: PropTypes.func,
  inputPattern: PropTypes.object,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.any.isRequired,
};

AddKeyboard.defaultProps = {
  disabledBack: false,
  disabledNext: false,
  disabledSave: false,
  handleBackClick: undefined,
  handleNextClick: undefined,
  handleSaveClick: undefined,
  inputCheck: undefined,
  inputPattern: undefined,
  subtitle: undefined,
  units: undefined,
};

export default withStyles(styles)(AddKeyboard);

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  separator: {
    margin: '0 4px',
  },
};

function Separator(props) {
  const { classes } = props;

  return <span className={classes.separator}>•</span>;
}

Separator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Separator);

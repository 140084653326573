export const patternNumber = /^\d+?$/;

export const patternDecimal1 = /^[-+]?\d*(\.)?(\d)?$/;

export const patternDecimal1Pos = /^\d*(\.)?(\d)?$/;

export const patternDecimal2Pos = /^\d*(\.)?(\d{1,2})?$/;

// eslint-disable-next-line max-len
export const patternGatewayCheck = /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;

// eslint-disable-next-line max-len
export const patternIpAddressCheck = /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\/(3[0-2]|[1-2][0-9]|[0-9])$/;

export const patternIpAddressInput = /^[0-9./]*$/;

import { aggregationTypeText, alarmTypeText, delayText, displayCalibrationText, methodText, minimumTimeText, timeText } from './utils';

export const optionsAggregationType = ['average', 'peak'].map(item => ({
  label: aggregationTypeText(item),
  value: item,
}));

export const optionsAlarmType = ['increasing', 'decreasing', 'window'].map(item => ({
  label: alarmTypeText(item),
  value: item,
}));

export const optionsAnalogOutType = [{ label: 'BC8AOI', value: 'BC8AOI' }];

export const optionsChannel2 = [1, 2].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsChannel = [1, 2, 3, 4, 5, 6, 7, 8].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsChannel16 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsDelay = [0, 10, 30, 300, 600].map(item => ({
  label: delayText(item),
  value: item,
}));

export const optionsDisplayCalibration = [true, false].map(item => ({
  label: displayCalibrationText(item),
  value: item,
}));

export const optionsLane = [1, 2, 3, 4].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsLaneFilter = [1, 2, 3, 'Local'].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsLatching = [
  { label: 'Non-latching', value: 'Non-latching' },
  { label: 'Latching', value: 'Latching' },
];

export const optionsMethod = ['auto', 'manual'].map(item => ({
  label: methodText(item),
  value: item,
}));

export const optionsMinimumRunTime = [0, 300, 600, 900].map(item => ({
  label: minimumTimeText(item),
  value: item,
}));

export const optionsNormalState = [
  { label: 'De-energized', value: 'De-energized' },
  { label: 'Energized', value: 'Energized' },
];

export const optionsPostRunTime = [0, 300, 600, 900].map(item => ({
  label: timeText(item),
  value: item,
}));

export const optionsPowerUpAlarmDelay = [0, 30, 60, 120, 300].map(item => ({
  label: delayText(item),
  value: item,
}));

export const optionsBaud = [9600, 19200, 38400].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsParity = [
  { label: 'Even', value: 'E' },
  { label: 'Odd', value: 'O' },
  { label: 'None', value: 'N' },
];

export const optionsByteSize = [7, 8].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsStopBits = [1, 2].map(item => ({
  label: item.toString(),
  value: item,
}));

export const optionsRange = [
  { label: '0-10 V', value: 1 },
  { label: '4-20 mA', value: 2 },
  { label: '2-10 V', value: 3 },
  { label: '0-20 mA', value: 4 },
];

export const optionsRelayType = [
  { label: 'ERE', value: 'ERE' },
  { label: 'Local', value: 'Local' },
];

export const optionsSensorType = [
  { label: 'AMC-400', value: 'AMC-400' },
  { label: 'BC8AII', value: 'BC8AII' },
  { label: 'DTR', value: 'DTR' },
  { label: 'SIR', value: 'SIR' },
];

export const optionsTimezone = [
  { label: 'Pacific time', value: 'America/Vancouver' },
  { label: 'Mountain time', value: 'America/Edmonton' },
  { label: 'Mountain standard time', value: 'America/Phoenix' },
  { label: 'Central time', value: 'America/Winnipeg' },
  { label: 'Central standard time', value: 'America/Regina' },
  { label: 'Eastern time', value: 'America/Toronto' },
  { label: 'Atlantic time', value: 'America/Halifax' },
  { label: 'Newfoundland time', value: 'America/St_Johns' },
  { label: 'Coordinated universal time', value: 'UTC' },
];
import PropTypes from 'prop-types';
import React from 'react';
import EditDialog from './components/EditDialog';
import EditKeyboard from './components/EditKeyboard';
import SensorTypeName from './components/SensorTypeName';
import SettingsDetails from './SettingsDetails';
import SettingsSensorTypesAdd from './SettingsSensorTypesAdd';
import SettingsSensorTypesDetails from './SettingsSensorTypesDetails';
import SettingsSensorTypesList from './SettingsSensorTypesList';
import { optionsAmc400Id } from './utils/amc400';
import {
  apiDeleteSensorType,
  apiGetRelays,
  apiGetSensors,
  apiGetSettings,
  apiModifySettings,
  apiModifyTimeZone,
  apiGetModbus,
  apiModifyModbus,
} from './utils/api';
import deepCopy from './utils/deepCopy';
import has from './utils/has';
import history from './utils/history';
import {
  optionsAlarmType,
  optionsDelay,
  optionsDisplayCalibration,
  optionsMethod,
  optionsPowerUpAlarmDelay,
  optionsTimezone,
  optionsBaud,
  optionsParity,
  optionsByteSize,
  optionsStopBits,
} from './utils/options';
import {
  patternDecimal1,
  patternDecimal2Pos,
  patternGatewayCheck,
  patternIpAddressCheck,
  patternIpAddressInput,
  patternNumber,
} from './utils/patterns';
import { displayCalibrationText, valueText } from './utils/utils';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    const { settings } = props;
    const { lanes } = props;

    this.state = {
      edit: undefined,
      index: 1,
      id: null,
      metadata: null,
      options: [],
      sensors: [],
      settings,
      lanes
   };

    this.getSettings = this.getSettings.bind(this);
    this.description = this.description.bind(this);
    this.handleEditItemClick = this.handleEditItemClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.subtitle = this.subtitle.bind(this);
    this.units = this.units.bind(this);
    this.value = this.value.bind(this);
    this.valueDialog = this.valueDialog.bind(this);

    history.defaultState({
      metadata: null,
      settingsId: null,
      settingsIndex: 1,
    });
}

  componentDidMount() {
    this.interval = setInterval(() => this.getSettings(), 1000);
    apiGetSensors().then((sensors) => {
      if (this.interval) {
        this.setState({ sensors });
      }
    });

    history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;

    history.removeListener(this.handleLocationChanged);
  }

  getSettings() {
    apiGetSettings().then((settings) => {
      if (this.interval) {
        this.setState({ settings });
      }
    });
    apiGetModbus().then((lanes) => {
      if (this.interval) {
        this.setState({ lanes });
      }
    });
  }

  description() {
    const { metadata } = this.state;

    if (metadata) {
      const { index2 } = metadata;
      if (index2 === 'address') {
        return 'Format: <0-255>.<0-255>.<0-255>.<0-255>/<0-32>';
      }
      if (index2 === 'gateway') {
        return 'Format: <0-255>.<0-255>.<0-255>.<0-255>';
      }
    }

    return undefined;
  }

  handleEditItemClick(item) {
    const { index, index2, index3 } = item;

    if (index === 'displayCalibration') {
      this.setState({ edit: true, metadata: item, options: optionsDisplayCalibration });

    } else if (index === 'baud') {
      this.setState({ edit: true, metadata: item, options: optionsBaud });
    } else if (index === 'parity') {
      this.setState({ edit: true, metadata: item, options: optionsParity });
    } else if (index === 'bytesize') {
      this.setState({ edit: true, metadata: item, options: optionsByteSize });
    } else if (index === 'stopbits') {
      this.setState({ edit: true, metadata: item, options: optionsStopBits });
    } else if (index === 'defaultTimeZone') {
      this.setState({ edit: true, metadata: item, options: optionsTimezone });

     } else if (index === 'powerUpAlarmDelay') {
      this.setState({ edit: true, metadata: item, options: optionsPowerUpAlarmDelay });
    } else if (index2 === 'method') {
      this.setState({ edit: true, metadata: item, options: optionsMethod });
    } else if (index2 === 'alarmType') {
      this.setState({ edit: true, metadata: item, options: optionsAlarmType });
    } else if (index2 === 'delay' || index3 === 'delay') {
      this.setState({ edit: true, metadata: item, options: optionsDelay });
    } else if (index2 === 'relay') {
      apiGetRelays().then((relays) => {
        if (this.interval) {
          const options = relays.map(value => ({ label: `Relay ${value.id}`, value: value.id }));
          options.push({ label: 'None', value: '' });
          this.setState({
            edit: true,
            metadata: item,
            options,
          });
        }
      });
    } else if (index2 === 'amc400Id') {
      this.setState({
        edit: true,
        metadata: item,
        options: optionsAmc400Id.concat([{ label: 'None', value: '' }]).map(option => ({
          label: <SensorTypeName name={option.label} />,
          value: option.value,
        })),
      });
    } else if (has.call(item, 'reset')) {
      this.setState({ edit: true, metadata: item, options: [] });
    } else {
      history.pushAppend({ metadata: item, options: [] });
    }
  }

  handleLocationChanged(value) {
    const { settings } = this.state;
    const {
      metadata, options, settingsId, settingsIndex,
    } = value;

    let settingsUpdate = {};

    if (settingsId) {
      if (!settings.sensorTypes.some(item => item.id === settingsId)) {
        history.goBack();
        return;
      }
      settingsUpdate = { id: settingsId, index: settingsIndex || 1 };
    } else {
      settingsUpdate = { id: null, index: settingsIndex || 1 };
    }

    if (metadata) {
      this.setState({
        edit: true,
        metadata,
        options,
        ...settingsUpdate,
      });
    } else {
      this.setState({ edit: false, ...settingsUpdate });
    }
  }

  handleSaveClick(value) {
    const { metadata, options, settings, lanes } = this.state;

    const closeDialogOrGoBack = () => {
      if (options.length !== 0 || has.call(metadata, 'reset')) {
        this.setState({ edit: false });
      } else {
        history.goBack();
      }
    };

    if (!metadata) {
      closeDialogOrGoBack();
      return;
    }

    const {
      index, index2, index3, indexId, indexId2,
    } = metadata;
    let value2 = {};

    if (['delay', 'setPoint'].includes(index3)) {
      const sensorType = settings[index].find(item => item.id === indexId);
      const newValue = deepCopy(sensorType[index2]).reduce((accumulator, item) => {
        if (item.id === indexId2) {
          if (value !== '') accumulator.push({ ...item, [index3]: Number(value) });
        } else {
          accumulator.push(item);
        }
        return accumulator;
      }, []);
      if (index3 === 'setPoint' && value !== '' && !newValue.find(item => item.id === indexId2)) {
        newValue.push({ id: indexId2, [index3]: Number(value), delay: 0 });
      }
      if (JSON.stringify(newValue) === JSON.stringify(sensorType[index2])) {
        closeDialogOrGoBack();
        return;
      }
      value2 = {
        sensorType: {
          id: indexId,
          [index2]: newValue,
        },
      };
    } else if (indexId !== undefined && index2 !== undefined) {
      if (index2 === 'amc400Id') {
        const newValue = value === '' ? null : Number(value);
        if (newValue === settings[index].find(item => item.id === indexId)[index2]) {
          closeDialogOrGoBack();
          return;
        }
        value2 = {
          sensorType: {
            id: indexId,
            [index2]: newValue,
          },
        };
      } else {
        const newValue = ['alarmType', 'description', 'name', 'units'].includes(index2) ? value : Number(value);
        if (newValue === settings[index].find(item => item.id === indexId)[index2]) {
          closeDialogOrGoBack();
          return;
        }
        if (index === 'sensorTypes') {
          value2 = {
            sensorType: {
              id: indexId,
              [index2]: newValue,
            },
          };
        } else {
          value2 = {
            [index]: {
              id: indexId,
              [index2]: newValue,
            },
          };
        }
      }
    } else if (index === 'displayCalibration') {
      const newValue = value === 'true';
      if (newValue === settings[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = {
        [index]: newValue,
      };
    } else if (index2 === 'deviceName') {
      const newValue = value;
      if (newValue === settings[index][index2]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = {
        [index]: {
          [index2]: newValue,
        },
      };
    } else if (index2 === 'method') {
      const newValue = value;
      if (newValue === settings[index][index2]) {
        closeDialogOrGoBack();
        return;
      }
      if (value === 'auto') {
        value2 = {
          [index]: {
            [index2]: newValue,
            address: '',
            gateway: '',
          },
        };
      } else {
        value2 = {
          [index]: {
            [index2]: newValue,
            address: settings.network.address,
            gateway: settings.network.gateway,
          },
        };
      }
    } else if (index2 === 'address') {
      const newValue = value || '';
      if (newValue === settings[index][index2]) {
        closeDialogOrGoBack();
        return;
      }
      if (has.call(metadata, 'value')) {
        value2 = {
          [index]: {
            [index2]: newValue,
            gateway: metadata.value.network.gateway,
            method: metadata.value.network.method,
          },
        };
      } else {
        value2 = {
          [index]: {
            [index2]: newValue,
            gateway: settings.network.gateway,
            method: settings.network.method,
          },
        };
      }
    } else if (index2 === 'gateway') {
      const newValue = value || '';
      if (newValue === settings[index][index2]) {
        closeDialogOrGoBack();
        return;
      }
      if (has.call(metadata, 'value')) {
        value2 = {
          [index]: {
            [index2]: newValue,
            address: metadata.value.network.address,
            method: metadata.value.network.method,
          },
        };
      } else {
        value2 = {
          [index]: {
            [index2]: newValue,
            address: settings.network.address,
            method: settings.network.method,
          },
        };
      }
    } else if (index === 'siteName') {
      const newValue = value === '' ? null : value;
      if (newValue === settings[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = {
        [index]: newValue,
      };
    } 
    else if (index === 'baud' || index === 'bytesize' || index === 'stopbits') {
      const newValue = value === '' ? null : Number(value);
      if (newValue === lanes[indexId].index) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { 
        [index]: newValue 
      };
      apiModifyModbus(indexId, value2).then((data) => {
        if (this.interval) {
          if (data) {
            this.setState({ lanes });
          }
          closeDialogOrGoBack();
          return;
        }
      });
      closeDialogOrGoBack();
      return;
    }
    else if (index === 'parity') {
      const newValue = value === '' ? null : value;
      if (newValue === lanes[indexId].index) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { 
        [index]: newValue 
      };
      apiModifyModbus(indexId, value2).then((data) => {
        if (this.interval) {
          if (data) {
            this.setState({ lanes });
          }
          closeDialogOrGoBack();
          return;
        }
      });
      return;
    }
    else {
      const newValue = value === '' ? null : Number(value);
      if (index2 !== undefined) {
        if (newValue === settings[index][index2]) {
          closeDialogOrGoBack();
          return;
        }
        value2 = {
          [index]: {
            [index2]: newValue,
          },
        };
      } else {
        if (newValue === settings[index]) {
          closeDialogOrGoBack();
          return;
        }
        value2 = {
          [index]: newValue,
        };
      }
    }

    if (index2 === 'method') {
      if (value === 'manual') {
        this.handleEditItemClick({
          label: 'Network IPv4 address',
          index: 'network',
          index2: 'address',
          reset: true,
          value: {
            ...value2,
          },
        });
        return;
      }
    }

    if (index2 === 'address') {
      if (has.call(metadata, 'reset')) {
        this.handleEditItemClick({
          label: 'Network gateway',
          index: 'network',
          index2: 'gateway',
          reset: true,
          value: {
            ...value2,
          },
        });
        return;
      }
    }

    if (index === 'defaultTimeZone') {
      value2 = {
        [index]: value,
      };
    }

    apiModifySettings(value2).then((data) => {
      if (this.interval) {
        if (data) this.setState({ settings: data });
        closeDialogOrGoBack();
      }
    });

    if (index === 'defaultTimeZone') {
      apiModifyTimeZone({ timeZone: value })
    }

  }

  inputCheck(value) {
    const { metadata, sensors, settings } = this.state;

    if (metadata.index2 === 'amc400Id' && value === '') {
      if (sensors.some(item => item.sensorType.id === metadata.indexId && item.type === 'AMC-400')) {
        return 'Sensor type is associated with AMC-400 devices which depend on a valid AMC-400 type.';
      }
    }

    if (metadata.index2 === 'name') {
      if (value.replace(/_/g, '').length > 12) {
        return 'Maximum characters exceeded. Name must be less than 13 chars.';
      }
      if (settings.sensorTypes.some(item => item.name === value && item.id !== metadata.indexId)) {
        return 'Duplicate name. Sensor type name must be unique.';
      }
    }

    if (metadata.index2 === 'port') {
      const number = Number(value);
      if (number < 47808 || number > 47817) {
        return 'Invalid port. Valid range is 47808 to 47817.';
      }
    }

    if (metadata.index2 === 'address') {
      if (!patternIpAddressCheck.test(value)) {
        return 'Invalid address. Valid format: <0-255>.<0-255>.<0-255>.<0-255>/<0-32>';
      }
    }

    if (metadata.index2 === 'gateway') {
      if (!patternGatewayCheck.test(value)) {
        return 'Invalid gateway. Valid format: <0-255>.<0-255>.<0-255>.<0-255>';
      }
    }

    return null;
  }

  inputPattern() {
    const { metadata } = this.state;

    if (metadata) {
      const { index2 } = metadata;
      if (['faultLimit', 'fullScale', 'zeroScale'].includes(index2)) {
        return patternDecimal1;
      }
      if (index2 === 'alarmHysteresis') {
        return patternDecimal2Pos;
      }
      if (['deviceId', 'port'].includes(index2)) {
        return patternNumber;
      }
      if (['address', 'gateway'].includes(index2)) {
        return patternIpAddressInput;
      }
    }

    return undefined;
  }

  subtitle() {
    const { metadata, settings } = this.state;

    if (metadata && settings) {
      const {
        index, index2, indexId, indexId2,
      } = metadata;
      if (index === 'sensorTypes') {
        const sensorType = settings.sensorTypes.find(item => item.id === indexId);
        if (sensorType) {
          return index2 === 'alarms' ? (
            <SensorTypeName name={`Sensor type ${sensorType.name} - Alarm ${indexId2}`} />
          ) : (
            <SensorTypeName name={`Sensor type ${sensorType.name}`} />
          );
        }
      }
    }

    return undefined;
  }

  units() {
    const { metadata, settings } = this.state;

    if (metadata && settings) {
      const { index2, index3, indexId } = metadata;
      if (
        ['alarmHysteresis', 'faultLimit', 'fullScale', 'zeroBuffer', 'zeroScale'].includes(index2)
        || index3 === 'setPoint'
      ) {
        const sensorType = settings.sensorTypes.find(item => item.id === indexId);
        if (sensorType) {
          return sensorType.units;
        }
      }
    }

    return undefined;
  }

  value() {
    const { metadata, settings } = this.state;

    if (metadata && settings) {
      const {
        index, index2, index3, indexId, indexId2,
      } = metadata;
      if (index3 !== undefined) {
        const value = settings[index].find(item => item.id === indexId)[index2].find(item2 => item2.id === indexId2);
        if (index3 === 'setPoint') {
          return value ? valueText(value[index3], 1) : '';
        }
        return value ? value[index3] : '';
      }
      if (index2 !== undefined) {
        if (indexId !== undefined) {
          const value = settings[index].find(item => item.id === indexId)[index2];
          if (index2 === 'alarmHysteresis') {
            return valueText(value, 2);
          }
          if (['zeroScale', 'fullScale', 'faultLimit', 'zeroBuffer'].includes(index2)) {
            return valueText(value, 1);
          }
          return value;
        }
        return settings[index][index2];
      }
      const value = settings[index];
      if (index === 'displayCalibration') {
        return displayCalibrationText(value);
      }
      if (index === 'siteName') {
        return value || '';
      }
      return value;
    }

    return '';
  }

  valueDialog() {
    const { metadata, options, settings, lanes } = this.state;

    if (metadata && settings && options.length !== 0) {
      const {
        index, index2, index3, indexId, indexId2,
      } = metadata;
      if (index === 'displayCalibration' || index === 'powerUpAlarmDelay' || index === 'defaultTimeZone') {
        return settings[index];
      }
      if (index === 'baud' || index === 'parity' || index === 'bytesize' || index === 'stopbits') {
        return lanes[indexId][index];
      }
      if (indexId2 !== undefined) {
        const item = settings[index].find(value => value.id === indexId);
        if (item !== undefined) {
          const item2 = item[index2].find(value => value.id === indexId2);
          if (item2 !== undefined) {
            return item2[index3] === null || item2[index3] === undefined || item2[index3].length === 0
              ? ''
              : item2[index3];
          }
        }
      } else if (indexId !== undefined) {
        const item = settings[index].find(value => value.id === indexId);
        if (item !== undefined) {
          return item[index2] === null || item[index2] === undefined || item[index2].length === 0 ? '' : item[index2];
        }
      } else {
        return settings[index][index2] === null
          || settings[index][index2] === undefined
          || settings[index][index2].length === 0
          ? ''
          : settings[index][index2];
      }
    }

    return '';
  }

  render() {
    const { getScrollTop, handleBackClick, handleSaveScrollTop } = this.props;
    const {
      edit, id, index, metadata, options, settings, lanes
    } = this.state;

    if (edit === undefined) return null;

    return (
      <>
        {edit && options.length === 0 && (
          <EditKeyboard
            allowNone={metadata.index === 'siteName' || metadata.index3 === 'setPoint'}
            buttonText={has.call(metadata, 'reset') && metadata.index2 === 'address' ? 'Next' : 'Save'}
            description={this.description()}
            handleCloseClick={() => {
              if (has.call(metadata, 'reset')) {
                this.setState({ edit: false });
              } else {
                history.goBack();
              }
            }}
            handleSaveClick={this.handleSaveClick}
            inputCheck={this.inputCheck}
            inputPattern={this.inputPattern()}
            label={metadata.label}
            subtitle={this.subtitle()}
            title="Edit Settings"
            units={this.units()}
            value={this.value()}
          />
        )}
        {(!edit || (edit && options.length !== 0))
          && ((index === 1 && (
            <SettingsDetails
              handleBackClick={handleBackClick}
              handleEditItemClick={this.handleEditItemClick}
              handleListItemClick={(settingsId) => {
                handleSaveScrollTop('sensorTypesDetails')(0);
                history.pushAppend({ settingsId, settingsIndex: 3 });
              }}
              handleSaveScrollTop={handleSaveScrollTop}
              handleShowAllClick={() => {
                handleSaveScrollTop('sensorTypesList')(0);
                history.pushAppend({ settingsIndex: 2 });
              }}
              initialScrollTop={getScrollTop()}
              settings={settings}
              lanes={lanes}
            />
          ))
            || (index === 2 && (
              <SettingsSensorTypesList
                handleAddClick={() => {
                  history.pushAppend({ settingsIndex: 4 });
                }}
                handleBackClick={() => {
                  history.goBack();
                }}
                handleListItemClick={(settingsId) => {
                  handleSaveScrollTop('sensorTypesDetails')(0);
                  history.pushAppend({ settingsId, settingsIndex: 3 });
                }}
                handleSaveScrollTop={handleSaveScrollTop('sensorTypesList')}
                initialScrollTop={getScrollTop('sensorTypesList')()}
                sensorTypes={settings && settings.sensorTypes}
              />
            ))
            || (index === 3 && (
              <SettingsSensorTypesDetails
                handleBackClick={() => {
                  history.goBack();
                }}
                handleDeleteClick={() => {
                  apiDeleteSensorType(id).then(() => {
                    if (this.interval) {
                      history.goBack();
                    }
                  });
                }}
                handleEditItemClick={this.handleEditItemClick}
                handleSaveScrollTop={handleSaveScrollTop('sensorTypesDetails')}
                initialScrollTop={getScrollTop('sensorTypesDetails')()}
                sensorType={settings && settings.sensorTypes.find(item => item.id === id)}
              />
            ))
            || (index === 4 && (
              <SettingsSensorTypesAdd
                handleCloseClick={() => {
                  history.goBack();
                }}
                handleSaveClick={(settingsId) => {
                  apiGetSettings().then((updatedSettings) => {
                    if (this.interval) {
                      handleSaveScrollTop('sensorTypesList')(-1);
                      this.setState({ settings: updatedSettings });
                      history.replaceInline({ settingsId, settingsIndex: 3 });
                    }
                  });
                }}
                sensorTypes={settings.sensorTypes}
              />
            )))}
        <EditDialog
          handleCloseClick={() => {
            this.setState({ edit: false });
          }}
          handleSaveClick={this.handleSaveClick}
          inputCheck={this.inputCheck}
          label={metadata && has.call(metadata, 'label') ? metadata.label : ''}
          lowerCase={metadata && has.call(metadata, 'lowerCase') ? metadata.lowerCase : true}
          open={edit && options.length !== 0}
          options={options}
          value={this.valueDialog()}
        />
      </>
    );
  }
}

Settings.propTypes = {
  getScrollTop: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default Settings;

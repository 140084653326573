import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB6FOP59tgQxabLJfqK9Q6OS53SHDgPIEM',
  authDomain: 'monitor-config-a9db5.firebaseapp.com',
  databaseURL: 'https://monitor-config-a9db5.firebaseio.com',
  projectId: 'monitor-config-a9db5',
  storageBucket: 'monitor-config-a9db5.appspot.com',
  messagingSenderId: '739131096008',
  appId: '1:739131096008:web:0dfdb7291e8c4c7736defd',
};

const app = firebase.initializeApp(firebaseConfig);

export default app;

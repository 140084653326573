import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import ListSubheader from './components/ListSubheader';
import SensorTypeName from './components/SensorTypeName';
import Separator from './components/Separator';
import SpacedText from './components/SpacedText';
import { AbilityContext } from './utils/auth';
import has from './utils/has';
import { valueText } from './utils/utils';

const styles = theme => ({
  divider: {
    padding: theme.spacing(0, 2),
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    overflow: 'auto',
    paddingBottom: theme.spacing(1.5),
  },
  noValue: {
    fontStyle: 'italic',
  },
  paper: {
    width: 352,
  },
  switch: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 60,
    width: '50%',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  warningText: {
    lineHeight: '1em',
    marginLeft: theme.spacing(2),
  },
  wrapper: {
    height: '100%',
  },
});

class SensorsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.scrollTarget = undefined;

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteCancelClick() {
    this.setState({ open: false });
  }

  handleDeleteConfirmClick() {
    const { handleDeleteClick: handleDeleteClickProp, id } = this.props;

    handleDeleteClickProp(id);
    this.setState({ open: false });
  }

  render() {
    const {
      classes, handleBackClick, handleChangeItem, handleEditItemClick, id, sensor,
    } = this.props;
    const { open } = this.state;

    var start_sensorType_alarm = 0;
    var finish_sensorType_alarm = 3;
    var start_sensor_alarm = 0;
    var finish_sensor_alarm = 0;

    const alarms = [];
    if (sensor) {
      sensor.config.sensorType.alarms.forEach((alarm) => {
        const alarmLimit = {
          delay: alarm.delay,
          id: alarm.id,
          setPoint: alarm.setPoint,
          override: false,
        };
        const override = sensor.config.alarms.find(item => item.id === alarm.id);
        if (override) {
          alarmLimit.delay = override.delay
          alarmLimit.setPoint = override.setPoint;
          alarmLimit.override = true;
          start_sensor_alarm++;
          finish_sensor_alarm++;
        }
        alarms.push(alarmLimit);
      });
    }

    const sensorAlarms = [];
    if (sensor) {
      sensor.config.alarms.forEach((alarm) => {
        const alarmLimit = {
          delay: alarm.delay,
          id: alarm.id,
          setPoint: alarm.setPoint,
          override: false,
        };
        const override = sensor.config.alarms.find(item => item.id === alarm.id);
        if (override) {
          alarmLimit.delay = override.delay;
          alarmLimit.setPoint = override.setPoint;
          alarmLimit.override = true;
          finish_sensor_alarm++;
        }
        sensorAlarms.push(alarmLimit);
      });
    }
    if (sensor.config.sensorType.alarms.length === 2 && sensor.config.alarmPoints === 1)
       finish_sensorType_alarm = 1;
    if (sensor.config.sensorType.alarms.length === 3 && sensor.config.alarmPoints === 1)
       finish_sensorType_alarm = 1;
    if (sensor.config.sensorType.alarms.length === 3 && sensor.config.alarmPoints === 2)
       finish_sensorType_alarm = 2;

    return (
      <AbilityContext.Consumer>
        {(ability) => {
          const disabled = !ability.can('update', 'device');
          const color = disabled ? 'textSecondary' : 'initial';
          return (
            <>
              <div className={classes.wrapper}>
                <ElevationScroll target={this.scrollTarget}>
                  <AppBar
                    handleBackClick={handleBackClick}
                    handleDeleteClick={disabled ? undefined : this.handleDeleteClick}
                    title={`Sensor ${id}`}
                  />
                </ElevationScroll>
                <div
                  className={classes.listWrapper}
                  ref={(node) => {
                    if (node) {
                      this.scrollTarget = node;
                    }
                  }}
                >
                  {sensor ? (
                    <>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>General settings</ListSubheader>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Zone',
                                index: 'zone',
                              })
                              }
                            >
                              <ListItemText
                                disableTypography
                                primary={<Typography color={color}>Zone</Typography>}
                                secondary={
                                  sensor.config.zone !== null ? (
                                    <Typography color="textSecondary" variant="body2">
                                      {sensor.config.zone.name}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className={clsx(classes.noValue, classes.warning)}
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      None
                                    </Typography>
                                  )
                                }
                              />
                            </ButtonBase>
                            <span className={classes.switch}>
                              <Typography color={color}>Enabled</Typography>
                              <Switch
                                checked={sensor.config.enabled}
                                color="secondary"
                                disabled={disabled}
                                onChange={event => handleChangeItem(event.target.checked, { index: 'enabled' })}
                              />
                            </span>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ label: 'Sensor type', index: 'sensorTypeId' })}
                            >
                              <ListItemText
                                primary="Sensor type"
                                primaryTypographyProps={{ color }}
                                secondary={(
                                  <Typography color="textSecondary" variant="body2">
                                    <SensorTypeName name={sensor.config.sensorType.name} />
                                    <Separator />
                                    {sensor.config.sensorType.description}
                                  </Typography>
                                )}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ label: 'Device', index: 'type' })}
                            >
                              <ListItemText
                                primary="Device"
                                primaryTypographyProps={{ color }}
                                secondary={sensor.config.type}
                              />
                            </ButtonBase>
                          </ListItem>
                        </div>
                      </List>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>Communications</ListSubheader>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ label: 'Modbus lane', index: 'lane' })}
                            >
                              <ListItemText
                                className={classes.listItem}
                                disableTypography
                                primary={<Typography color={color}>Modbus lane</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(sensor.config.duplicate && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {sensor.config.lane}
                                  </Typography>
                                )}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ label: 'Modbus address', index: 'address' })}
                            >
                              <ListItemText
                                className={classes.listItem}
                                disableTypography
                                primary={<Typography color={color}>Modbus address</Typography>}
                                secondary={(
                                  <Typography
                                    className={clsx(sensor.config.duplicate && classes.warning)}
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {sensor.config.address}
                                  </Typography>
                                )}
                              />
                            </ButtonBase>
                          </ListItem>
                          {has.call(sensor.config, 'channel') && (
                            <ListItem>
                              <ButtonBase
                                className={classes.listItem}
                                disabled={disabled}
                                onClick={() => handleEditItemClick({ label: 'Modbus channel', index: 'channel' })}
                              >
                                <ListItemText
                                  className={classes.listItem}
                                  disableTypography
                                  primary={<Typography color={color}>Modbus channel</Typography>}
                                  secondary={(
                                    <Typography
                                      className={clsx(sensor.config.duplicate && classes.warning)}
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {sensor.config.channel}
                                    </Typography>
                                  )}
                                />
                              </ButtonBase>
                            </ListItem>
                          )}
                          {sensor.config.duplicate && (
                            <Typography className={clsx(classes.warning, classes.warningText)} variant="caption">
                              {has.call(sensor.config, 'channel')
                                ? '* Duplicate modbus lane, address or channel'
                                : '* Duplicate modbus lane or address'}
                            </Typography>
                          )}
                        </div>
                      </List>
                      {alarms.length !== 0 && (
                        <>
                          <div className={classes.divider}>
                            <Divider />
                          </div>
                          <List className={classes.list}>
                            <div className={classes.listContent}>
                              <ListSubheader disableSticky>Alarm settings</ListSubheader>
                              { <ListItem>
                                {alarms.slice(start_sensorType_alarm, finish_sensorType_alarm).map(item => (
                                  <ButtonBase
                                    className={classes.listItem}
                                    disabled={disabled}
                                    key={item.id.toString()}
                                    onClick={() => handleEditItemClick({
                                      label: 'Set point',
                                      index: 'alarms',
                                      index2: 'setPoint',
                                      indexId: item.id,
                                      subtitle: `Alarm ${item.id} Override`,
                                    })
                                    }
                                  >
                                    <ListItemText
                                      className={classes.listItem}
                                      disableTypography
                                      key={item.id.toString()}
                                      primary={(
                                        <Typography color={color}>
                                          <SpacedText
                                            text1={`Alarm ${item.id}`}
                                            text2={`${item.override ? '(override)' : '(default)'}`}
                                          />
                                        </Typography>
                                      )}
                                      secondary={(
                                        <Typography color="textSecondary" component="span" variant="body2">
                                          Set point
                                          <Separator />
                                          {valueText(item.setPoint, 1, sensor.config.sensorType.units)}
                                        </Typography>
                                      )}
                                    />
                                  </ButtonBase>
                                ))}
                                </ListItem>
                              }
                              { (alarms.length !== sensor.config.alarmPoints)
                                && (
                                <ListItem>
                                  {sensorAlarms.slice(start_sensor_alarm, finish_sensor_alarm).map(item => (
                                    <ButtonBase
                                      className={classes.listItem}
                                      disabled={disabled}
                                      key={item.id.toString()}
                                      onClick={() => handleEditItemClick({
                                        label: 'Set point',
                                        index: 'alarms',
                                        index2: 'setPoint',
                                        indexId: item.id,
                                        subtitle: `Alarm ${item.id} Override`,
                                      })
                                      }
                                    >
                                      <ListItemText
                                        className={classes.listItem}
                                        disableTypography
                                        key={item.id.toString()}
                                        primary={(
                                          <Typography color={color}>
                                            <SpacedText
                                              text1={`Alarm ${item.id}`}
                                              text2={`${item.override ? '(override)' : '(default)'}`}
                                            />
                                          </Typography>
                                        )}
                                        secondary={(
                                          <Typography color="textSecondary" component="span" variant="body2">
                                            Set point
                                            <Separator />
                                            {valueText(item.setPoint, 1, sensor.config.sensorType.units)}
                                          </Typography>
                                        )}
                                      />
                                    </ButtonBase>
                                  ))}
                                </ListItem>
                              )}

                              <ListItem>
                                <ButtonBase
                                  className={classes.listItem}
                                  disabled={disabled}
                                  onClick={() => handleEditItemClick({
                                    label: 'Number of Alarm Levels',
                                    index: 'alarmPoints',
                                    index2: 'id',
                                    indexId: sensor.config.sensorType.alarms.length,
                                    subtitle: `Alarm Points ${sensor.config.sensorType.alarms.length} Override`
                                  })}
                                >
                                <ListItemText
                                  className={classes.listItem}
                                  disableTypography
                                  primary={(
                                    <Typography color={color}>
                                      <SpacedText
                                        text1={ (sensor.config.sensorType.alarms.length === sensor.config.alarmPoints
                                          ?  `Alarm Points ${sensor.config.sensorType.alarms.length}`
                                          :  `Alarm Points ${sensor.config.alarmPoints}`
                                        )}
                                        text2={ `${(sensor.config.sensorType.alarms.length !== sensor.config.alarmPoints) ? '(override)' : '(default)'}`}
                                      />
                                    </Typography>
                                  )}
                                />
                                </ButtonBase>
                              </ListItem>
                            </div>
                          </List>
                        </>
                      )}
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <Dialog open={open} onClose={this.handleDeleteCancelClick} PaperProps={{ className: classes.paper }}>
                <DialogTitle>Delete this sensor?</DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleDeleteCancelClick}>Cancel</Button>
                  <Button color="secondary" onClick={this.handleDeleteConfirmClick}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </AbilityContext.Consumer>
    );
  }
}

SensorsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleChangeItem: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleEditItemClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  sensor: PropTypes.object,
};

SensorsDetails.defaultProps = {
  sensor: undefined,
};

export default withStyles(styles)(SensorsDetails);

import AppBar from '@material-ui/core/AppBar';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Scroll from './components/Scroll';
import { SelectInputDark } from './components/SelectInput';
import Separator from './components/Separator';
import SpacedText from './components/SpacedText';
import has from './utils/has';
import {
  optionsAnalogOutType, optionsLaneFilter, optionsRelayType, optionsSensorType,
} from './utils/options';
import { iconSize, tableHead } from './utils/styles';

const styles = theme => ({
  buttonBack: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  },
  counts: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 0.7,
  },
  formDevices: {
    minWidth: 138,
  },
  formLanes: {
    minWidth: 84,
  },
  formZone: {
    minWidth: 92,
  },
  icon: {
    color: theme.palette.common.white,
  },
  iconSize,
  noValue: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.background.paper,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0, 0.5),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  select: {
    fontSize: '16px',
    lineHeight: '22x',
    padding: theme.spacing(0, 2),
  },
  spacing: {
    marginRight: 13,
  },
  table: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
  tableCell: {
    '&:first-child': {
      paddingLeft: theme.spacing(3),
    },
  },
  tableFooter: {
    height: theme.spacing(4),
  },
  tableHead,
  tableValue: {
    lineHeight: '22px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  tableWrapper: {
    height: '100%',
    paddingTop: theme.spacing(0.5),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  wrapper: {
    height: '100%',
  },
});

function CommunicationsList(props) {
  const {
    classes,
    deviceFilter,
    devices,
    handleBackClick,
    handleListItemClick,
    handleSaveScrollTop,
    initialScrollTop,
    handleSelectDeviceChange,
    handleSelectLaneChange,
    handleSelectZoneChange,
    laneFilter,
    zoneFilter,
    zones,
  } = props;

  const filtered = devices
    .filter(
      item => zoneFilter === ''
        || (has.call(item, 'zone') && item.zone !== null && item.zone.name === zoneFilter)
        || (has.call(item, 'zones') && item.zones.map(value => value.name).includes(zoneFilter)),
    )
    .filter(
      item => laneFilter === '' || item.lane === laneFilter || (!has.call(item, 'lane') && laneFilter === 'Local'),
    )
    .filter(item => deviceFilter === '' || item.type === deviceFilter);

  return (
    <div className={classes.wrapper}>
      <AppBar elevation={0} position="relative">
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            <IconButton className={classes.buttonBack} color="inherit" onClick={handleBackClick}>
              <ArrowBackIcon className={classes.iconSize} />
            </IconButton>
            <Typography color="inherit" variant="h6">
              {zoneFilter || laneFilter || deviceFilter ? (
                <SpacedText
                  text1="Communications"
                  text2={(
                    <Typography className={classes.counts} component="span">
                      {`(${filtered.length} of ${devices.length})`}
                    </Typography>
                  )}
                />
              ) : (
                <SpacedText
                  text1="Communications"
                  text2={(
                    <Typography className={classes.counts} component="span">
                      {`(${filtered.length})`}
                    </Typography>
                  )}
                />
              )}
            </Typography>
          </div>
          <div>
            <FormControl className={clsx(classes.formDevices, classes.spacing)} variant="outlined">
              <Select
                classes={{
                  icon: classes.icon,
                }}
                input={<SelectInputDark />}
                onChange={handleSelectDeviceChange}
                renderValue={value => (
                  <Typography className={classes.select} variant="body2">
                    {value}
                  </Typography>
                )}
                value={deviceFilter}
              >
                <MenuItem value="">
                  <em>All devices</em>
                </MenuItem>
                {optionsSensorType
                  .concat(optionsRelayType)
                  .concat(optionsAnalogOutType)
                  .map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={clsx(classes.formLanes, classes.spacing)} variant="outlined">
              <Select
                classes={{
                  icon: classes.icon,
                }}
                input={<SelectInputDark />}
                onChange={handleSelectLaneChange}
                renderValue={value => (
                  <Typography className={classes.select} variant="body2">
                    {value}
                  </Typography>
                )}
                value={laneFilter}
              >
                <MenuItem value="">
                  <em>All lanes</em>
                </MenuItem>
                {optionsLaneFilter.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formZone} variant="outlined">
              <Select
                classes={{
                  icon: classes.icon,
                }}
                input={<SelectInputDark />}
                onChange={handleSelectZoneChange}
                renderValue={value => (
                  <Typography className={classes.select} variant="body2">
                    {value}
                  </Typography>
                )}
                value={zoneFilter}
              >
                <MenuItem value="">
                  <em>All zones</em>
                </MenuItem>
                {zones.map(item => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.tableWrapper}>
        <Scroll className={classes.table} handleSaveScrollTop={handleSaveScrollTop} initialScrollTop={initialScrollTop}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>
                  Lane
                </TableCell>
                <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Address</TableCell>
                <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Device</TableCell>
                <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>ID</TableCell>
                <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Zone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map((item, index) => (
                <TableRow hover key={index.toString()} onClick={() => handleListItemClick(item)}>
                  <TableCell className={clsx(classes.tableCell, classes.tableValue, item.duplicate && classes.warning)}>
                    {item.lane}
                  </TableCell>
                  <TableCell className={clsx(classes.tableValue, item.duplicate && classes.warning)}>
                    {has.call(item, 'channel') ? (
                      <>
                        {has.call(item, 'address') && (
                          <>
                            {`${item.address}`}
                            <Separator />
                          </>
                        )}
                        {`${item.channel}`}
                      </>
                    ) : (
                      item.address
                    )}
                  </TableCell>
                  <TableCell className={classes.tableValue}>{item.type}</TableCell>
                  <TableCell className={classes.tableValue}>
                    {item.instance.type === 'analogOut'
                      ? `Analog out ${item.instance.id}`
                      : `${item.instance.type.charAt(0).toUpperCase() + item.instance.type.slice(1)} ${
                        item.instance.id
                      }`}
                  </TableCell>
                  <TableCell className={classes.tableValue}>
                    <>
                      {has.call(item, 'zone') && (
                        <>
                          {item.zone !== null ? (
                            item.zone.name
                          ) : (
                            <Typography className={classes.noValue} variant="caption">
                              NONE
                            </Typography>
                          )}
                        </>
                      )}
                      {has.call(item, 'zones') && (
                        <>
                          {item.zones.length !== 0
                          || (item.instance.type === 'relay' && item.instance.id === item.settings.fault.relay) ? (
                              item.zones.map(value => value.name).join(', ')
                            ) : (
                              <Typography className={classes.noValue} variant="caption">
                                NONE
                              </Typography>
                            )}
                        </>
                      )}
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow className={classes.tableFooter} />
            </TableFooter>
          </Table>
        </Scroll>
      </div>
    </div>
  );
}

CommunicationsList.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceFilter: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  handleSelectDeviceChange: PropTypes.func.isRequired,
  handleSelectLaneChange: PropTypes.func.isRequired,
  handleSelectZoneChange: PropTypes.func.isRequired,
  laneFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  zoneFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(CommunicationsList);

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import AppBar2 from './components/AppBar2';
import SensorTypeName from './components/SensorTypeName';
import Separator from './components/Separator';
import SpacedText from './components/SpacedText';
import has from './utils/has';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  fontMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  footer: {
    height: theme.spacing(3),
  },
  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formItem: {
    width: '40%',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginBottom: 14,
    marginTop: 14,
  },
  formLabel: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  formWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - 58px)`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    ...theme.mixins.gutters(),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: 9,
  },
  wrapper: {
    height: '100%',
  },
  width: {
    width: 408,
  },
  widthSensor: {
    width: 458,
  },
});

class ZonesEdit extends React.Component {
  constructor(props) {
    super(props);

    const { devices } = this.props;

    this.state = {
      checked: new Set(
        devices.reduce((accumulator, item) => (item.checked ? accumulator.concat(item.id) : accumulator), []),
      ),
    };

    this.handleChecked = this.handleChecked.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleChecked(id) {
    const { checked } = this.state;

    if (checked.has(id)) {
      checked.delete(id);
    } else {
      checked.add(id);
    }

    this.setState({ checked });
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSaveClick();
    }
  }

  handleSaveClick() {
    const { devices, handleSaveClick: handleSaveClickProps } = this.props;
    const { checked } = this.state;

    handleSaveClickProps(
      devices.reduce(
        // eslint-disable-next-line max-len
        (accumulator, item) => (checked.has(item.id) ? accumulator.concat(item.id) : accumulator),
        [],
      ),
    );
  }

  render() {
    const {
      classes, devices, deviceType, handleCloseClick, title, zone,
    } = this.props;
    const { checked } = this.state;

    return (
      <div className={classes.wrapper}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <AppBar2 handleCloseClick={handleCloseClick} handleSaveClick={this.handleSaveClick} title={title} />
        <Typography align="center" className={classes.subtitle} variant="h6">
          <SpacedText
            text1={`${deviceType}s`}
            text2={(
              <Typography className={classes.fontMedium} color="textSecondary" component="span">
                {`(${checked.size})`}
              </Typography>
            )}
          />
        </Typography>
        <div className={classes.container}>
          <Divider className={clsx(classes.divider, classes.width, deviceType === 'Sensor' && classes.widthSensor)} />
        </div>
        <div className={classes.formWrapper}>
          <div className={clsx(classes.width, deviceType === 'Sensor' && classes.widthSensor)}>
            <FormGroup className={classes.formGroup}>
              {devices.map(item => (
                <FormControlLabel
                  className={classes.formItem}
                  control={(
                    <Checkbox
                      checked={checked.has(item.id)}
                      color="secondary"
                      onChange={() => this.handleChecked(item.id)}
                    />
                  )}
                  key={item.id.toString()}
                  label={(
                    <div
                      className={clsx(
                        has.call(item, 'zone') && (item.zone || checked.has(item.id)) && classes.formLabel,
                      )}
                    >
                      <Typography>
                        {`${deviceType} ${item.id}`}
                        {item.sensorTypeName && (
                          <>
                            <Separator />
                            <SensorTypeName name={item.sensorTypeName} />
                          </>
                        )}
                      </Typography>
                      {has.call(item, 'zone') && (item.zone || checked.has(item.id)) && (
                        <Typography className={classes.secondaryText} variant="body2">
                          {checked.has(item.id) ? zone.name : <>{item.zone === zone.name ? null : item.zone}</>}
                        </Typography>
                      )}
                    </div>
                  )}
                  labelPlacement="start"
                />
              ))}
            </FormGroup>
            <div className={classes.footer} />
          </div>
        </div>
      </div>
    );
  }
}

ZonesEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  deviceType: PropTypes.string.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  zone: PropTypes.object.isRequired,
};

export default withStyles(styles)(ZonesEdit);

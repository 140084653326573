import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import ListSubheader from './components/ListSubheader';
import Scroll from './components/Scroll';
import SensorTypeName from './components/SensorTypeName';
import { amc400TypeText } from './utils/amc400';
import { AbilityContext } from './utils/auth';
import { alarmTypeText, valueText } from './utils/utils';

const styles = theme => ({
  divider: {
    padding: theme.spacing(0, 2),
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    paddingBottom: theme.spacing(1.5),
  },
  noValue: {
    fontStyle: 'italic',
  },
  wrapper: {
    height: '100%',
  },
});

class SettingsSensorTypesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.scrollTarget = undefined;

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteCancelClick() {
    this.setState({ open: false });
  }

  handleDeleteConfirmClick() {
    const { sensorType, handleDeleteClick: handleDeleteClickProp } = this.props;

    handleDeleteClickProp(sensorType.id);
    this.setState({ open: false });
  }

  sensorTypeAlarm(alarmId, color, disabled) {
    const { classes, sensorType, handleEditItemClick } = this.props;
    const alarm = sensorType.alarms.find(item => item.id === alarmId);

    return (
      <React.Fragment key={alarmId.toString()}>
        <div className={classes.divider}>
          <Divider />
        </div>
        <List className={classes.list} key={alarmId.toString()}>
          <div className={classes.listContent}>
            <ListSubheader disableSticky>{`Alarm ${alarmId}`}</ListSubheader>
            <ListItem className={classes.flexWrap}>
              <ButtonBase
                className={classes.listItem}
                disabled={disabled}
                onClick={() => handleEditItemClick({
                  label: 'Set point',
                  index: 'sensorTypes',
                  index2: 'alarms',
                  index3: 'setPoint',
                  indexId: sensorType.id,
                  indexId2: alarmId,
                })
                }
              >
                <ListItemText
                  primary="Set point"
                  primaryTypographyProps={{ color }}
                  secondary={alarm ? valueText(alarm.setPoint, 1, sensorType.units) : 'Disabled'}
                />
              </ButtonBase>
              {alarm && (
                <ButtonBase
                  className={classes.listItem}
                  disabled={disabled}
                  onClick={() => handleEditItemClick({
                    label: 'Delay',
                    index: 'sensorTypes',
                    index2: 'alarms',
                    index3: 'delay',
                    indexId: sensorType.id,
                    indexId2: alarmId,
                  })
                  }
                >
                  <ListItemText
                    primary="Delay"
                    primaryTypographyProps={{ color }}
                    secondary={valueText(alarm.delay, 0, 'sec')}
                  />
                </ButtonBase>
              )}
            </ListItem>
          </div>
        </List>
      </React.Fragment>
    );
  }

  render() {
    const {
      classes,
      sensorType,
      handleBackClick,
      handleEditItemClick,
      handleSaveScrollTop,
      initialScrollTop,
    } = this.props;
    const { open } = this.state;

    return (
      <AbilityContext.Consumer>
        {(ability) => {
          const disabled = !ability.can('update', 'sensorType');
          const color = disabled ? 'textSecondary' : 'initial';
          return (
            <>
              <div className={classes.wrapper}>
                <ElevationScroll target={this.scrollTarget}>
                  <AppBar
                    disabledDelete={sensorType && sensorType.custom && sensorType.sensorCount !== 0}
                    handleBackClick={handleBackClick}
                    handleDeleteClick={
                      !ability.can('delete', 'sensorType') || !sensorType || !sensorType.custom
                        ? undefined
                        : this.handleDeleteClick
                    }
                    title={sensorType ? <SensorTypeName name={`Sensor type ${sensorType.name}`} /> : 'Sensor type'}
                  />
                </ElevationScroll>
                <Scroll
                  className={classes.listWrapper}
                  handleSaveScrollTop={handleSaveScrollTop}
                  initialScrollTop={initialScrollTop}
                  ref={(node) => {
                    if (node) {
                      this.scrollTarget = node;
                    }
                  }}
                >
                  {sensorType ? (
                    <>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>General settings</ListSubheader>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled || !sensorType.custom}
                              onClick={() => handleEditItemClick({
                                label: 'Name',
                                index: 'sensorTypes',
                                index2: 'name',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Name"
                                primaryTypographyProps={sensorType.custom ? { color } : { color: 'textSecondary' }}
                                secondary={<SensorTypeName name={sensorType.name} />}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled || !sensorType.custom}
                              onClick={() => handleEditItemClick({
                                label: 'Desciption',
                                index: 'sensorTypes',
                                index2: 'description',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Description"
                                primaryTypographyProps={sensorType.custom ? { color } : { color: 'textSecondary' }}
                                secondary={sensorType.description}
                              />
                            </ButtonBase>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled || !sensorType.custom}
                              onClick={() => handleEditItemClick({
                                label: 'Units',
                                index: 'sensorTypes',
                                index2: 'units',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Units"
                                primaryTypographyProps={sensorType.custom ? { color } : { color: 'textSecondary' }}
                                secondary={sensorType.units}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Zero scale',
                                index: 'sensorTypes',
                                index2: 'zeroScale',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Zero scale"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(sensorType.zeroScale, 1, sensorType.units)}
                              />
                            </ButtonBase>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Full scale',
                                index: 'sensorTypes',
                                index2: 'fullScale',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Full scale"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(sensorType.fullScale, 1, sensorType.units)}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Zero buffer',
                                index: 'sensorTypes',
                                index2: 'zeroBuffer',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Zero buffer"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(sensorType.zeroBuffer, 1, sensorType.units, '±')}
                              />
                            </ButtonBase>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Fault limit',
                                index: 'sensorTypes',
                                index2: 'faultLimit',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Fault limit"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(sensorType.faultLimit, 1, sensorType.units)}
                              />
                            </ButtonBase>
                            {sensorType.custom ? (
                              <ButtonBase
                                className={classes.listItem}
                                disabled={disabled}
                                onClick={() => handleEditItemClick({
                                  label: 'AMC-400 type',
                                  lowerCase: false,
                                  index: 'sensorTypes',
                                  index2: 'amc400Id',
                                  indexId: sensorType.id,
                                })
                                }
                              >
                                <ListItemText
                                  primary="AMC-400 type"
                                  primaryTypographyProps={{ color }}
                                  secondary={
                                    sensorType.amc400Id !== undefined && sensorType.amc400Id !== null ? (
                                      <SensorTypeName name={amc400TypeText(sensorType.amc400Id)} />
                                    ) : (
                                      <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                        None
                                      </Typography>
                                    )
                                  }
                                />
                              </ButtonBase>
                            ) : (
                              <>
                                {sensorType.amc400Id !== undefined && (
                                  <ListItemText
                                    primary="AMC-400 type"
                                    primaryTypographyProps={{ color: 'textSecondary' }}
                                    secondary={<SensorTypeName name={amc400TypeText(sensorType.amc400Id)} />}
                                  />
                                )}
                              </>
                            )}
                          </ListItem>
                        </div>
                      </List>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>Alarm settings</ListSubheader>
                          <ListItem className={classes.flexWrap}>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Alarm type',
                                index: 'sensorTypes',
                                index2: 'alarmType',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Alarm type"
                                primaryTypographyProps={{ color }}
                                secondary={alarmTypeText(sensorType.alarmType)}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Alarm hysteresis',
                                index: 'sensorTypes',
                                index2: 'alarmHysteresis',
                                indexId: sensorType.id,
                              })
                              }
                            >
                              <ListItemText
                                primary="Alarm hysteresis"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(sensorType.alarmHysteresis, 2, sensorType.units)}
                              />
                            </ButtonBase>
                          </ListItem>
                        </div>
                      </List>
                      {this.sensorTypeAlarm(1, color, disabled)}
                      {this.sensorTypeAlarm(2, color, disabled)}
                      {sensorType.alarmType !== 'window' && this.sensorTypeAlarm(3, color, disabled)}
                    </>
                  ) : (
                    <div />
                  )}
                </Scroll>
              </div>
              <Dialog open={open} onClose={this.handleDeleteCancelClick} PaperProps={{ className: classes.paper }}>
                <DialogTitle>Delete this sensor?</DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleDeleteCancelClick}>Cancel</Button>
                  <Button color="secondary" onClick={this.handleDeleteConfirmClick}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </AbilityContext.Consumer>
    );
  }
}

SettingsSensorTypesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleEditItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  sensorType: PropTypes.object,
};

SettingsSensorTypesDetails.defaultProps = {
  sensorType: undefined,
};

export default withStyles(styles)(SettingsSensorTypesDetails);

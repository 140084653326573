import firebase from './firebase';
import { sortByName } from './sort';

class Session {
  constructor() {
    this.user = undefined;

    this.handleUserChanged = undefined;

    firebase.auth().onAuthStateChanged((user) => {
      this.user = user;

      if (this.handleUserChanged) this.handleUserChanged(this.user);
    });
  }
}

const serverAddress = process.env.NODE_ENV === 'development' ? 'http://localhost' : '';

const session = new Session();

function restDeleteCall(path) {
  const basicAuth = `Basic ${btoa('monitor:armstrong')}`;
  const headers = new Headers();

  headers.set('Authorization', basicAuth);
  return fetch(`${serverAddress}/api/v1/${path}/`, {
    method: 'DELETE',
    headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.resolve();
    })
    .catch(() => Promise.resolve());
}

function restGetCall(path) {
  const basicAuth = `Basic ${btoa('monitor:armstrong')}`;
  const headers = new Headers();

  headers.set('Authorization', basicAuth);
  return fetch(`${serverAddress}/api/v1/${path}/`, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.resolve();
    })
    .catch(() => Promise.resolve());
}

function restPostPutCall(path, value, method) {
  const basicAuth = `Basic ${btoa('monitor:armstrong')}`;
  const headers = new Headers();

  headers.set('Authorization', basicAuth);
  headers.set('Content-Type', 'application/json');
  return fetch(`${serverAddress}/api/v1/${path}/`, {
    method,
    headers,
    body: JSON.stringify(value),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.resolve();
    })
    .catch(() => Promise.resolve());
}

function restPostCall(path, value) {
  return restPostPutCall(path, value, 'POST');
}

function restPutCall(path, value) {
  return restPostPutCall(path, value, 'PUT');
}

export function apiAddSensors(value) {
  return restPostCall('sensors', value);
}

export function apiGetSensor(id) {
  return restGetCall(`sensors/${id}`);
}

export function apiGetSensors() {
  return restGetCall('sensors').then(data => (data && data.sensors ? data.sensors.sort((a, b) => a.id - b.id) : []));
}

export function apiModifySensor(id, value) {
  return restPutCall(`sensors/${id}`, value);
}

export function apiDeleteSensor(id) {
  return restDeleteCall(`sensors/${id}`);
}

export function apiAddRelays(value) {
  return restPostCall('relays', value);
}

export function apiGetRelay(id) {
  return restGetCall(`relays/${id}`);
}

export function apiGetRelays() {
  return restGetCall('relays').then(data => (data && data.relays ? data.relays.sort((a, b) => a.id - b.id) : []));
}

export function apiModifyRelay(id, value) {
  return restPutCall(`relays/${id}`, value);
}

export function apiDeleteRelay(id) {
  return restDeleteCall(`relays/${id}`);
}

export function apiAddAnalogOuts(value) {
  return restPostCall('analogouts', value);
}

export function apiGetAnalogOut(id) {
  return restGetCall(`analogouts/${id}`);
}

export function apiGetAnalogOuts() {
  return restGetCall('analogouts').then(data => (data && data.analogOuts ? data.analogOuts.sort((a, b) => a.id - b.id) : []));
}

export function apiModifyAnalogOut(id, value) {
  return restPutCall(`analogouts/${id}`, value);
}
export function apiDeleteAnalogOut(id) {
  return restDeleteCall(`analogouts/${id}`);
}

export function apiAddZone(value) {
  return restPostCall('zones', value);
}

export function apiGetZone(id) {
  return restGetCall(`zones/${id}`);
}

export function apiGetZones() {
  return restGetCall('zones').then(data => (data && data.zones ? sortByName(data.zones) : []));
}

export function apiModifyZone(id, value) {
  return restPutCall(`zones/${id}`, value);
}

export function apiDeleteZone(id) {
  return restDeleteCall(`zones/${id}`);
}

export function apiGetSettings() {
  return restGetCall('settings');
}

export function apiGetModbus() {
  return restGetCall('lanes');
}

export function apiModifySettings(value) {
  return restPutCall('settings', value);
}

export function apiModifyModbus(id, value) {
  return restPutCall(`lanes/${id}`, value);
}

export function apiAddSensorType(value) {
  return restPostCall('sensortypes', value);
}

export function apiDeleteSensorType(id) {
  return restDeleteCall(`sensortypes/${id}`);
}

export function apiDownloadConfig() {
  return restGetCall('downloadconfig');
}

export function apiUploadConfig(config) {
  return restPostCall('uploadconfig', config);
}

export function apiGetFileName() {
  return restGetCall('filename');
}

export function apiSetFileName(value) {
  return restPostCall('filename', value);
}

export function apiOnUserChanged(handleUserChanged) {
  session.handleUserChanged = handleUserChanged;

  setTimeout(() => {
    if (session.handleUserChanged) session.handleUserChanged(session.user);
  });
}

export function apiAccountExists(email) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((signinMethods) => {
        resolve(signinMethods.length > 0);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function apiSignIn(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function apiModifyTimeZone(value) {
  return restPutCall('timezone', value);
}

export function apiGetFirstStartUp() {
  return restGetCall('firststartup').then((data) => {
    if (data) {
       return data;
    }
    return data;
  });
}

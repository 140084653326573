import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import DeviceHubIcon from '@material-ui/icons/DeviceHubOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SpeedIcon from '@material-ui/icons/SpeedOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import ElectricSwitchIcon from '../images/ElectricSwitch';
import FileDownloadOutlined from '../images/FileDownloadOutlined';
import FileUploadOutlined from '../images/FileUploadOutlined';
import PulseIcon from '../images/Pulse';
import ZoneIcon from '../images/Zone';
import { iconSize } from '../utils/styles';

const styles = theme => ({
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  badge: {
    top: 2,
    right: 2,
  },
  badgeColor: {
    backgroundColor: theme.palette.warning.main,
  },
  divider: {
    padding: theme.spacing(0, 2),
  },
  dot: {
    top: 6,
    right: 6,
  },
  dotColor: {
    color: 'blue',
  },
  icon: {
    padding: theme.spacing(0.75, 0.5, 0),
  },
  iconSize,
  selected: {
    color: theme.palette.secondary.main,
  },
  unselected: {
    color: theme.palette.text.icon,
  },
});

function SidebarNav(props) {
  const {
    classes,
    handleAnalogOutsClick,
    handleCommunicationsClick,
    handleDownloadClick,
    handleRelaysClick,
    handleSensorsClick,
    handleSettingsClick,
    handleUploadClick,
    handleZonesClick,
    index,
    warnings,
  } = props;

  return (
    <>
      <List className={classes.background}>
        <ListItem button onClick={handleSensorsClick}>
          <ListItemIcon className={clsx(index !== 2 ? classes.unselected : classes.selected)}>
            <Badge
              classes={{
                badge: classes.dot,
                colorError: classes.badgeColor,
              }}
              color="error"
              invisible={warnings === undefined || !warnings.sensors}
              variant="dot"
            >
              <div className={classes.icon}>
                <SpeedIcon className={classes.iconSize} />
              </div>
            </Badge>
          </ListItemIcon>
          <ListItemText className={clsx(index !== 2 ? classes.unselected : classes.selected)} primary="Sensors" />
        </ListItem>
        <ListItem button onClick={handleRelaysClick}>
          <ListItemIcon className={clsx(index !== 4 ? classes.unselected : classes.selected)}>
            <Badge
              classes={{
                badge: classes.dot,
                colorError: classes.badgeColor,
              }}
              color="error"
              invisible={warnings === undefined || !warnings.relays}
              variant="dot"
            >
              <div className={classes.icon}>
                <ElectricSwitchIcon className={classes.iconSize} />
              </div>
            </Badge>
          </ListItemIcon>
          <ListItemText className={clsx(index !== 4 ? classes.unselected : classes.selected)} primary="Relays" />
        </ListItem>
        <ListItem button onClick={handleAnalogOutsClick}>
          <ListItemIcon className={clsx(index !== 6 ? classes.unselected : classes.selected)}>
            <Badge
              classes={{
                badge: classes.dot,
                colorError: classes.badgeColor,
              }}
              color="error"
              invisible={warnings === undefined || !warnings.analogOuts}
              variant="dot"
            >
              <div className={classes.icon}>
                <PulseIcon className={classes.iconSize} />
              </div>
            </Badge>
          </ListItemIcon>
          <ListItemText
            className={clsx(index !== 6 ? classes.unselected : classes.selected)}
            primary="Analog outputs"
          />
        </ListItem>
        <ListItem button onClick={handleZonesClick}>
          <ListItemIcon className={clsx(index !== 8 ? classes.unselected : classes.selected)}>
            <Badge
              classes={{
                badge: classes.dot,
                colorError: classes.badgeColor,
              }}
              color="error"
              invisible={warnings === undefined || !warnings.zones}
              variant="dot"
            >
              <div className={classes.icon}>
                <ZoneIcon className={classes.iconSize} />
              </div>
            </Badge>
          </ListItemIcon>
          <ListItemText className={clsx(index !== 8 ? classes.unselected : classes.selected)} primary="Zones" />
        </ListItem>
        <ListItem button onClick={handleCommunicationsClick}>
          <ListItemIcon className={clsx(index !== 10 ? classes.unselected : classes.selected)}>
            <Badge
              classes={{
                badge: classes.dot,
                colorError: classes.badgeColor,
              }}
              color="error"
              invisible={warnings === undefined || !warnings.devices}
              variant="dot"
            >
              <div className={classes.icon}>
                <DeviceHubIcon className={classes.iconSize} />
              </div>
            </Badge>
          </ListItemIcon>
          <ListItemText
            className={clsx(index !== 10 ? classes.unselected : classes.selected)}
            primary="Communications"
          />
        </ListItem>
        <ListItem button onClick={handleSettingsClick}>
          <ListItemIcon className={clsx(index !== 12 ? classes.unselected : classes.selected)}>
            <div className={classes.icon}>
              <SettingsIcon className={classes.iconSize} />
            </div>
          </ListItemIcon>
          <ListItemText className={clsx(index !== 12 ? classes.unselected : classes.selected)} primary="Settings" />
        </ListItem>
      </List>
      <div className={classes.divider}>
        <Divider />
      </div>
      <List className={classes.background}>
        <ListItem button onClick={handleUploadClick}>
          <ListItemIcon className={classes.unselected}>
            <div className={classes.icon}>
              <FileUploadOutlined className={classes.iconSize} />
            </div>
          </ListItemIcon>
          <ListItemText className={classes.unselected} primary="Upload" />
        </ListItem>
        <ListItem button onClick={handleDownloadClick}>
          <ListItemIcon className={classes.unselected}>
            <div className={classes.icon}>
              <FileDownloadOutlined className={classes.iconSize} />
            </div>
          </ListItemIcon>
          <ListItemText className={classes.unselected} primary="Download" />
        </ListItem>
      </List>
    </>
  );
}

SidebarNav.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAnalogOutsClick: PropTypes.func.isRequired,
  handleCommunicationsClick: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  handleRelaysClick: PropTypes.func.isRequired,
  handleSensorsClick: PropTypes.func.isRequired,
  handleSettingsClick: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  handleZonesClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  warnings: PropTypes.object,
};

SidebarNav.defaultProps = {
  warnings: undefined,
};

export default withStyles(styles)(SidebarNav);

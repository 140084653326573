import PropTypes from 'prop-types';
import React from 'react';
import EditDialog from './components/EditDialog';
import EditKeyboard from './components/EditKeyboard';
import {
  apiDeleteZone, apiGetAnalogOuts, apiGetRelays, apiGetSensors, apiGetZones, apiModifyZone,
} from './utils/api';
import deepCopy from './utils/deepCopy';
import has from './utils/has';
import history from './utils/history';
import { optionsAggregationType } from './utils/options';
import { patternNumber } from './utils/patterns';
import ZonesDetails from './ZonesDetails';
import ZonesEdit from './ZonesEdit';

class Zones extends React.Component {
  constructor(props) {
    super(props);

    const { zone } = props;

    this.state = {
      edit: undefined,
      metadata: null,
      options: [],
      zone,
      zones: [],
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditItemClick = this.handleEditItemClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.units = this.units.bind(this);
    this.value = this.value.bind(this);

    history.defaultState({ metadata: null });
  }

  componentDidMount() {
    const { id } = this.props;

    this.mounted = true;
    apiGetZones(id).then((zones) => {
      if (this.mounted) {
        this.setState({ zones });
      }
    });

    history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    this.mounted = false;

    history.removeListener(this.handleLocationChanged);
  }

  getDeviceType() {
    const { metadata } = this.state;

    if (metadata) {
      if (metadata.index === 'sensors') return 'Sensor';
      if (metadata.index === 'alarms' && metadata.index2 === 'relays') return 'Relay';
      if (metadata.index === 'analogOuts') return 'Analog out';
    }
    return '';
  }

  handleDeleteClick() {
    const { handleBackClick, id } = this.props;

    apiDeleteZone(id).then(() => {
      if (this.mounted) {
        this.setState({ edit: false });
      }
    });

    handleBackClick();
  }

  handleEditItemClick(item) {
    const { index } = item;

    if (index === 'aggregationType') {
      this.setState({ edit: true, metadata: item, options: optionsAggregationType });
    } else if (index === 'faultRelay') {
      apiGetRelays().then((relays) => {
        if (this.mounted) {
          const options = relays.map(value => ({ label: `Relay ${value.id}`, value: value.id }));
          options.push({ label: 'None', value: '' });
          this.setState({
            edit: true,
            metadata: item,
            options,
          });
        }
      });
    } else {
      history.pushAppend({ metadata: item, options: [] });
    }
  }

  handleLocationChanged(value) {
    const { zone } = this.state;

    const { metadata, options } = value;

    if (metadata) {
      const { index, indexId, index2 } = metadata;

      if (index === 'sensors') {
        apiGetSensors().then((sensors) => {
          if (this.mounted) {
            this.setState({
              edit: true,
              metadata: {
                ...metadata,
                devices: sensors.map(item => ({
                  id: item.id,
                  sensorTypeName: item.sensorType.name,
                  checked: zone.sensors.find(item2 => item2.id === item.id) !== undefined,
                  zone: item.zone && item.zone.name,
                })),
              },
              options,
            });
          }
        });
      } else if (index === 'alarms' && index2 === 'relays') {
        apiGetRelays().then((relays) => {
          if (this.mounted) {
            const alarm = zone.alarms.find(item => item.id === indexId);
            if (alarm) {
              this.setState({
                edit: true,
                metadata: {
                  ...metadata,
                  devices: relays.map(item => ({
                    id: item.id,
                    checked: alarm.relays.find(item2 => item2 === item.id) !== undefined,
                  })),
                },
                options,
              });
            }
          }
        });
      } else if (index === 'analogOuts') {
        apiGetAnalogOuts().then((analogOuts) => {
          if (this.mounted) {
            this.setState({
              edit: true,
              metadata: {
                ...metadata,
                devices: analogOuts.map(item => ({
                  id: item.id,
                  checked: zone.analogOuts.find(item2 => item2 === item.id) !== undefined,
                  zone: item.zone && item.zone.name,
                })),
              },
              options,
            });
          }
        });
      } else {
        this.setState({ edit: true, metadata, options });
      }
    } else {
      this.setState({ edit: false });
    }
  }

  handleSaveClick(value) {
    const { id } = this.props;
    const { metadata, options, zone } = this.state;

    const closeDialogOrGoBack = () => {
      if (options.length !== 0) {
        this.setState({ edit: false });
      } else {
        history.goBack();
      }
    };

    if (!metadata) {
      closeDialogOrGoBack();
      return;
    }

    const { index, index2, indexId } = metadata;
    let value2 = {};

    if (index === 'sensors') {
      if (JSON.stringify(value) === JSON.stringify(zone[index].map(item => item.id))) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { sensorIds: value };
    } else if (index === 'alarms' && index2 === 'relays') {
      const alarms = deepCopy(zone[index]).map(item => ({
        id: item.id,
        relays: item.id === indexId ? value : item.relays,
      }));
      if (JSON.stringify(alarms) === JSON.stringify(zone[index].map(item => ({ id: item.id, relays: item.relays })))) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { alarms };
    } else if (index === 'analogOuts') {
      if (JSON.stringify(value) === JSON.stringify(zone[index].map(item => item.id))) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { analogOuts: value };
    } else {
      let newValue = value;
      if (index === 'samplePeriod') newValue = Number(value);
      else if (index === 'faultRelay') newValue = value === '' ? null : Number(value);
      if (newValue === zone[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { [index]: newValue };
    }

    apiModifyZone(id, value2).then((data) => {
      if (this.mounted) {
        if (data) this.setState({ zone: data });
        if (index !== 'enabled') closeDialogOrGoBack();
      }
    });
  }

  inputCheck(value) {
    const { id } = this.props;
    const { metadata, zones } = this.state;

    if (metadata.index === 'name') {
      if (value.length > 24) {
        return 'Maximum characters exceeded. Name must be less than 25 chars.';
      }
      if (zones.some(item => item.name === value && item.id !== id)) {
        return 'Duplicate name. Zone name must be unique.';
      }
    }

    if (metadata.index === 'samplePeriod') {
      const sampleperiod = Number(value);
      if (sampleperiod < 1 || sampleperiod > 60) {
        return 'Invalid Sample Period. Valid range is 1 to 60.';
      }
    }

    return null;
  }

  inputPattern() {
    const { metadata } = this.state;

    if (metadata) {
      if (metadata.index === 'samplePeriod') {
        return patternNumber;
      }
    }

    return undefined;
  }

  units() {
    const { metadata } = this.state;

    if (metadata) {
      const { index } = metadata;
      if (index === 'samplePeriod') {
        return 'sec';
      }
    }

    return undefined;
  }

  value() {
    const { metadata, options, zone } = this.state;

    if (metadata && zone && options.length !== 0) {
      const { index } = metadata;
      return zone[index] === null || zone[index] === undefined || zone[index].length === 0 ? '' : zone[index];
    }

    return '';
  }

  render() {
    const { handleBackClick, id } = this.props;
    const {
      edit, metadata, options, zone,
    } = this.state;

    if (edit === undefined) return null;

    return (
      <>
        {edit && (
          <>
            {options.length === 0 && (
              <>
                {['analogOuts', 'sensors'].includes(metadata.index)
                || (metadata.index === 'alarms' && metadata.index2 === 'relays') ? (
                  <ZonesEdit
                    devices={metadata.devices}
                    deviceType={this.getDeviceType()}
                    handleCloseClick={() => {
                      history.goBack();
                    }}
                    handleSaveClick={this.handleSaveClick}
                    title = { (metadata.index === 'alarms' && metadata.index2 === 'relays' 
                      ? `Edit Zone Alarm${metadata.indexId} ${zone.name}`
                      : `Edit Zone ${zone.name}`
                     )} 
                    zone={zone}
                  />
                  ) : (
                    <EditKeyboard
                      handleCloseClick={() => {
                        history.goBack();
                      }}
                      handleSaveClick={this.handleSaveClick}
                      label={metadata.label}
                      inputCheck={this.inputCheck}
                      inputPattern={this.inputPattern()}
                      title={`Edit Zone ${zone.name}`}
                      units={this.units()}
                      value={zone && zone[metadata.index]}
                    />
                  )}
              </>
            )}
          </>
        )}
        {(!edit || (edit && options.length !== 0)) && (
          <ZonesDetails
            handleBackClick={handleBackClick}
            handleDeleteClick={this.handleDeleteClick}
            handleEditItemClick={this.handleEditItemClick}
            id={id}
            zone={zone}
          />
        )}
        <EditDialog
          handleCloseClick={() => {
            this.setState({ edit: false });
          }}
          handleSaveClick={this.handleSaveClick}
          label={metadata && has.call(metadata, 'label') ? metadata.label : ''}
          open={edit && options.length !== 0}
          options={options}
          value={this.value()}
        />
      </>
    );
  }
}

Zones.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  zone: PropTypes.object.isRequired,
};

export default Zones;

import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import Scroll from './components/Scroll';
import SensorTypeName from './components/SensorTypeName';
import { Auth } from './utils/auth';
import { iconFabSize } from './utils/styles';

const styles = theme => ({
  fab: {
    backgroundColor: theme.palette.background.default,
    bottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
    color: theme.palette.secondary.main,
    position: 'absolute',
    right: theme.spacing(2),
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  iconFabSize,
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listScroll: {
    height: '100%',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    overflow: 'auto',
    paddingBottom: theme.spacing(1.5),
  },
  wrapper: {
    height: '100%',
  },
});

class SettingsSensorTypesList extends React.Component {
  constructor(props) {
    super(props);

    this.scrollTarget = undefined;
  }

  render() {
    const {
      classes,
      sensorTypes,
      handleAddClick,
      handleBackClick,
      handleListItemClick,
      handleSaveScrollTop,
      initialScrollTop,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <ElevationScroll target={this.scrollTarget}>
          <AppBar handleBackClick={handleBackClick} title="Sensor types" />
        </ElevationScroll>
        <div
          className={classes.listWrapper}
          ref={(node) => {
            if (node) {
              this.scrollTarget = node;
            }
          }}
        >
          {sensorTypes ? (
            <div className={classes.wrapper}>
              <Scroll
                className={classes.listScroll}
                handleSaveScrollTop={handleSaveScrollTop}
                initialScrollTop={initialScrollTop}
              >
                <List className={classes.list}>
                  <div className={classes.listContent}>
                    <ListItem className={classes.flexWrap}>
                      {sensorTypes.map(item => (
                        <ListItemText
                          className={classes.listItem}
                          key={item.id.toString()}
                          onClick={() => handleListItemClick(item.id)}
                          primary={<SensorTypeName name={item.name} />}
                          secondary={item.description}
                        />
                      ))}
                    </ListItem>
                  </div>
                </List>
              </Scroll>
              <Auth do="create" on="sensorType">
                <Fab className={classes.fab} onClick={handleAddClick}>
                  <AddIcon className={classes.iconFabSize} />
                </Fab>
              </Auth>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

SettingsSensorTypesList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  sensorTypes: PropTypes.arrayOf(PropTypes.object),
};

SettingsSensorTypesList.defaultProps = {
  sensorTypes: [],
};

export default withStyles(styles)(SettingsSensorTypesList);

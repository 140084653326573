import PropTypes from 'prop-types';
import React from 'react';
import AddKeyboard from './components/AddKeyboard';
import AddRadio from './components/AddRadio';
import SensorTypeName from './components/SensorTypeName';
import has from './utils/has';
import { optionsChannel2, optionsChannel, optionsLane, optionsSensorType } from './utils/options';
import { patternNumber } from './utils/patterns';

class SensorsAddMulti extends React.Component {
  static minStep() {
    return 1;
  }

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      channel: '',
      sensorTypeId: '',
      lane: '',
      step: 1,
      type: '',
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.options = this.options.bind(this);
    this.label = this.label.bind(this);
    this.maxStep = this.maxStep.bind(this);
    this.value = this.value.bind(this);
  }

  handleBackClick() {
    const { step } = this.state;

    this.setState({ step: step - 1 });
  }

  handleChange(value) {
    const { step } = this.state;

    if (step === 1) {
      this.setState({ type: value });
    } else if (step === 2) {
      this.setState({ sensorTypeId: Number(value) });
    } else if (step === 3) {
      this.setState({ lane: Number(value) });
    } else if (step === 4) {
      this.setState({ address: value });
    } else if (step === 5) {
      this.setState({ channel: Number(value) });
    }
  }

  handleNextClick() {
    const { step } = this.state;

    this.setState({ step: step + 1 });
  }

  handleSaveClick() {
    const { addCount, handleSaveClick: handleSaveClickProp } = this.props;
    const {
      address, channel, sensorTypeId, lane, type,
    } = this.state;

    handleSaveClickProp(
      ((type === 'BC8AII') || (type === 'DTR'))
        ? {
          addCount,
          address: Number(address),
          channel,
          sensorTypeId,
          lane,
          type,
        }
        : {
          addCount,
          address: Number(address),
          sensorTypeId,
          lane,
          type,
        },
    )
  }

  inputCheck(value) {
    const { step } = this.state;

    if (step === 4) {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    return null;
  }

  inputPattern() {
    const { step } = this.state;

    if (step === 4) {
      return patternNumber;
    }

    return undefined;
  }

  label() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return 'Device';
      case 2:
        return 'Sensor type';
      case 3:
        return 'Modbus lane';
      case 4:
        return 'Starting modbus address';
      case 5:
        return 'Starting modbus channel';
      default:
        return 'Unknown';
    }
  }

  maxStep() {
    const { type } = this.state;

    return ((type === 'BC8AII') || (type === 'DTR')) ? 5 : 4;
  }

  options() {
    const { sensorTypes: sensorTypesProp } = this.props;
    const { step, type } = this.state;

    switch (step) {
      case 1:
        return optionsSensorType;
      case 2: {
        const sensorTypes = sensorTypesProp.map(item => ({
          label: <SensorTypeName name={item.name} />,
          value: item.id,
          amc400Id: item.amc400Id,
        }));
        return type === 'AMC-400'
          ? sensorTypes.filter(item => has.call(item, 'amc400Id') && item.amc400Id !== undefined)
          : sensorTypes;
      }
      case 3:
        return optionsLane;
      case 5:
        return (type === 'DTR') ? optionsChannel2 : optionsChannel;
      default:
        return [];
    }
  }

  value() {
    const {
      address, channel, sensorTypeId, lane, step, type,
    } = this.state;

    switch (step) {
      case 1:
        return type;
      case 2:
        return sensorTypeId;
      case 3:
        return lane;
      case 4:
        return address;
      case 5:
        return channel;
      default:
        return '';
    }
  }

  render() {
    const { handleCloseClick } = this.props;
    const { step } = this.state;

    return step === 4 ? (
      <AddKeyboard
        disabledBack={step === SensorsAddMulti.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === this.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === this.maxStep() ? this.handleSaveClick : undefined}
        inputCheck={this.inputCheck}
        inputPattern={this.inputPattern()}
        label={this.label()}
        subtitle={`Step ${step} of ${this.maxStep()}`}
        title="Add sensor"
        value={this.value()}
      />
    ) : (
      <AddRadio
        disabledBack={step === SensorsAddMulti.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === this.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === this.maxStep() ? this.handleSaveClick : undefined}
        label={this.label()}
        options={this.options()}
        subtitle={`Step ${step} of ${this.maxStep()}`}
        title="Add sensor"
        value={this.value()}
      />
    );
  }
}

SensorsAddMulti.propTypes = {
  addCount: PropTypes.number.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SensorsAddMulti;

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Scroll from './Scroll';

const styles = theme => ({
  dialog: {
    minWidth: 356,
  },
  dialogContent: {
    flex: '1 1 auto',
    padding: theme.spacing(0, 3),
    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
    marginBottom: 5,
  },
  item: {
    minHeight: 70,
  },
  noValue: {
    fontStyle: 'italic',
  },
  radio: {
    marginRight: theme.spacing(4),
  },
  secondaryContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
});

class EditDialog extends React.Component {
  static scrollTop(options, value) {
    const index = options.findIndex(item => item.value === value);
    return index < 4 ? 0 : (index - 1) * 70;
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleChange(event) {
    const { handleSaveClick, inputCheck, value } = this.props;

    if (value !== event.target.value) {
      if (inputCheck) {
        const error = inputCheck(event.target.value);
        if (error) {
          this.setState({ error });
          return;
        }
      }
      handleSaveClick(event.target.value);
    }
  }

  handleCloseClick() {
    const { handleCloseClick: handleCloseClickProp } = this.props;

    this.setState({ error: null });
    handleCloseClickProp();
  }

  render() {
    const {
      classes, handleCloseClick, label, lowerCase, open, options, value,
    } = this.props;
    const { error } = this.state;

    return (
      <Dialog classes={{ paper: classes.dialog }} open={open}>
        {error ? (
          <>
            <DialogTitle>Invalid setting</DialogTitle>
            <DialogContent>
              <DialogContentText>{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseClick}>Done</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>{`Set the ${lowerCase ? label.toLowerCase() : label}`}</DialogTitle>
            <Scroll className={classes.dialogContent} initialScrollTop={EditDialog.scrollTop(options, value)}>
              <FormControl fullWidth>
                <RadioGroup name={label} onChange={this.handleChange} value={value}>
                  {options.map(item => (
                    <FormControlLabel
                      className={classes.item}
                      control={<Radio className={classes.radio} color="secondary" />}
                      key={item.value.toString()}
                      label={
                        item.secondary ? (
                          <div className={classes.secondaryContainer}>
                            <Typography className={clsx(item.value === '' && classes.noValue)} component="span">
                              {item.label}
                            </Typography>
                            <Typography className={classes.secondaryText} variant="body2">
                              {item.secondary}
                            </Typography>
                          </div>
                        ) : (
                          <Typography className={clsx(item.value === '' && classes.noValue)} component="span">
                            {item.label}
                          </Typography>
                        )
                      }
                      value={item.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Scroll>
            <DialogActions>
              <Button onClick={handleCloseClick}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  inputCheck: PropTypes.func,
  label: PropTypes.string.isRequired,
  lowerCase: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.any,
};

EditDialog.defaultProps = {
  inputCheck: undefined,
  lowerCase: true,
  value: '',
};

export default withStyles(styles)(EditDialog);

import PropTypes from 'prop-types';
import React from 'react';
import AddKeyboard from './components/AddKeyboard';
import AddRadio from './components/AddRadio';
import { apiAddZone } from './utils/api';
import { optionsAggregationType } from './utils/options';
import { patternNumber } from './utils/patterns';

class ZonesAdd extends React.Component {
  static minStep() {
    return 1;
  }

  static maxStep() {
    return 3;
  }

  constructor(props) {
    super(props);

    this.state = {
      aggregationType: '',
      name: '',
      samplePeriod: '60',
      step: 1,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.options = this.options.bind(this);
    this.label = this.label.bind(this);
    this.units = this.units.bind(this);
    this.value = this.value.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleBackClick() {
    const { step } = this.state;

    this.setState({ step: step - 1 });
  }

  handleChange(value) {
    const { step } = this.state;

    if (step === 1) {
      this.setState({ name: value });
    } else if (step === 2) {
      this.setState({ aggregationType: value });
    } else if (step === 3) {
      this.setState({ samplePeriod: value });
    }
  }

  handleNextClick() {
    const { step } = this.state;

    this.setState({ step: step + 1 });
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const { aggregationType, name, samplePeriod } = this.state;

    apiAddZone({
      aggregationType,
      name,
      samplePeriod: Number(samplePeriod),
    }).then((data) => {
      if (this.mounted) {
        if (data) {
          handleSaveClickProp(data.id);
        } else {
          handleSaveClickProp();
        }
      }
    });
  }

  inputCheck(value) {
    const { zones } = this.props;
    const { step } = this.state;

    if (step === 1) {
      if (value.length > 24) {
        return 'Maximum characters exceeded. Name must be less than 25 chars.';
      }
      if (zones.some(item => item.name === value)) {
        return 'Duplicate name. Zone name must be unique.';
      }
    }

    if (step === 3) {
      const sampleperiod = Number(value);
      if (sampleperiod < 1 || sampleperiod > 60) {
        return 'Invalid Sample Period. Valid range is 1 to 60.';
      }
    }

    return null;
  }

  inputPattern() {
    const { step } = this.state;

    if (step === 3) {
      return patternNumber;
    }

    return undefined;
  }

  label() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return 'Name';
      case 2:
        return 'Aggregation type';
      case 3:
        return 'Sample period';
      default:
        return 'Unknown';
    }
  }

  options() {
    const { step } = this.state;

    return step === 2 ? optionsAggregationType : [];
  }

  units() {
    const { step } = this.state;

    return step === 3 ? 'sec' : undefined;
  }

  value() {
    const {
      aggregationType, name, samplePeriod, step,
    } = this.state;

    switch (step) {
      case 1:
        return name;
      case 2:
        return aggregationType;
      case 3:
        return samplePeriod;
      default:
        return '';
    }
  }

  render() {
    const { handleCloseClick } = this.props;
    const { step } = this.state;

    return [1, 3].includes(step) ? (
      <AddKeyboard
        disabledBack={step === ZonesAdd.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === ZonesAdd.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === ZonesAdd.maxStep() ? this.handleSaveClick : undefined}
        inputCheck={this.inputCheck}
        inputPattern={this.inputPattern()}
        label={this.label()}
        subtitle={`Step ${step} of ${ZonesAdd.maxStep()}`}
        title="Add zone"
        units={this.units()}
        value={this.value()}
      />
    ) : (
      <AddRadio
        disabledBack={step === ZonesAdd.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === ZonesAdd.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === ZonesAdd.maxStep() ? this.handleSaveClick : undefined}
        label={this.label()}
        options={this.options()}
        subtitle={`Step ${step} of ${ZonesAdd.maxStep()}`}
        title="Add zone"
        value={this.value()}
      />
    );
  }
}

ZonesAdd.propTypes = {
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ZonesAdd;

const typography = {
  h6: {
    fontSize: '26px',
  },
  subtitle1: {
    fontSize: '19px',
  },
  subtitle2: {
    fontSize: '17px',
  },
  body1: {
    fontSize: '19px',
  },
  body2: {
    fontSize: '17px',
  },
  button: {
    fontSize: '16px',
    textTransform: 'none',
  },
  caption: {
    fontSize: '14px',
  },
};

export default typography;

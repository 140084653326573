import PropTypes from 'prop-types';
import React from 'react';
import SensorsAddMulti from './SensorsAddMulti';
import SensorsAddSingle from './SensorsAddSingle';
import { apiAddSensors, apiGetSettings, apiGetZones } from './utils/api';

class SensorsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sensorTypes: [],
      zones: [],
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    apiGetSettings().then((settings) => {
      if (this.mounted) {
        this.setState({ sensorTypes: settings.sensorTypes });
      }
    });
    apiGetZones().then((zones) => {
      if (this.mounted) {
        this.setState({
          zones,
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSaveClick(value) {
    const { addCount, handleSaveClick: handleSaveClickProp } = this.props;

    apiAddSensors(value).then((data) => {
      if (this.mounted) {
        if (addCount === 1 && data) {
          handleSaveClickProp(data.config.id);
        } else {
          handleSaveClickProp();
        }
      }
    });
  }

  render() {
    const { addCount, handleCloseClick } = this.props;
    const { sensorTypes, zones } = this.state;

    return addCount === 1 ? (
      <SensorsAddSingle
        handleCloseClick={handleCloseClick}
        handleSaveClick={this.handleSaveClick}
        sensorTypes={sensorTypes}
        zones={zones}
      />
    ) : (
      <SensorsAddMulti
        addCount={addCount}
        handleCloseClick={handleCloseClick}
        handleSaveClick={this.handleSaveClick}
        sensorTypes={sensorTypes}
      />
    );
  }
}

SensorsAdd.propTypes = {
  addCount: PropTypes.number.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default SensorsAdd;

import common from '@material-ui/core/colors/common';
import { darkPrimary } from './colors';

export const paletteButton = {
  primary: {
    dark: common.white,
    main: common.white,
  },
};

export const paletteDark = {
  type: 'dark',
  primary: {
    main: darkPrimary,
  },
  secondary: {
    main: 'rgb(127, 255, 212)',
  },
  success: {
    main: 'rgb(161, 230, 0)',
  },
  warning: {
    main: 'rgb(255, 96, 0)',
  },
  error: {
    main: 'rgb(255, 34, 4)',
  },
  background: {
    paper: 'rgb(25, 60, 78)',
    default: darkPrimary,
  },
};

export const paletteLight = {
  primary: {
    main: 'rgb(11, 49, 68)',
  },
  secondary: {
    main: 'rgb(0, 102, 173)',
  },
  success: {
    main: 'rgb(37, 204, 24)',
  },
  warning: {
    main: 'rgb(255, 96, 0)',
  },
  error: {
    main: 'rgb(255, 34, 4)',
  },
  background: {
    default: common.white,
  },
  text: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(0, 0, 0, 0.60)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.16)',
};

export const paletteSelect = {
  primary: {
    main: darkPrimary,
  },
  background: {
    default: common.white,
  },
};

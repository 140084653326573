import { Response, Server } from 'miragejs';
import { defaultAnalogOut, defaultDb, defaultRelay, defaultSensor, defaultSensorType, defaultZone, newId, sortIds } from './db';
import deepCopy from './deepCopy';
import demo from './demo';
import has from './has';

let db = null;

const maxAddress = 247;

function dumpConfig() {
  return {
    analogOuts: db.analogOuts.map(item => ({
      address: item.address,
      analogTypeId: item.analogTypeId,
      channel: item.channel,
      enabled: item.enabled,
      id: item.id,
      lane: item.lane,
      scaleFactor: item.scaleFactor,
      type: item.type,
    })),
    analogTypes: db.analogTypes,
    lanes: db.lanes.map((item) => ({
      baud: item.baud,
      bytesize: item.bytesize,
      device: item.device,
      id: item.id,
      parity: item.parity,
      stopbits: item.stopbits,
    })),
    relays: db.relays.map((item) => {
      const relay = {
        channel: item.channel,
        enabled: item.enabled,
        id: item.id,
        latching: item.latching,
        minimumRunTime: item.minimumRunTime,
        normalState: item.normalState,
        postRunTime: item.postRunTime,
        type: item.type,
      };
      return item.type !== 'Local' ? { ...relay, address: item.address, lane: item.lane } : relay;
    }),
    roles: db.roles,
    sensorTypes: db.sensorTypes.map((item) => {
      const sensorType = {
        alarmHysteresis: item.alarmHysteresis,
        alarmType: item.alarmType,
        alarms: item.alarms,
        description: item.description,
        faultLimit: item.faultLimit,
        fullScale: item.fullScale,
        id: item.id,
        name: item.name,
        units: item.units,
        zeroBuffer: item.zeroBuffer,
        zeroScale: item.zeroScale,
      };
      if (has.call(item, 'amc400Id') && item.amc400Id !== null) sensorType.amc400Id = item.amc400Id;
      if (has.call(item, 'custom')) sensorType.custom = item.custom;
      return sensorType;
    }),
    sensors: db.sensors.map((item) => {
      const sensor = {
        address: item.address,
        alarmPoints: item.alarmPoints,
        alarms: item.alarms,
        enabled: item.enabled,
        id: item.id,
        lane: item.lane,
        sensorTypeId: item.sensorTypeId,
        type: item.type,
      };
      if (has.call(item, 'channel')) sensor.channel = item.channel;
      return sensor;
    }),
    settings: {
      bacnet: db.settings.bacnet,
      displayCalibration: db.settings.displayCalibration,
      fault: db.settings.fault,
      network: db.settings.network,
      defaultTimeZone: db.settings.defaultTimeZone,
      powerUpAlarmDelay: db.settings.powerUpAlarmDelay,
      siteName: db.settings.siteName,
    },
    zones: db.zones.map(item => ({
      aggregationType: item.aggregationType,
      alarms: item.alarms,
      analogOuts: item.analogOuts,
      faultRelay: item.faultRelay,
      id: item.id,
      name: item.name,
      samplePeriod: item.samplePeriod,
      sensorIds: item.sensorIds,
    })),
  };
}

function storeConfig() {
  sessionStorage.setItem('config', JSON.stringify(dumpConfig()));
}

function buildAlarmsForZone(zone) {
  if (zone.sensorIds.length === 0) {
    return [];
  }
  const { alarms } = zone;
  const sensorTypes = [];
  zone.sensorIds.forEach((sensorId) => {
    const sensor = db.sensors.find(item => item.id === sensorId);
    sensorTypes.push(sensor.sensorType);
  });
  const alarmIds = new Set();
  sensorTypes.forEach((sensorType) => {
    sensorType.alarms.forEach((item) => {
      alarmIds.add(item.id);
    });
  });
  zone.sensorIds.forEach((sensorId) => {
    const sensor = db.sensors.find(item => item.id === sensorId);
    if (sensor.alarmPoints > sensor.sensorType.alarms.length) { 
      sensor.alarms.forEach((item) => {
        alarmIds.add(item.id);
      });
    };
  });
  alarmIds.forEach((item) => {
    if (!alarms.some(alarm => alarm.id === item)) {
      alarms.push({
        id: item,
        relays: [],
      });
    }
  });
  const zoneAlarms = [];
  alarms.forEach((item) => {
    if (alarmIds.has(item.id)) {
      zoneAlarms.push(item);
    }
  });
  sortIds(zoneAlarms);
  return zoneAlarms;
}

function buildZoneRelationships() {
  db.sensors = db.sensors.map(item => ({ ...item, zone: null }));
  db.analogOuts = db.analogOuts.map(item => ({ ...item, zone: null }));
  const relays = [];
  db.zones = db.zones.map((zone) => {
    const sensors = [];
    zone.sensorIds.forEach((sensorId) => {
      const sensor = db.sensors.find(item => item.id === sensorId);
      sensor.zone = { id: zone.id, name: zone.name };
      sensors.push({ id: sensorId, sensorTypeName: sensor.sensorType.name });
    });
    zone.alarms.forEach((alarm) => {
      alarm.relays.forEach((relayId) => {
        const relay = relays.find(item => item.id === relayId);
        if (relay === undefined) {
          relays.push({ id: relayId, zones: [{ id: zone.id, name: zone.name }] });
        } else if (!relay.zones.some(item => item.id === zone.id)) {
          relay.zones.push({ id: zone.id, name: zone.name });
        }
      });
    });
    if (zone.faultRelay) {
      const relay = relays.find(item => item.id === zone.faultRelay);
      if (relay === undefined) {
        relays.push({ id: zone.faultRelay, zones: [{ id: zone.id, name: zone.name }] });
      } else if (!relay.zones.some(item => item.id === zone.id)) {
        relay.zones.push({ id: zone.id, name: zone.name });
      }
    }
    db.relays = db.relays.map((item) => {
      const relay = relays.find(item2 => item2.id === item.id);
      if (relay) {
        return { ...item, zones: relay.zones };
      }
      return { ...item, zones: [] };
    });
    const alarms = buildAlarmsForZone(zone);
    zone.analogOuts.forEach((analogOutId) => {
      const analogOut = db.analogOuts.find(item => item.id === analogOutId);
      if (analogOut) {
        analogOut.zone = { id: zone.id, name: zone.name };
      }
    });
    return { ...zone, sensors, alarms };
  });
}

function countSensorTypes() {
  // add sensor count to sensorType
  const counts = db.sensorTypes.map(item => ({ id: item.id, count: 0 }));
  db.sensors.forEach((item) => {
    const count = counts.find(value => value.id === item.sensorTypeId);
    if (count) {
      count.count += 1;
    }
  });
  // Directly updating sensor type to preserve settings associated ref
  for (let i = 0; i < db.sensorTypes.length; i += 1) {
    let count = 0;
    for (let n = 0; n < counts.length; n += 1) {
      if (counts[n].id === db.sensorTypes[i].id) {
        // eslint-disable-next-line prefer-destructuring
        count = counts[n].count;
        break;
      }
    }
    db.sensorTypes[i].sensorCount = count;
  }
}

function findDuplicateComms() {
  const addresses = {};
  db.analogOuts.forEach((item) => {
    const address = has.call(item, 'lane') ? `${item.lane}.${item.address}` : 'Local';
    if (has.call(addresses, address)) {
      if (addresses[address].channels.includes(item.channel)) {
        addresses[address].duplicateChannels.push(item.channel);
      } else {
        addresses[address].channels.push(item.channel);
      }
      addresses[address].analogOuts.push(item.id);
    } else {
      addresses[address] = {
        analogOuts: [item.id],
        channels: [item.channel],
        duplicateChannels: [],
        duplicate: false,
      };
    }
  });
  db.relays.forEach((item) => {
    const address = `${item.lane}.${item.address}`;
    if (has.call(addresses, address)) {
      if (has.call(addresses[address], 'analogOuts')) {
        addresses[address].duplicate = true;
      }
      if (has.call(addresses[address], 'relays')) {
        if (addresses[address].channels.includes(item.channel)) {
          addresses[address].duplicateChannels.push(item.channel);
        } else {
          addresses[address].channels.push(item.channel);
        }
        addresses[address].relays.push(item.id);
      } else {
        addresses[address].relays = [item.id];
      }
    } else {
      addresses[address] = {
        relays: [item.id],
        channels: [item.channel],
        duplicateChannels: [],
        duplicate: false,
      };
    }
  });
  db.sensors.forEach((item) => {
    const address = `${item.lane}.${item.address}`;
    if (has.call(addresses, address)) {
      if (has.call(addresses[address], 'analogOuts') || has.call(addresses[address], 'relays')) {
        addresses[address].duplicate = true;
      }
      if (has.call(addresses[address], 'sensors')) {
        if (has.call(item, 'channel')) {
          if (addresses[address].channels.includes(item.channel)) {
            addresses[address].duplicateChannels.push(item.channel);
          } else if (addresses[address].channels.includes(0)) {
            addresses[address].duplicate = true;
          } else {
            addresses[address].channels.push(item.channel);
          }
        } else if (addresses[address].sensors.length !== 0) {
          addresses[address].duplicate = true;
        } else {
          addresses[address].channels.push(0);
        }
        addresses[address].sensors.push(item.id);
      } else {
        addresses[address].sensors = [item.id];
      }
    } else {
      addresses[address] = {
        sensors: [item.id],
        channels: [],
        duplicateChannels: [],
        duplicate: false,
      };
      if (has.call(item, 'channel')) {
        addresses[address].channels.push(item.channel);
      } else {
        addresses[address].channels.push(0);
      }
    }
  });

  for (let i = 0; i < db.analogOuts.length; i += 1) {
    db.analogOuts[i].duplicate = false;
  }
  for (let i = 0; i < db.relays.length; i += 1) {
    db.relays[i].duplicate = false;
  }
  for (let i = 0; i < db.sensors.length; i += 1) {
    db.sensors[i].duplicate = false;
  }

  Object.values(addresses).forEach((item) => {
    if (item.duplicate === true) {
      if (has.call(item, 'analogOuts')) {
        item.analogOuts.forEach((id) => {
          db.analogOuts.find(i => i.id === id).duplicate = true;
        });
      }
      if (has.call(item, 'relays')) {
        item.relays.forEach((id) => {
          db.relays.find(i => i.id === id).duplicate = true;
        });
      }
      if (has.call(item, 'sensors')) {
        item.sensors.forEach((id) => {
          db.sensors.find(i => i.id === id).duplicate = true;
        });
      }
    } else if (item.duplicateChannels.length !== 0) {
      if (has.call(item, 'analogOuts')) {
        item.analogOuts.forEach((id) => {
          const analogOut = db.analogOuts.find(i => i.id === id && item.duplicateChannels.includes(i.channel));
          if (analogOut !== undefined) analogOut.duplicate = true;
        });
      }
      if (has.call(item, 'relays')) {
        item.relays.forEach((id) => {
          const relay = db.relays.find(i => i.id === id && item.duplicateChannels.includes(i.channel));
          if (relay !== undefined) relay.duplicate = true;
        });
      }
      if (has.call(item, 'sensors')) {
        item.sensors.forEach((id) => {
          const sensor = db.sensors.find(
            i => i.id === id && has.call(i, 'channel') && item.duplicateChannels.includes(i.channel),
          );
          if (sensor !== undefined) sensor.duplicate = true;
        });
      }
    }
  });
}

function updateAnalogOut(id, update) {
  const analogOut = db.analogOuts.find(item => item.id === id);
  Object.entries(update).forEach(([key, value]) => {
    if (['type', 'enabled', 'lane', 'address', 'channel', 'scaleFactor'].includes(key)) {
      analogOut[key] = value;
    } else if (key === 'analogTypeId') {
      analogOut[key] = value;
      analogOut.analogType = db.analogTypes.find(item => item.id === analogOut.analogTypeId);
    } else if (key === 'zoneId') {
      db.zones = db.zones.map(zone => ({ ...zone, analogOuts: zone.analogOuts.filter(item => item !== id) }));
      const zone = db.zones.find(item => item.id === update[key]);
      if (zone) {
        zone.analogOuts.push(id);
        zone.analogOuts.sort();
      }
    }
  });
  if (has.call(update, 'zoneId')) {
    buildZoneRelationships();
  }
  if (has.call(update, 'address') || has.call(update, 'channel') || has.call(update, 'lane')) {
    findDuplicateComms();
  }
}

function createAnalogOut(update) {
  const newAnalogOut = deepCopy(defaultAnalogOut);
  newAnalogOut.id = newId(db.analogOuts);
  db.analogOuts.push(newAnalogOut);
  sortIds(db.analogOuts);
  updateAnalogOut(newAnalogOut.id, update);
  storeConfig();
  return { config: newAnalogOut };
}

function createAnalogOuts(update) {
  const { addCount, address: addressProp, channel: channelProp } = update;
  let address = addressProp;
  let channel = channelProp;
  for (let i = 0; i < addCount; i += 1) {
    createAnalogOut({ ...update, address, channel });
    if (channel === 8) {
      if (address < maxAddress) {
        address += 1;
        channel = 1;
      }
    } else {
      channel += 1;
    }
  }
  return null;
}

function updateRelay(id, update) {
  const relay = db.relays.find(item => item.id === id);
  Object.entries(update).forEach(([key, value]) => {
    if (['enabled', 'channel', 'latching', 'minimumRunTime', 'normalState', 'postRunTime'].includes(key)) {
      relay[key] = value;
    } else if (key === 'type') {
      relay[key] = value;
      if (update[key] === 'Local') {
        if (has.call(relay, 'address')) {
          delete relay.address;
        }
        if (has.call(relay, 'lane')) {
          delete relay.lane;
        }
      }
    } else if (['address', 'lane'].includes(key)) {
      if (has.call(update, 'type')) {
        if (update.type !== 'Local') {
          relay[key] = value;
        }
      } else if (relay.type !== 'Local') {
        relay[key] = value;
      }
    } else if (key === 'zones') {
      // remove old fault and alarm relay/zone assignments
      db.zones = db.zones.map((zone) => {
        const faultRelay = zone.faultRelay === id ? null : zone.faultRelay;
        const alarms = zone.alarms.map(alarm => ({ ...alarm, relays: alarm.relays.filter(relayId => relayId !== id) }));
        return { ...zone, faultRelay, alarms };
      });
      db.zones = db.zones.map((zone) => {
        // if update applies to this zone, assign the relay
        let faultRelay = null;
        const alarms = { zone };
        const zoneUpdate = update[key].find(item => item.id === zone.id);
        if (zoneUpdate) {
          if (has.call(zoneUpdate, 'faultRelay') && zoneUpdate.faultRelay) {
            faultRelay = id;
          }
          zoneUpdate.alarms.forEach((alarmId) => {
            const alarm = zone.alarms.find(item => item.id === alarmId);
            if (alarm === undefined) {
              alarms.push({ id: alarmId, relays: [id] });
              sortIds(zone.alarms);
            } else {
              alarm.relays.push(id);
              alarm.relays.sort();
            }
          });
        }
        return { ...zone, faultRelay: faultRelay !== null ? faultRelay : zone.faultRelay };
      });
    }
  });
  if (has.call(update, 'zones')) {
    buildZoneRelationships();
  }
  if (has.call(update, 'address') || has.call(update, 'channel') || has.call(update, 'lane')) {
    findDuplicateComms();
  }
}

function createRelay(update) {
  const newRelay = deepCopy(defaultRelay);
  newRelay.id = newId(db.relays);
  db.relays.push(newRelay);
  sortIds(db.relays);
  updateRelay(newRelay.id, update);
  storeConfig();
  return { config: newRelay };
}

function createRelays(update) {
  const {
    addCount, address: addressProp, channel: channelProp, type,
  } = update;
  let address = addressProp;
  let channel = channelProp;
  for (let i = 0; i < addCount; i += 1) {
    createRelay({ ...update, address, channel });
    if (type === 'Local') {
      if (channel !== 16) {
        channel += 1;
      }
    } else if (channel === 8) {
      if (address < maxAddress) {
        address += 1;
        channel = 1;
      }
    } else {
      channel += 1;
    }
  }
  return null;
}

function updateSensor(id, update) {
  const sensor = db.sensors.find(item => item.id === id);
  Object.entries(update).forEach(([key, value]) => {
    if (['enabled', 'lane', 'address'].includes(key)) {
      sensor[key] = value;
    } else if (key === 'sensorTypeId') {
      sensor[key] = value;
      sensor.sensorType = db.sensorTypes.find(item => item.id === update[key]);
    } else if (key === 'type') {
      sensor[key] = value;
      if (update[key] === 'AMC-400') {
        if (has.call(sensor, 'channel')) {
          delete sensor.channel;
        }
      }
    } else if (key === 'channel') {
      if (has.call(update, 'type')) {
        if (update.type !== 'AMC-400') {
          sensor[key] = value;
        }
      } else if (sensor.type !== 'AMC-400') {
        sensor[key] = value;
      }
    } else if (key === 'alarmPoints') {
      sensor[key] = value;
    } else if (key === 'alarms') {
      sensor[key] = [];
      value.forEach((item) => {
        sensor[key].push({
          delay: item.delay,
          id: item.id,
          setPoint: item.setPoint,
        });
      });
      sortIds(sensor.alarms);
    } else if (key === 'zoneId') {
      db.zones = db.zones.map(zone => ({ ...zone, sensorIds: zone.sensorIds.filter(item => item !== id) }));
      const zone = db.zones.find(item => item.id === update[key]);
      if (zone) {
        zone.sensorIds.push(id);
        zone.sensorIds.sort();
      }
    }
  });
  if (has.call(update, 'zoneId') || has.call(update, 'sensorTypeId')) {
    buildZoneRelationships();
  }
  if (has.call(update, 'sensorTypeId')) {
    countSensorTypes();
  }
  if (
    has.call(update, 'address')
    || has.call(update, 'channel')
    || has.call(update, 'lane')
    || has.call(update, 'type')
  ) {
    findDuplicateComms();
  }
}

function createSensor(update) {
  const newSensor = deepCopy(defaultSensor);
  newSensor.id = newId(db.sensors);
  db.sensors.push(newSensor);
  sortIds(db.sensors);
  updateSensor(newSensor.id, update);
  storeConfig();
  return { config: newSensor };
}

function createSensors(update) {
  const { addCount, address: addressProp, type } = update;
  let address = addressProp;
  let channel = ((type === 'BC8AII') || (type === 'DTR')) ? update.channel : undefined;
  for (let i = 0; i < addCount; i += 1) {
    createSensor(channel !== undefined ? { ...update, address, channel } : { ...update, address });
    if (channel !== undefined) {
      if (((channel === 8) && (type === 'BC8AII')) || ((channel === 2) && (type === 'DTR'))) {
        if (address < maxAddress) {
          address += 1;
          channel = 1;
        }
      } else {
        channel += 1;
      }
    } else if (address < maxAddress) {
      address += 1;
    }
  }
  return null;
}

function updateSensorType(id, update) {
  const sensorType = db.sensorTypes.find(item => item.id === id);
  Object.entries(update).forEach(([key, value]) => {
    if (['description', 'fullScale', 'name', 'units', 'zeroScale'].includes(key)) {
      sensorType[key] = value;
    }
  });
  countSensorTypes();
}

function updateLane(lane, update) {
  Object.entries(update).forEach(([key, value2]) => {
    if (['baud', 'parity', 'bytesize', 'stopbits'].includes(key)) {
     db.lanes[lane][key] = value2;
    }
  });
}
  
function updateSettings(update) {
  const { settings } = db;
  Object.entries(update).forEach(([key, value]) => {
    if (['displayCalibration', 'powerUpAlarmDelay', 'siteName', 'defaultTimeZone'].includes(key)) {
      settings[key] = value;
    } else if (key === 'bacnet') {
      Object.entries(update[key]).forEach(([key2, value2]) => {
        if (['deviceId', 'deviceName', 'port'].includes(key2)) {
          settings[key][key2] = value2;
        }
      });
    } else if (key === 'fault') {
      Object.entries(update[key]).forEach(([key2, value2]) => {
        if (['delay', 'relay'].includes(key2)) {
          settings[key][key2] = value2;
        }
      });
    } else if (key === 'network') {
      Object.entries(update[key]).forEach(([key2, value2]) => {
        if (['address', 'gateway', 'method'].includes(key2)) {
          settings[key][key2] = value2;
        }
      });
    } else if (key === 'sensorType') {
      const sensorType = db.sensorTypes.find(item => item.id === update[key].id);
      if (sensorType) {
        Object.entries(update[key]).forEach(([key2, value2]) => {
          if (
            [
              'alarmHysteresis',
              'alarmType',
              'amc400Id',
              'description',
              'faultLimit',
              'fullScale',
              'name',
              'units',
              'zeroScale',
              'zeroBuffer',
            ].includes(key2)
          ) {
            sensorType[key2] = value2;
          } else if (key2 === 'alarms') {
            sensorType[key2] = [];
            update[key][key2].forEach((item) => {
              sensorType[key2].push({
                id: item.id,
                setPoint: item.setPoint,
                delay: item.delay,
              });
            });
            sortIds(sensorType[key2]);
            db.sensors = db.sensors.map((item) => {
              if (item.sensorTypeId === sensorType.id) {
                return {
                  ...item,
                  [key2]: item[key2].filter(alarm => sensorType[key2].some(alarm2 => alarm2.id === alarm.id)),
                };
              }
              return item;
            });
          }
        });
      }
      db.zones = db.zones.map(item => ({ ...item, alarms: buildAlarmsForZone(item) }));
    }
  });
}

function updateZone(id, update) {
  const zone = db.zones.find(item => item.id === id);
  Object.entries(update).forEach(([key, value]) => {
    if (['aggregationType', 'alarms', 'faultRelay', 'name', 'samplePeriod', 'sensorIds'].includes(key)) {
      zone[key] = value;
    } else if (key === 'analogOuts') {
      zone[key] = value;
      db.zones = db.zones.map((item) => {
        if (item.id !== id) {
          return {
            ...item,
            [key]: item[key].filter(analogOutId => !zone[key].includes(analogOutId)),
          };
        }
        return item;
      });
    } else if (key === 'sensorIds') {
      zone[key] = value;
      db.zones = db.zones.map((item) => {
        if (item.id !== id) {
          return {
            ...item,
            [key]: item[key].filter(sensorId => !zone[key].includes(sensorId)),
          };
        }
        return item;
      });
    }
  });
  buildZoneRelationships();
}

function loadConfig(config = null) {
  db = deepCopy(defaultDb);
  if (config !== null) {
    if (has.call(config, 'analogTypes')) db.analogTypes = config.analogTypes;
    if (has.call(config, 'lanes')) db.lanes = config.lanes;
    if (has.call(config, 'roles')) db.roles = config.roles;
    if (has.call(config, 'sensorTypes')) db.sensorTypes = config.sensorTypes;
    if (has.call(config, 'settings')) db.settings = config.settings;
    if (has.call(config, 'analogOuts')) {
      config.analogOuts.forEach((item) => {
        db.analogOuts.push(item);
        sortIds(db.analogOuts);
        updateAnalogOut(item.id, item);
      });
    }
    if (has.call(config, 'relays')) {
      config.relays.forEach((item) => {
        db.relays.push(item);
        sortIds(db.relays);
        updateRelay(item.id, item);
      });
    }
    if (has.call(config, 'sensors')) {
      config.sensors.forEach((item) => {
        db.sensors.push(item);
        sortIds(db.sensors);
        updateSensor(item.id, item);
      });
    }
    if (has.call(config, 'zones')) {
      config.zones.forEach((item) => {
        db.zones.push(item);
        sortIds(db.zones);
        updateZone(item.id, item);
      });
    }
  }
  db.settings.sensorTypes = db.sensorTypes;
}

// eslint-disable-next-line import/prefer-default-export
export function makeServer() {
  const server = new Server({
    urlPrefix: process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://config.armstrongmonitoring.com/',
    namespace: 'api/v1',

    seeds() {
      if (sessionStorage.getItem('config')) {
        loadConfig(JSON.parse(sessionStorage.getItem('config')));
      } else if (process.env.NODE_ENV === 'development') {
        loadConfig(demo);
      } else {
        loadConfig();
      }
    },

    routes() {
      // lanes

      this.get('/lanes', () => db.lanes);

      this.put('/lanes/:id', (_, request) => {
        const lane = Number(request.params.id);
        const update = JSON.parse(request.requestBody);
        updateLane(lane, update);
        storeConfig();
        return db.lanes;
      });

      // sensors

      this.get('/sensors', () => ({
        sensors: db.sensors.map(item => ({
          address: item.address,
          alarms: item.alarms,
          channel: item.channel,
          duplicate: item.duplicate,
          id: item.id,
          lane: item.lane,
          sensorType: db.sensorTypes.find(value => value.id === item.sensorTypeId),
          type: item.type,
          zone: item.zone,
        })),
      }));

      this.get('/sensors/:id', (_, request) => {
        const id = Number(request.params.id);
        const sensor = db.sensors.find(item => item.id === id);
        return sensor ? { config: sensor } : new Response(404);
      });

      this.post('/sensors', (_, request) => {
        const update = JSON.parse(request.requestBody);
        if (has.call(update, 'addCount') && update.addCount !== 1) {
          return createSensors(update);
        }
        return createSensor(update);
      });

      this.put('/sensors/:id', (_, request) => {
        const id = Number(request.params.id);
        const update = JSON.parse(request.requestBody);
        updateSensor(id, update);
        storeConfig();
        return { config: db.sensors.find(item => item.id === id) };
      });

      this.delete('/sensors/:id', (_, request) => {
        const id = Number(request.params.id);
        db.sensors = db.sensors.filter(item => item.id !== id);
        db.zones = db.zones.map(item => ({ ...item, sensorIds: item.sensorIds.filter(value => value !== id) }));
        buildZoneRelationships();
        findDuplicateComms();
        countSensorTypes();
        storeConfig();
      });

      // relays

      this.get('/relays', () => ({
        relays: db.relays.map((item) => {
          const relay = {
            channel: item.channel,
            duplicate: item.duplicate,
            id: item.id,
            latching: item.latching,
            normalState: item.normalState,
            type: item.type,
            zones: item.zones,
            settings: { fault: { relay: db.settings.fault.relay } },
          };
          return item.type !== 'Local' ? { ...relay, address: item.address, lane: item.lane } : relay;
        }),
      }));

      this.get('/relays/:id', (_, request) => {
        const id = Number(request.params.id);
        const relay = db.relays.find(item => item.id === id);
        return relay ? { config: relay, settings: { fault: { relay: db.settings.fault.relay } } } : new Response(404);
      });

      this.post('/relays', (_, request) => {
        const update = JSON.parse(request.requestBody);
        if (has.call(update, 'addCount') && update.addCount !== 1) {
          return createRelays(update);
        }
        return createRelay(update);
      });

      this.put('/relays/:id', (_, request) => {
        const id = Number(request.params.id);
        const update = JSON.parse(request.requestBody);
        updateRelay(id, update);
        storeConfig();
        return {
          config: db.relays.find(item => item.id === id),
          settings: { fault: { relay: db.settings.fault.relay } },
        };
      });

      this.delete('/relays/:id', (_, request) => {
        const id = Number(request.params.id);
        db.relays = db.relays.filter(item => item.id !== id);
        // remove any zone associations
        db.zones = db.zones.map((zone) => {
          const faultRelay = zone.faultRelay === id ? null : zone.faultRelay;
          const alarms = zone.alarms.map(alarm => ({
            ...alarm,
            relays: alarm.relays.filter(relayId => relayId !== id),
          }));
          return { ...zone, faultRelay, alarms };
        });
        buildZoneRelationships();
        findDuplicateComms();
        storeConfig();
      });

      // analog outs

      this.get('/analogouts', () => ({
        analogOuts: db.analogOuts.map(item => ({
          address: item.address,
          analogType: item.analogType,
          channel: item.channel,
          duplicate: item.duplicate,
          id: item.id,
          lane: item.lane,
          scaleFactor: item.scaleFactor,
          type: item.type,
          zone: item.zone,
        })),
      }));

      this.get('/analogouts/:id', (_, request) => {
        const id = Number(request.params.id);
        const analogOut = db.analogOuts.find(item => item.id === id);
        return analogOut ? { config: analogOut } : new Response(404);
      });

      this.post('/analogouts', (_, request) => {
        const update = JSON.parse(request.requestBody);
        if (has.call(update, 'addCount') && update.addCount !== 1) {
          return createAnalogOuts(update);
        }
        return createAnalogOut(update);
      });

      this.put('/analogouts/:id', (_, request) => {
        const id = Number(request.params.id);
        const update = JSON.parse(request.requestBody);
        updateAnalogOut(id, update);
        storeConfig();
        return { config: db.analogOuts.find(item => item.id === id) };
      });

      this.delete('/analogouts/:id', (_, request) => {
        const id = Number(request.params.id);
        db.analogOuts = db.analogOuts.filter(item => item.id !== id);
        db.zones = db.zones.map(item => ({ ...item, analogOuts: item.analogOuts.filter(value => value !== id) }));
        buildZoneRelationships();
        findDuplicateComms();
        storeConfig();
      });

      // zones

      this.get('/zones', () => ({
        zones: db.zones.map(item => ({
          alarms: item.alarms,
          analogOuts: item.analogOuts,
          faultRelay: item.faultRelay,
          id: item.id,
          name: item.name,
          sensorIds: item.sensorIds,
        })),
      }));

      this.get('/zones/:id', (_, request) => {
        const id = Number(request.params.id);
        const zone = db.zones.find(item => item.id === id);
        return zone || new Response(404);
      });

      this.post('/zones', (_, request) => {
        const update = JSON.parse(request.requestBody);
        const newZone = deepCopy(defaultZone);
        newZone.id = newId(db.zones);
        db.zones.push(newZone);
        sortIds(db.zones);
        updateZone(newZone.id, update);
        storeConfig();
        return newZone;
      });

      this.put('/zones/:id', (_, request) => {
        const id = Number(request.params.id);
        const update = JSON.parse(request.requestBody);
        updateZone(id, update);
        storeConfig();
        return db.zones.find(item => item.id === id);
      });

      this.delete('/zones/:id', (_, request) => {
        const id = Number(request.params.id);
        db.zones = db.zones.filter(item => item.id !== id);
        storeConfig();
      });

      // settings

      this.get('/settings', () => db.settings);

      this.put('/settings', (_, request) => {
        const update = JSON.parse(request.requestBody);
        updateSettings(update);
        storeConfig();
        return db.settings;
      });

      // sensor types

      this.post('/sensortypes', (_, request) => {
        const update = JSON.parse(request.requestBody);
        const newSensorType = deepCopy(defaultSensorType);
        newSensorType.id = newId(db.sensorTypes);
        db.sensorTypes.push(newSensorType);
        sortIds(db.sensorTypes);
        updateSensorType(newSensorType.id, update);
        storeConfig();
        return newSensorType;
      });

      this.delete('/sensortypes/:id', (_, request) => {
        const id = Number(request.params.id);
        db.sensorTypes = db.sensorTypes.filter(item => item.id !== id);
        const { settings } = db;
        settings.sensorTypes = db.sensorTypes;
        storeConfig();
      });

      // download config

      this.get('/downloadconfig', () => dumpConfig());

      // upload config

      this.post('/uploadconfig', (_, request) => {
        const config = JSON.parse(request.requestBody);
        loadConfig(config);
        storeConfig();
      });

      // filename

      this.get('/filename', () => ({ fileName: sessionStorage.getItem('fileName') }));

      this.post('/filename', (_, request) => {
        const { fileName } = JSON.parse(request.requestBody);
        sessionStorage.setItem('fileName', fileName);
      });
    },
  });

  server.timing = 0;
  // TODO server.logging = false;

  server.passthrough('https://apis.google.com/**');
  server.passthrough('https://securetoken.googleapis.com/**');
  server.passthrough('https://www.googleapis.com/**');
  server.passthrough('https://www.google.com/recaptcha/**');

  return server;
}

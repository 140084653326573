import has from './has';

export function aggregationTypeText(value) {
  switch (value) {
    case 'average':
      return 'Average';
    case 'peak':
      return 'Peak';
    default:
      return 'Unknown';
  }
}

export function alarmTypeText(value) {
  switch (value) {
    case 'increasing':
      return 'Increasing';
    case 'decreasing':
      return 'Decreasing';
    case 'window':
      return 'Window';
    default:
      return 'Unknown';
  }
}

export function conditionText(condition) {
  switch (condition.general) {
    case 'ready':
      return 'Normal';
    case 'sensor_fault':
      return 'Sensor fault';
    case 'missing':
      return 'Missing';
    case 'not_ready':
      return 'Not ready';
    case 'status_error':
      return 'Bad config';
    case 'disabled':
      return 'Disabled';
    default:
      return 'Unknown';
  }
}

export function timeText(value) {
  return value < 61 ? `${value} sec` : `${value / 60} min`;
}

export function delayText(value) {
  if (value === 0) {
    return 'No delay';
  }
  return timeText(value);
}

export function displayCalibrationValue(text) {
  return text === 'Everyone';
}

export function displayCalibrationText(value) {
  if (value !== undefined) {
    return value ? 'Everyone' : 'Staff only';
  }
  return '';
}

export function methodText(value) {
  switch (value) {
    case 'auto':
      return 'Automatic (DHCP)';
    case 'manual':
      return 'Manual (static)';
    default:
      return 'Unknown';
  }
}

export function minimumTimeText(value) {
  if (value === 0) {
    return 'No minimum';
  }
  return timeText(value);
}

export function rangeText(valueType) {
  return `${valueType.zeroScale}-${valueType.fullScale} ${valueType.units}`;
}

export function relayLatchingText(value) {
  if (value !== undefined) {
    return value ? 'Latching' : 'Non-latching';
  }
  return '';
}

export function relayLatchingValue(text) {
  return text === 'Latching';
}

export function relayStateText(value) {
  if (value !== undefined) {
    return value ? 'Energized' : 'De-energized';
  }
  return '';
}

export function relayStateValue(text) {
  return text === 'Energized';
}

export function sensorAlarms(sensor) {
  const alarms = [];
  if (sensor) {
    const sensorTypeAlarms = has.call(sensor, 'config') ? sensor.config.sensorType.alarms : sensor.sensorType.alarms;
    const overrideAlarms = has.call(sensor, 'config') ? sensor.config.alarms : sensor.alarms;
    sensorTypeAlarms.forEach((item) => {
      const alarm = {
        delay: item.delay,
        id: item.id,
        setPoint: item.setPoint,
        override: false,
      };
      const override = overrideAlarms.find(value => value.id === item.id);
      if (override) {
        alarm.delay = override.delay;
        alarm.setPoint = override.setPoint;
        alarm.override = true;
      }
      alarms.push(alarm);
    });

    overrideAlarms.forEach((item) => {
      const alarm = {
        delay: item.delay,
        id: item.id,
        setPoint: item.setPoint,
        override: false,
      };
      const alarmPresent = sensorTypeAlarms.find(value => value.id === item.id);
      if ( !alarmPresent ) {
        alarms.push(alarm);
      }
    });

  }
  return alarms;
}

export function sensorManufactureDate(days) {
  const date = new Date('1900-01-01');
  date.setDate(date.getDate() + days);
  return date.toLocaleDateString();
}

function valueText0(value, precision = 0) {
  if (value === undefined || value === null) {
    return value;
  }

  if (Number.isNaN(Number(value))) {
    return '';
  }

  const text = `${value.toFixed(precision)}`;
  return text === '-0' ? '0' : text;
}

export function valueText(value, precision = 0, unit = null, prefix = '') {
  const text = valueText0(value, precision);
  if (!text) return text;
  return unit ? `${prefix}${text} ${unit}` : `${prefix}${text}`;
}

export function zoneAlarmLevel(alarms) {
  return alarms.reduce(
    (accumulator, item) => (item.sensors.length !== 0 && item.id > accumulator ? item.id : accumulator),
    0,
  );
}

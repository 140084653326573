import PropTypes from 'prop-types';
import React from 'react';

function SensorTypeName(props) {
  const { name } = props;

  const characters = Array.from(name);
  const format = [];
  let i = 0;

  while (i < characters.length) {
    if (characters[i] === '_') {
      i += 1;
      if (i < characters.length) {
        format.push({ char: characters[i], subscript: true });
      }
    } else {
      format.push({ char: characters[i], subscript: false });
    }
    i += 1;
  }

  return (
    <span>
      {format.map((element, index) => (element.subscript ? (
      // eslint-disable-next-line react/no-array-index-key
        <sub key={index}>{element.char}</sub>
      ) : (
        element.char
      )))}
    </span>
  );
}

SensorTypeName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SensorTypeName;

import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { iconSize } from '../utils/styles';
import { themeDark } from '../utils/theme';

const styles = theme => ({
  button: {
    minWidth: 88,
    minHeight: 32,
    padding: '7px 8px',
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&$disabled': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonLeft: {
    marginRight: theme.spacing(1),
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttonClose: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  },
  disabled: {},
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconSize,
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
});

function AppBar2(props) {
  const {
    buttonText,
    classes,
    disabledBack,
    disabledNext,
    disabledSave,
    handleBackClick,
    handleCloseClick,
    handleNextClick,
    handleSaveClick,
    title,
  } = props;

  return (
    <MuiAppBar elevation={0} position="relative">
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <IconButton className={classes.buttonClose} color="inherit" onClick={handleCloseClick}>
            <CloseIcon className={classes.iconSize} />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
        </div>
        <div className={classes.iconContainer}>
          <ThemeProvider theme={themeDark}>
            {(handleBackClick || disabledBack) && (
              <Button
                classes={{ disabled: classes.disabled }}
                className={clsx(classes.button, classes.buttonLeft)}
                color="secondary"
                disabled={disabledBack}
                onClick={handleBackClick}
                variant="outlined"
              >
                Back
              </Button>
            )}
            {(handleNextClick || disabledNext) && (
              <Button
                className={clsx(classes.button, classes.buttonRight)}
                color="secondary"
                disabled={disabledNext}
                onClick={handleNextClick}
                variant="contained"
              >
                Next
              </Button>
            )}
            {(handleSaveClick || disabledSave) && (
              <Button
                className={clsx(classes.button, classes.buttonRight)}
                color="secondary"
                disabled={disabledSave}
                onClick={handleSaveClick}
                variant="contained"
              >
                {buttonText}
              </Button>
            )}
          </ThemeProvider>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar2.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  disabledSave: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleCloseClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  title: PropTypes.string,
};

AppBar2.defaultProps = {
  buttonText: 'Save',
  disabledBack: false,
  disabledNext: false,
  disabledSave: false,
  handleBackClick: undefined,
  handleNextClick: undefined,
  handleSaveClick: undefined,
  title: undefined,
};

export default withStyles(styles)(AppBar2);

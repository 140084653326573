import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const FileDownloadOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M13,5V11H14.17L12,13.17L9.83,11H11V5H13M15,3H9V9H5L12,16L19,9H15V3M19,18H5V20H19V18Z" />
  </SvgIcon>
);

export default FileDownloadOutlined;

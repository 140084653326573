import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import React from 'react';

function ElevationScroll(props) {
  const { children, target } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  target: PropTypes.any,
};

ElevationScroll.defaultProps = {
  target: undefined,
};

export default ElevationScroll;

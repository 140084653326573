export const optionsAmc400Id = [
  {
    label: 'CO',
    value: 0,
  },
  {
    label: 'NO_2',
    value: 1,
  },
  {
    label: 'CH_4',
    value: 2,
  },
  {
    label: 'C_2H_5',
    value: 3,
  },
  {
    label: 'H_2S',
    value: 4,
  },
  {
    label: 'NH_3',
    value: 5,
  },
  {
    label: 'Cl_2',
    value: 6,
  },
  {
    label: 'O_2',
    value: 7,
  },
  {
    label: 'GASOL',
    value: 8,
  },
  {
    label: 'H_2',
    value: 9,
  },
  {
    label: 'COMBU',
    value: 11,
  },
  {
    label: 'CO_2',
    value: 13,
  },
  {
    label: 'C_2H_2',
    value: 14,
  },
  {
    label: 'SO_2',
    value: 15,
  },
  {
    label: 'HCL',
    value: 17,
  },
  {
    label: 'HCN',
    value: 18,
  },
  {
    label: 'O_3',
    value: 19,
  },
  {
    label: 'NO',
    value: 20,
  },
  {
    label: 'TEMPC',
    value: 21,
  },
];

export function amc400TypeText(id) {
  const value = optionsAmc400Id.find(item => item.value === id);
  return value ? value.label : 'Unknown';
}

import PropTypes from 'prop-types';
import React from 'react';
import EditDialog from './components/EditDialog';
import EditKeyboard from './components/EditKeyboard';
import RelaysDetails from './RelaysDetails';
import RelaysEdit from './RelaysEdit';
import { apiDeleteRelay, apiGetZones, apiModifyRelay } from './utils/api';
import has from './utils/has';
import history from './utils/history';
import {
  optionsChannel,
  optionsChannel16,
  optionsLane,
  optionsLatching,
  optionsMinimumRunTime,
  optionsNormalState,
  optionsPostRunTime,
  optionsRelayType,
} from './utils/options';
import { patternNumber } from './utils/patterns';
import {
  relayLatchingText, relayLatchingValue, relayStateText, relayStateValue,
} from './utils/utils';

class Relays extends React.Component {
  constructor(props) {
    super(props);

    const { relay } = props;

    this.state = {
      edit: undefined,
      metadata: null,
      options: [],
      relay,
    };

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditItemClick = this.handleEditItemClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.value = this.value.bind(this);

    history.defaultState({ metadata: null });
  }

  componentDidMount() {
    this.mounted = true;

    history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    this.mounted = false;

    history.removeListener(this.handleLocationChanged);
  }

  handleDeleteClick() {
    const { handleBackClick, id } = this.props;

    apiDeleteRelay(id).then(() => {
      if (this.mounted) {
        this.setState({ edit: false });
      }
    });

    handleBackClick();
  }

  handleEditItemClick(item) {
    const { index } = item;

    if (index === 'channel') {
      this.setState({ edit: true, metadata: item, options: item.type === 'Local' ? optionsChannel16 : optionsChannel });
    } else if (index === 'lane') {
      this.setState({ edit: true, metadata: item, options: optionsLane });
    } else if (index === 'latching') {
      this.setState({ edit: true, metadata: item, options: optionsLatching });
    } else if (index === 'minimumRunTime') {
      this.setState({ edit: true, metadata: item, options: optionsMinimumRunTime });
    } else if (index === 'normalState') {
      this.setState({ edit: true, metadata: item, options: optionsNormalState });
    } else if (index === 'postRunTime') {
      this.setState({ edit: true, metadata: item, options: optionsPostRunTime });
    } else if (index === 'type') {
      this.setState({ edit: true, metadata: item, options: optionsRelayType });
    } else if (has.call(item, 'reset')) {
      this.setState({ edit: true, metadata: item, options: [] });
    } else {
      history.pushAppend({ metadata: item, options: [] });
    }
  }

  handleLocationChanged(value) {
    const { relay } = this.state;

    const { metadata, options } = value;

    if (metadata) {
      const { index } = metadata;

      if (index === 'zones' && relay) {
        const relayId = relay.config.id;
        apiGetZones().then((zones) => {
          if (this.mounted) {
            this.setState({
              edit: true,
              metadata: {
                ...metadata,
                zones: zones.map(item => ({
                  id: item.id,
                  name: item.name,
                  alarms: item.alarms.map(alarm => ({
                    id: alarm.id,
                    checked: alarm.relays.find(item2 => item2 === relayId) !== undefined,
                  })),
                  faultRelay: item.faultRelay === relayId,
                })),
              },
              options,
            });
          }
        });
      } else {
        this.setState({ edit: true, metadata, options });
      }
    } else {
      this.setState({ edit: false });
    }
  }

  handleChangeItem(value, item) {
    const { id } = this.props;
    const { metadata, options, relay } = this.state;

    const closeDialogOrGoBack = () => {
      if (options.length !== 0) {
        this.setState({ edit: false });
      } else {
        history.goBack();
      }
    };

    if (!item) {
      closeDialogOrGoBack();
      return;
    }

    const { index } = item;
    let value2 = value;

    if (index === 'normalState') {
      const newValue = relayStateValue(value);
      if (newValue === relay.config[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { [index]: newValue };
    } else if (index === 'latching') {
      const newValue = relayLatchingValue(value);
      if (newValue === relay.config[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = {
        [index]: newValue,
        minimumRunTime: 0,
        postRunTime: 0,
      };
    } else if (index === 'zones') {
      value2 = { [index]: value };
    } else {
      const newValue = ['enabled', 'type'].includes(index) ? value : Number(value);
      if (newValue === relay.config[index]) {
        if (!has.call(metadata, 'reset')) {
          closeDialogOrGoBack();
          return;
        }
      }
      value2 = { [index]: newValue };
    }

    if (has.call(item, 'value')) {
      Object.assign(value2, item.value);
    }

    if (index === 'type') {
      if (value2.type === 'Local') {
        this.handleEditItemClick({
          label: 'Local channel',
          index: 'channel',
          reset: true,
          type: value2.type,
          value: {
            ...value2,
          },
        });
      } else {
        this.handleEditItemClick({
          label: 'Modbus lane',
          index: 'lane',
          reset: true,
          type: value2.type,
          value: {
            ...value2,
          },
        });
      }
      return;
    }

    if (index === 'lane') {
      if (has.call(metadata, 'reset')) {
        this.handleEditItemClick({
          label: 'Modbus address',
          index: 'address',
          reset: true,
          value: {
            ...value2,
          },
        });
      }
      return;
    }

    if (index === 'address') {
      if (has.call(metadata, 'reset')) {
        this.handleEditItemClick({
          label: 'Modbus channel',
          index: 'channel',
          reset: true,
          value: {
            ...value2,
          },
        });
      }
      return;
    }

    apiModifyRelay(id, value2).then((data) => {
      if (this.mounted) {
        if (data) this.setState({ relay: data });
        if (index !== 'enabled') closeDialogOrGoBack();
      }
    });
  }

  handleSaveClick(value) {
    const { metadata } = this.state;

    return this.handleChangeItem(value, metadata);
  }

  inputCheck(value) {
    const { metadata } = this.state;

    if (metadata.index === 'address') {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    return null;
  }

  inputPattern() {
    const { metadata } = this.state;

    if (metadata) {
      if (metadata.index === 'address') {
        return patternNumber;
      }
    }

    return undefined;
  }

  value() {
    const { metadata, options, relay } = this.state;

    if (metadata && relay && options.length !== 0) {
      const { index } = metadata;
      if (index === 'normalState') {
        return relayStateText(relay.config[index]);
      }
      if (index === 'latching') {
        return relayLatchingText(relay.config[index]);
      }
      if (index === 'channel') {
        if (has.call(metadata, 'reset')) {
          return '';
        }
      }
      return relay.config[index] === null || relay.config[index] === undefined || relay.config[index].length === 0
        ? ''
        : relay.config[index];
    }

    return '';
  }

  render() {
    const { handleBackClick, id } = this.props;
    const {
      edit, metadata, options, relay,
    } = this.state;

    if (edit === undefined) return null;

    return (
      <>
        {edit
          && options.length === 0
          && (metadata.index === 'zones' ? (
            <RelaysEdit
              handleCloseClick={() => {
                history.goBack();
              }}
              handleSaveClick={this.handleSaveClick}
              title={`Edit Relay ${id}`}
              zones={metadata.zones}
            />
          ) : (
            <EditKeyboard
              buttonText={has.call(metadata, 'reset') ? 'Next' : 'Save'}
              handleCloseClick={() => {
                if (has.call(metadata, 'reset')) {
                  this.setState({ edit: false });
                } else {
                  history.goBack();
                }
              }}
              handleSaveClick={this.handleSaveClick}
              inputCheck={this.inputCheck}
              inputPattern={this.inputPattern()}
              label={metadata.label}
              title={`Edit Relay ${id}`}
              value={relay && relay.config[metadata.index]}
            />
          ))}
        {(!edit || (edit && options.length !== 0)) && (
          <RelaysDetails
            handleBackClick={handleBackClick}
            handleChangeItem={this.handleChangeItem}
            handleDeleteClick={this.handleDeleteClick}
            handleEditItemClick={this.handleEditItemClick}
            id={id}
            relay={relay}
          />
        )}
        <EditDialog
          handleCloseClick={() => {
            this.setState({ edit: false });
          }}
          handleSaveClick={this.handleSaveClick}
          label={metadata && has.call(metadata, 'label') ? metadata.label : ''}
          open={edit && options.length !== 0}
          options={options}
          value={this.value()}
        />
      </>
    );
  }
}

Relays.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  relay: PropTypes.object.isRequired,
};

export default Relays;

import PropTypes from 'prop-types';
import React from 'react';
import AddKeyboard from './components/AddKeyboard';
import { apiAddSensorType } from './utils/api';
import { patternDecimal1 } from './utils/patterns';

class SettingsSensorTypesAdd extends React.Component {
  static minStep() {
    return 1;
  }

  static maxStep() {
    return 5;
  }

  constructor(props) {
    super(props);

    this.state = {
      description: '',
      fullScale: '',
      name: '',
      step: 1,
      units: '',
      zeroScale: '',
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.label = this.label.bind(this);
    this.units = this.units.bind(this);
    this.value = this.value.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleBackClick() {
    const { step } = this.state;

    this.setState({ step: step - 1 });
  }

  handleChange(value) {
    const { step } = this.state;

    if (step === 1) {
      this.setState({ name: value });
    } else if (step === 2) {
      this.setState({ description: value });
    } else if (step === 3) {
      this.setState({ units: value });
    } else if (step === 4) {
      this.setState({ zeroScale: value });
    } else if (step === 5) {
      this.setState({ fullScale: value });
    }
  }

  handleNextClick() {
    const { step } = this.state;

    this.setState({ step: step + 1 });
  }

  handleSaveClick() {
    const { handleCloseClick, handleSaveClick: handleSaveClickProp } = this.props;
    const {
      description, fullScale, name, units, zeroScale,
    } = this.state;

    apiAddSensorType({
      description,
      fullScale: Number(fullScale),
      name,
      units,
      zeroScale: Number(zeroScale),
    }).then((data) => {
      if (this.mounted) {
        if (data) {
          handleSaveClickProp(data.id);
        } else {
          handleCloseClick();
        }
      }
    });
  }

  inputCheck(value) {
    const { sensorTypes } = this.props;
    const { step } = this.state;

    if (step === 1) {
      if (value.replace(/_/g, '').length > 12) {
        return 'Maximum characters exceeded. Name must be less than 13 chars.';
      }
      if (sensorTypes.some(item => item.name === value)) {
        return 'Duplicate name. Sensor type name must be unique.';
      }
    }

    return null;
  }

  inputPattern() {
    const { step } = this.state;

    if ([4, 5].includes(step)) {
      return patternDecimal1;
    }

    return undefined;
  }

  label() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return 'Name';
      case 2:
        return 'Description';
      case 3:
        return 'Units';
      case 4:
        return 'Zero scale';
      case 5:
        return 'Full scale';
      default:
        return 'Unknown';
    }
  }

  units() {
    const { step, units } = this.state;

    return [4, 5].includes(step) ? units : undefined;
  }

  value() {
    const {
      description, fullScale, name, step, units, zeroScale,
    } = this.state;

    switch (step) {
      case 1:
        return name;
      case 2:
        return description;
      case 3:
        return units;
      case 4:
        return zeroScale;
      case 5:
        return fullScale;
      default:
        return '';
    }
  }

  render() {
    const { handleCloseClick } = this.props;
    const { step } = this.state;

    return (
      <AddKeyboard
        disabledBack={step === SettingsSensorTypesAdd.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === SettingsSensorTypesAdd.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === SettingsSensorTypesAdd.maxStep() ? this.handleSaveClick : undefined}
        inputCheck={this.inputCheck}
        inputPattern={this.inputPattern()}
        label={this.label()}
        subtitle={`Step ${step} of ${SettingsSensorTypesAdd.maxStep()}`}
        title="Add sensor type"
        units={this.units()}
        value={this.value()}
      />
    );
  }
}

SettingsSensorTypesAdd.propTypes = {
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SettingsSensorTypesAdd;

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import AppBar2 from './components/AppBar2';
import ListSubheader from './components/ListSubheader';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  footer: {
    height: theme.spacing(3),
  },
  formGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  formItem: {
    justifyContent: 'space-between',
    marginLeft: 0,
    marginBottom: 14,
    marginTop: 6,
  },
  formLabel: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - 58px)`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    ...theme.mixins.gutters(),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: 9,
  },
  width: {
    width: 500,
  },
  wrapper: {
    height: '100%',
  },
});

class RelaysEdit2 extends React.Component {
  constructor(props) {
    super(props);

    this.handleChecked = this.handleChecked.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChecked(zoneId, alarmId = undefined) {
    const { handleChange, zones } = this.props;

    const zone = zones.find(item => item.id === zoneId);
    if (zone) {
      if (alarmId) {
        const alarm = zone.alarms.find(value => value.id === alarmId);
        if (alarm) {
          alarm.checked = !alarm.checked;
        }
      } else {
        zone.faultRelay = !zone.faultRelay;
      }
    }

    handleChange(zones);
  }

  handleKeyDown(event) {
    const {
      disabledNext, disabledSave, handleNextClick, handleSaveClick,
    } = this.props;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!disabledNext && handleNextClick) handleNextClick();
      if (!disabledSave && handleSaveClick) handleSaveClick();
    }
  }

  render() {
    const {
      classes,
      disabledBack,
      disabledNext,
      disabledSave,
      handleBackClick,
      handleCloseClick,
      handleNextClick,
      handleSaveClick,
      title,
      zones,
    } = this.props;

    const count = zones.reduce(
      // eslint-disable-next-line max-len
      (accumulator, item) => (item.faultRelay || item.alarms.reduce((accumulator2, item2) => accumulator2 || item2.checked, false)
        ? accumulator + 1
        : accumulator),
      0,
    );

    return (
      <div className={classes.wrapper}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <AppBar2
          disabledBack={disabledBack}
          disabledNext={disabledNext}
          disabledSave={disabledSave}
          handleBackClick={handleBackClick}
          handleCloseClick={handleCloseClick}
          handleNextClick={handleNextClick}
          handleSaveClick={handleSaveClick}
          title={title}
        />
        <Typography align="center" className={classes.subtitle} variant="h6">
          {`Zones (${count})`}
        </Typography>
        <div className={classes.container}>
          <Divider className={classes.width} />
        </div>
        <div className={classes.listWrapper}>
          <List className={classes.width}>
            {zones.map(zone => (
              <div key={zone.id}>
                <ListSubheader className={classes.listItem} disableSticky>{`Zone ${zone.name}`}</ListSubheader>
                <ListItem className={classes.listItem}>
                  <FormGroup className={classes.formGroup}>
                    {zone.alarms.map(item => (
                      <FormControlLabel
                        className={classes.formItem}
                        control={(
                          <Checkbox
                            checked={item.checked}
                            color="secondary"
                            onChange={() => this.handleChecked(zone.id, item.id)}
                          />
                        )}
                        key={item.id.toString()}
                        label={`Alarm ${item.id}`}
                        labelPlacement="start"
                      />
                    ))}
                    <FormControlLabel
                      className={classes.formItem}
                      control={(
                        <Checkbox
                          checked={zone.faultRelay}
                          color="secondary"
                          onChange={() => this.handleChecked(zone.id)}
                        />
                      )}
                      key="faultRelay"
                      label="Fault relay"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </ListItem>
              </div>
            ))}
            <div className={classes.footer} />
          </List>
        </div>
      </div>
    );
  }
}

RelaysEdit2.propTypes = {
  classes: PropTypes.object.isRequired,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  disabledSave: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RelaysEdit2.defaultProps = {
  disabledBack: false,
  disabledNext: false,
  disabledSave: false,
  handleBackClick: undefined,
  handleNextClick: undefined,
  handleSaveClick: undefined,
};

export default withStyles(styles)(RelaysEdit2);

import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { createContext } from 'react';

export const AbilityContext = createContext();
export const Auth = createContextualCan(AbilityContext.Consumer);

const ability = [
  { actions: 'create', subject: 'device' },
  { actions: 'delete', subject: 'device' },
  { actions: 'update', subject: 'device' },
  { actions: 'create', subject: 'sensorType' },
  { actions: 'delete', subject: 'sensorType' },
  { actions: 'update', subject: 'sensorType' },
  { actions: 'update', subject: 'settings' },
  { actions: 'create', subject: 'zone' },
  { actions: 'delete', subject: 'zone' },
  { actions: 'update', subject: 'zone' },
];

export function getAbility() {
  return new Ability(ability);
}

import PropTypes from 'prop-types';
import React from 'react';
import RelaysAddMulti from './RelaysAddMulti';
import RelaysAddSingle from './RelaysAddSingle';
import { apiAddRelays } from './utils/api';

class RelaysAdd extends React.Component {
  constructor(props) {
    super(props);

    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSaveClick(value) {
    const { addCount, handleSaveClick: handleSaveClickProp } = this.props;

    apiAddRelays(value).then((data) => {
      if (this.mounted) {
        if (addCount === 1 && data) {
          handleSaveClickProp(data.config.id);
        } else {
          handleSaveClickProp();
        }
      }
    });
  }

  render() {
    const { addCount, handleCloseClick } = this.props;

    return addCount === 1 ? (
      <RelaysAddSingle handleCloseClick={handleCloseClick} handleSaveClick={this.handleSaveClick} />
    ) : (
      <RelaysAddMulti addCount={addCount} handleCloseClick={handleCloseClick} handleSaveClick={this.handleSaveClick} />
    );
  }
}

RelaysAdd.propTypes = {
  addCount: PropTypes.number.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default RelaysAdd;

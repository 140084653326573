import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import FileUploadIcon from './images/FileUploadOutlined';
import has from './utils/has';
import { isObject } from './utils/object';
import { themeLight } from './utils/theme';

const styles = theme => ({
  button: {
    width: 375,
    maxWidth: 375,
    height: 44,
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    boxShadow: theme.shadows[0],
    '&$disabled': {
      backgroundColor: themeLight.palette.text.disabled,
    },
    '&:hover': {
      backgroundColor: themeLight.palette.text.disabled,
    },
  },
  container: {
    position: 'relative',
    paddingTop: 20,
    paddingBottom: 4,
    flex: '1 1 0',
    minWidth: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  content: {
    height: 87,
  },
  disabled: {},
  file: {
    textAlign: 'left',
  },
  icon: {
    color: themeLight.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  label: {
    position: 'absolute',
    top: 5,
    left: 0,
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
});

class UploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      analogOutCount: null,
      config: null,
      errorConfig: null,
      fileName: null,
      relayCount: null,
      sensorCount: null,
      zoneCount: null,
    };

    this.inputConfigRef = React.createRef(null);

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleInputConfigChange = this.handleInputConfigChange.bind(this);
    this.handleSelectConfigFile = this.handleSelectConfigFile.bind(this);
  }

  handleCancelClick() {
    this.handleClose();
  }

  handleClose() {
    const { handleClose } = this.props;

    this.setState({
      analogOutCount: null,
      config: null,
      errorConfig: null,
      fileName: null,
      relayCount: null,
      sensorCount: null,
      zoneCount: null,
    });
    handleClose();
  }

  handleConfirmClick() {
    const { handleConfirmClick: handleConfirmClickProp } = this.props;
    const { config, fileName } = this.state;

    if (config !== null) {
      handleConfirmClickProp(config, fileName).then(() => this.handleClose());
    } else {
      this.handleClose();
    }
  }

  handleInputConfigChange(event) {
    if (event.target.files.length > 0) {
      const fileConfig = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        let analogOutCount = null;
        let config = null;
        let errorConfig = null;
        let relayCount = null;
        let sensorCount = null;
        let zoneCount = null;

        try {
          config = JSON.parse(reader.result);

          // lanes
          if (!has.call(config, 'lanes') || !Array.isArray(config.lanes)) {
            throw Error('Missing valid lanes array');
          }
          const laneIds = [];
          config.lanes.forEach((lane) => {
            if (!isObject(lane)) throw Error('Lane not an object');
            ['baud', 'bytesize', 'device', 'id', 'parity', 'stopbits'].forEach((parameter) => {
              if (!has.call(lane, parameter)) {
                throw Error(`Lane missing parameter ${parameter}`);
              }
            });
            if (!Number.isInteger(lane.id) || lane.id < 1) {
              throw Error(`Invalid lane id ${lane.id}`);
            }
            if (laneIds.includes(lane.id)) {
              throw Error(`Found lane ${lane.id} more than once`);
            }
            laneIds.push(lane.id);
            if (!Number.isInteger(lane.baud)) {
              throw Error(`Invalid baud rate for lane ${lane.id}`);
            }
            if (!Number.isInteger(lane.bytesize) || ![5, 6, 7, 8].includes(lane.bytesize)) {
              throw Error(`Invalid bize size for lane ${lane.id}`);
            }
            if (typeof lane.device !== 'string') {
              throw Error(`Invalid device for lane ${lane.id}`);
            }
            if (typeof lane.parity !== 'string' || !['E', 'M', 'N', 'O', 'S'].includes(lane.parity)) {
              throw Error(`Invalid parity for lane ${lane.id}`);
            }
            if (!Number.isInteger(lane.stopbits) || ![1, 2].includes(lane.stopbits)) {
              throw Error(`Invalid stop bits for lane ${lane.id}`);
            }
          });

          // analogTypes
          if (!has.call(config, 'analogTypes') || !Array.isArray(config.analogTypes)) {
            throw Error('Missing valid analogTypes array');
          }
          const analogTypeIds = [];
          config.analogTypes.forEach((analogType) => {
            if (!isObject(analogType)) throw Error('Analog type not an object');
            ['fullScale', 'id', 'units', 'zeroScale'].forEach((parameter) => {
              if (!has.call(analogType, parameter)) {
                throw Error(`Analog type missing parameter ${parameter}`);
              }
            });
            if (!Number.isInteger(analogType.id) || analogType.id < 1) {
              throw Error(`Invalid analog type id ${analogType.id}`);
            }
            if (analogTypeIds.includes(analogType.id)) {
              throw Error(`Found analog type ${analogType.id} more than once`);
            }
            analogTypeIds.push(analogType.id);
            if (typeof analogType.fullScale !== 'number') {
              throw Error(`Invalid full scale for analog type ${analogType.id}`);
            }
            if (typeof analogType.units !== 'string') {
              throw Error(`Invalid units for analog type ${analogType.id}`);
            }
            if (typeof analogType.zeroScale !== 'number') {
              throw Error(`Invalid zero scale for analog type ${analogType.id}`);
            }
          });

          // analogOuts
          if (!has.call(config, 'analogOuts') || !Array.isArray(config.analogOuts)) {
            throw Error('Missing valid analogOuts array');
          }
          const analogOutIds = [];
          config.analogOuts.forEach((analogOut) => {
            if (!isObject(analogOut)) throw Error('Analog out not an object');
            ['address', 'analogTypeId', 'channel', 'enabled', 'id', 'lane', 'scaleFactor', 'type'].forEach(
              (parameter) => {
                if (!has.call(analogOut, parameter)) {
                  throw Error(`Analog out missing parameter ${parameter}`);
                }
              },
            );
            if (!Number.isInteger(analogOut.id) || analogOut.id < 1) {
              throw Error(`Invalid analog out id ${analogOut.id}`);
            }
            if (analogOutIds.includes(analogOut.id)) {
              throw Error(`Found analog out ${analogOut.id} more than once`);
            }
            analogOutIds.push(analogOut.id);
            if (!Number.isInteger(analogOut.address) || analogOut.address < 1 || analogOut.address > 247) {
              throw Error(`Invalid address for analog out ${analogOut.id}`);
            }
            if (!Number.isInteger(analogOut.analogTypeId) || !analogTypeIds.includes(analogOut.analogTypeId)) {
              throw Error(`Invalid analogTypeId for analog out ${analogOut.id}`);
            }
            if (!Number.isInteger(analogOut.channel) || analogOut.channel < 1 || analogOut.channel > 8) {
              throw Error(`Invalid channel for analog out ${analogOut.id}`);
            }
            if (typeof analogOut.enabled !== 'boolean') {
              throw Error(`Invalid enabled value for analog out ${analogOut.id}`);
            }
            if (!Number.isInteger(analogOut.lane)) {
              throw Error(`Invalid lane for analog out ${analogOut.id}`);
            }
            if (!laneIds.includes(analogOut.lane)) {
              throw Error(`Unknown lane for analog out ${analogOut.id}`);
            }
            if (typeof analogOut.scaleFactor !== 'number') {
              throw Error(`Invalid scale factor for analog out ${analogOut.id}`);
            }
            if (typeof analogOut.type !== 'string' || !['BC8AOI'].includes(analogOut.type)) {
              throw Error(`Invalid type for analog out ${analogOut.id}`);
            }
          });

          // relays
          if (!has.call(config, 'relays') || !Array.isArray(config.relays)) {
            throw Error('Missing valid relays array');
          }
          const relayIds = [];
          config.relays.forEach((relay) => {
            if (!isObject(relay)) throw Error('Relay not an object');
            ['channel', 'enabled', 'id', 'latching', 'minimumRunTime', 'normalState', 'postRunTime', 'type'].forEach(
              (parameter) => {
                if (!has.call(relay, parameter)) {
                  throw Error(`Relay missing parameter ${parameter}`);
                }
              },
            );
            if (!Number.isInteger(relay.id) || relay.id < 1) {
              throw Error(`Invalid relay id ${relay.id}`);
            }
            if (relayIds.includes(relay.id)) {
              throw Error(`Found relay ${relay.id} more than once`);
            }
            relayIds.push(relay.id);
            if (relay.type !== 'Local') {
              if (!has.call(relay, 'address')) {
                throw Error(`Missing address for relay ${relay.id}`);
              }
              if (!Number.isInteger(relay.address) || relay.address < 1 || relay.address > 247) {
                throw Error(`Invalid address for relay ${relay.id}`);
              }
              if (!has.call(relay, 'lane')) {
                throw Error(`Missing lane for relay ${relay.id}`);
              }
              if (!Number.isInteger(relay.lane)) {
                throw Error(`Invalid lane for relay ${relay.id}`);
              }
              if (!laneIds.includes(relay.lane)) {
                throw Error(`Unknown lane for relay ${relay.id}`);
              }
              if (!Number.isInteger(relay.channel) || relay.channel < 1 || relay.channel > 8) {
                throw Error(`Invalid channel for relay ${relay.id}`);
              }
            } else if (!Number.isInteger(relay.channel) || relay.channel < 1 || relay.channel > 16) {
              throw Error(`Invalid channel for relay ${relay.id}`);
            }
            if (typeof relay.enabled !== 'boolean') {
              throw Error(`Invalid enabled value for relay ${relay.id}`);
            }
            if (typeof relay.latching !== 'boolean') {
              throw Error(`Invalid latching value for relay ${relay.id}`);
            }
            if (!Number.isInteger(relay.minimumRunTime) || ![0, 300, 600, 900].includes(relay.minimumRunTime)) {
              throw Error(`Invalid minimum run time for relay ${relay.id}`);
            }
            if (typeof relay.normalState !== 'boolean') {
              throw Error(`Invalid normal state for relay ${relay.id}`);
            }
            if (!Number.isInteger(relay.postRunTime) || ![0, 300, 600, 900].includes(relay.postRunTime)) {
              throw Error(`Invalid post run time for relay ${relay.id}`);
            }
            if (typeof relay.type !== 'string' || !['ERE', 'Local'].includes(relay.type)) {
              throw Error(`Invalid type for relay ${relay.id}`);
            }
          });

          // roles
          if (!has.call(config, 'roles') || !Array.isArray(config.roles)) {
            throw Error('Missing valid roles array');
          }
          const roleIds = [];
          config.roles.forEach((role) => {
            if (!isObject(role)) throw Error('Role not an object');
            ['ability', 'id'].forEach((parameter) => {
              if (!has.call(role, parameter)) {
                throw Error(`Role missing parameter ${parameter}`);
              }
            });
            if (typeof role.id !== 'string') {
              throw Error('Invalid id for role');
            }
            if (roleIds.includes(role.id)) {
              throw Error(`Found role ${role.id} more than once`);
            }
            roleIds.push(role.id);
            if (!Array.isArray(role.ability)) {
              throw Error(`ability not an array for role ${role.id}`);
            }
            const abilityIds = [];
            role.ability.forEach((ability) => {
              if (!isObject(ability)) throw Error(`Ability not an object in role ${role.id}`);
              if (typeof ability.actions !== 'string') {
                throw Error(`Invalid action for ability on role ${role.id}`);
              }
              if (typeof ability.subject !== 'string') {
                throw Error(`Invalid subject for ability on role ${role.id}`);
              }
              const abilityId = `${ability.actions}.${ability.subject}`;
              if (abilityIds.includes(abilityId)) {
                throw Error(`Found ability ${abilityId} in role ${role.id} more than once`);
              }
              abilityIds.push(abilityId);
            });
          });

          // sensorTypes
          if (!has.call(config, 'sensorTypes') || !Array.isArray(config.sensorTypes)) {
            throw Error('Missing valid sensorTypes array');
          }
          const sensorTypeIds = [];
          const sensorTypeNames = [];
          config.sensorTypes.forEach((sensorType) => {
            if (!isObject(sensorType)) throw Error('Sensor type not an object');
            [
              'alarmHysteresis',
              'alarmType',
              'alarms',
              'description',
              'faultLimit',
              'fullScale',
              'id',
              'name',
              'units',
              'zeroBuffer',
              'zeroScale',
            ].forEach((parameter) => {
              if (!has.call(sensorType, parameter)) {
                throw Error(`Sensor missing parameter ${parameter}`);
              }
            });
            if (!Number.isInteger(sensorType.id) || sensorType.id < 1) {
              throw Error(`Invalid sensor type id ${sensorType.id}`);
            }
            if (sensorTypeIds.includes(sensorType.id)) {
              throw Error(`Found sensor type ${sensorType.id} more than once`);
            }
            sensorTypeIds.push(sensorType.id);
            if (typeof sensorType.alarmHysteresis !== 'number') {
              throw Error(`Invalid alarm hysteresis for sensor type ${sensorType.id}`);
            }
            if (
              typeof sensorType.alarmType !== 'string'
              || !['decreasing', 'increasing', 'window'].includes(sensorType.alarmType)
            ) {
              throw Error(`Invalid alarm type for sensor type ${sensorType.id}`);
            }
            if (!Array.isArray(sensorType.alarms)) {
              throw Error(`Invalid alarms array for sensor type ${sensorType.id}`);
            }
            const alarmIds = [];
            sensorType.alarms.forEach((alarm) => {
              if (!isObject(alarm)) throw Error(`Alarm not an object for sensor type id ${sensorType.id}`);
              ['id', 'delay', 'setPoint'].forEach((parameter) => {
                if (!has.call(alarm, parameter)) {
                  throw Error(`Alarm missing parameter ${parameter}`);
                }
              });
              if (!Number.isInteger(alarm.id)) {
                throw Error(`Invalid alarm id for sensor type id ${sensorType.id}`);
              }
              if (alarmIds.includes(alarm.id)) {
                throw Error(`Found alarm ${alarm.id} in sensor type ${sensorType.id} more than once`);
              }
              alarmIds.push(alarm.id);
              if (!Number.isInteger(alarm.delay)) {
                throw Error(`Invalid alarm delay for sensor type id ${sensorType.id}`);
              }
              if (typeof alarm.setPoint !== 'number') {
                throw Error(`Invalid alarm set point for sensor type ${sensorType.id}`);
              }
            });
            if (
              has.call(sensorType, 'amc400Id')
              && !(sensorType.amc400Id === null || Number.isInteger(sensorType.amc400Id))
            ) {
              throw Error(`Invalid amc400Id value for sensor type ${sensorType.id}`);
            }
            if (has.call(sensorType, 'custom') && typeof sensorType.custom !== 'boolean') {
              throw Error(`Invalid custom value for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.description !== 'string') {
              throw Error(`Invalid description for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.faultLimit !== 'number') {
              throw Error(`Invalid fault limit for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.fullScale !== 'number') {
              throw Error(`Invalid full scale for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.name !== 'string') {
              throw Error(`Invalid name for sensor type ${sensorType.id}`);
            }
            if (sensorTypeNames.includes(sensorType.name)) {
              throw Error(`Found sensor type name ${sensorType.name} more than once`);
            }
            sensorTypeNames.push(sensorType.name);
            if (typeof sensorType.units !== 'string') {
              throw Error(`Invalid units for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.zeroBuffer !== 'number') {
              throw Error(`Invalid zero buffer for sensor type ${sensorType.id}`);
            }
            if (typeof sensorType.zeroScale !== 'number') {
              throw Error(`Invalid zero scale for sensor type ${sensorType.id}`);
            }
          });

          // sensors
          if (!has.call(config, 'sensors') || !Array.isArray(config.sensors)) {
            throw Error('Missing valid sensors array');
          }
          const sensorIds = [];
          config.sensors.forEach((sensor) => {
            if (!isObject(sensor)) throw Error('Sensor not an object');
            ['address', 'alarmPoints', 'alarms', 'enabled', 'id', 'lane', 'sensorTypeId', 'type'].forEach((parameter) => {
              if (!has.call(sensor, parameter)) {
                throw Error(`Sensor ${sensor.id} missing parameter ${parameter}`);
              }
            });
            if (!Number.isInteger(sensor.id) || sensor.id < 1) {
              throw Error(`Invalid sensor id ${sensor.id}`);
            }
            if (sensorIds.includes(sensor.id)) {
              throw Error(`Found sensor ${sensor.id} more than once`);
            }
            sensorIds.push(sensor.id);
            if (!Number.isInteger(sensor.address) || sensor.address < 1 || sensor.address > 247) {
              throw Error(`Invalid address for sensor ${sensor.id}`);
            }
            if (!Array.isArray(sensor.alarms)) {
              throw Error(`alarms not an array for sensor ${sensor.id}`);
            }
            if (typeof sensor.enabled !== 'boolean') {
              throw Error(`Invalid enabled value for sensor ${sensor.id}`);
            }
            if (!Number.isInteger(sensor.lane)) {
              throw Error(`Invalid lane for sensor ${sensor.id}`);
            }
            if (!laneIds.includes(sensor.lane)) {
              throw Error(`Unknown lane for sensor ${sensor.id}`);
            }
            if (!Number.isInteger(sensor.sensorTypeId) || !sensorTypeIds.includes(sensor.sensorTypeId)) {
              throw Error(`Invalid sensorTypeId for sensor ${sensor.id}`);
            }
            const alarmIds = [];
            sensor.alarms.forEach((alarm) => {
              if (!isObject(alarm)) throw Error(`Alarm not an object for sensor ${sensor.id}`);
              ['delay', 'id', 'setPoint'].forEach((parameter) => {
                if (!has.call(alarm, parameter)) {
                  throw Error(`Sensor ${sensor.id} alarm missing parameter ${parameter}`);
                }
              });
              if (!Number.isInteger(alarm.id)) {
                throw Error(`Invalid alarm id for sensor ${sensor.id}`);
              }
              if (alarmIds.includes(alarm.id)) {
                throw Error(`Found alarm ${alarm.id} in sensor ${sensor.id} more than once`);
              }
              alarmIds.push(alarm.id);
              if (typeof alarm.setPoint !== 'number') {
                throw Error(`Invalid alarm set point for sensor ${sensor.id}`);
              }
            });
            if (typeof sensor.type !== 'string' || !['AMC-400', 'BC8AII', 'DTR', 'SIR'].includes(sensor.type)) {
              throw Error(`Invalid type for sensor ${sensor.id}`);
            }
            if ((sensor.type === 'BC8AII') || (sensor.type === 'DTR')) {
              if (!has.call(sensor, 'channel')) {
                throw Error(`Missing channel for sensor ${sensor.id}`);
              }
              if (!Number.isInteger(sensor.channel) || sensor.channel < 1 || sensor.channel > 8) {
                throw Error(`Invalid channel for sensor ${sensor.id}`);
              }
            }
          });

          // settings
          if (!has.call(config, 'settings') || !isObject(config.settings)) {
            throw Error('Missing valid settings object');
          }
          ['bacnet', 'displayCalibration', 'fault', 'powerUpAlarmDelay', 'siteName', 'defaultTimeZone'].forEach((parameter) => {
            if (!has.call(config.settings, parameter)) {
              throw Error(`Settings missing parameter ${parameter}`);
            }
          });
          if (!isObject(config.settings.bacnet)) {
            throw Error('Missing valid BACnet object');
          }
          ['deviceId', 'deviceName', 'port'].forEach((parameter) => {
            if (!has.call(config.settings.bacnet, parameter)) {
              throw Error(`BACnet missing parameter ${parameter}`);
            }
          });
          if (
            !Number.isInteger(config.settings.bacnet.deviceId)
            || config.settings.bacnet.deviceId < 0
            || config.settings.bacnet.deviceId > 4194302
          ) {
            throw Error('Invalid BACnet device id');
          }
          if (typeof config.settings.bacnet.deviceName !== 'string') {
            throw Error('Invalid bacnet device name');
          }
          if (
            !Number.isInteger(config.settings.bacnet.port)
            || config.settings.bacnet.port < 0
            || config.settings.bacnet.port > 65535
          ) {
            throw Error('Invalid BACnet port');
          }
          if (typeof config.settings.displayCalibration !== 'boolean') {
            throw Error('Invalid displayCalibration value');
          }
          if (!isObject(config.settings.fault)) {
            throw Error('Missing valid fault object');
          }
          ['delay', 'relay'].forEach((parameter) => {
            if (!has.call(config.settings.fault, parameter)) {
              throw Error(`Fault missing parameter ${parameter}`);
            }
          });
          if (
            !Number.isInteger(config.settings.fault.delay)
            || ![0, 10, 30, 60, 300, 600].includes(config.settings.fault.delay)
          ) {
            throw Error('Invalid fault delay value');
          }
          if (!Number.isInteger(config.settings.fault.relay) && config.settings.fault.relay !== null) {
            throw Error('Invalid fault relay');
          }
          if (!relayIds.includes(config.settings.fault.relay) && config.settings.fault.relay !== null) {
            throw Error('Unknown fault relay');
          }
          if (typeof config.settings.defaultTimeZone !=='string' && config.settings.defaultTimeZone !== null) {
            throw Error('Invalid defaultTimeZone value');            
          }
          if (
            !Number.isInteger(config.settings.powerUpAlarmDelay)
            || ![0, 30, 60, 120, 300].includes(config.settings.powerUpAlarmDelay)
          ) {
            throw Error('Invalid powerUpAlarmDelay value');
          }
          if (typeof config.settings.siteName !== 'string' && config.settings.siteName !== null) {
            throw Error('Invalid siteName value');
          }

          // zones
          if (!has.call(config, 'zones') || !Array.isArray(config.zones)) {
            throw Error('Missing valid zones array');
          }
          const zoneIds = [];
          const zoneNames = [];
          const zoneSensors = [];
          const zoneAnalogOuts = [];
          config.zones.forEach((zone) => {
            if (!isObject(zone)) throw Error('Zone not an object');
            [
              'aggregationType',
              'alarms',
              'analogOuts',
              'faultRelay',
              'id',
              'name',
              'samplePeriod',
              'sensorIds',
            ].forEach((parameter) => {
              if (!has.call(zone, parameter)) {
                throw Error(`Zone missing parameter ${parameter}`);
              }
            });
            if (!Number.isInteger(zone.id) || zone.id < 1) {
              throw Error(`Invalid zone id ${zone.id}`);
            }
            if (zoneIds.includes(zone.id)) {
              throw Error(`Found zone ${zone.id} more than once`);
            }
            zoneIds.push(zone.id);
            if (typeof zone.aggregationType !== 'string' || !['average', 'peak'].includes(zone.aggregationType)) {
              throw Error(`Invalid aggregation type for zone ${zone.id}`);
            }
            if (!Array.isArray(zone.alarms)) {
              throw Error(`alarms not an array for zone ${zone.id}`);
            }
            const alarmIds = [];
            zone.alarms.forEach((alarm) => {
              if (!isObject(alarm)) throw Error(`Alarm not an object for zone ${zone.id}`);
              ['id', 'relays'].forEach((parameter) => {
                if (!has.call(alarm, parameter)) {
                  throw Error(`Zone alarm missing parameter ${parameter}`);
                }
              });
              if (!Number.isInteger(alarm.id)) {
                throw Error(`Invalid alarm id for zone ${zone.id}`);
              }
              if (alarmIds.includes(alarm.id)) {
                throw Error(`Found alarm ${alarm.id} in zone ${zone.id} more than once`);
              }
              alarmIds.push(alarm.id);
              if (!Array.isArray(alarm.relays)) {
                throw Error(`relays not an array for alarm in zone ${zone.id}`);
              }
              const zoneRelayIds = [];
              alarm.relays.forEach((relayId) => {
                if (!Number.isInteger(relayId)) {
                  throw Error(`Invalid relayId for alarm in zone ${zone.id}`);
                }
                if (!relayIds.includes(relayId)) {
                  throw Error(`Unknown relay in zone ${zone.id}`);
                }
                if (zoneRelayIds.includes(relayId)) {
                  throw Error(`Found relay ${relayId} in alarm for zone ${zone.id} more than once`);
                }
                zoneRelayIds.push(relayId);
              });
            });
            if (!Array.isArray(zone.analogOuts)) {
              throw Error(`analogOuts not an array for zone ${zone.id}`);
            }
            zone.analogOuts.forEach((analogOutId) => {
              if (!Number.isInteger(analogOutId)) {
                throw Error(`Invalid analogOutId in zone ${zone.id}`);
              }
              if (!analogOutIds.includes(analogOutId)) {
                throw Error(`Unknown analog out in zone ${zone.id}`);
              }
              if (zoneAnalogOuts.includes(analogOutId)) {
                throw Error(`Found analog out ${analogOutId} in zones more than once`);
              }
              zoneAnalogOuts.push(analogOutId);
            });
            if (!Number.isInteger(zone.faultRelay) && zone.faultRelay !== null) {
              throw Error(`Invalid fault relay in zone ${zone.id}`);
            }
            if (!relayIds.includes(zone.faultRelay) && zone.faultRelay !== null) {
              throw Error(`Unknown fault relay in zone ${zone.id}`);
            }
            if (typeof zone.name !== 'string') {
              throw Error(`Invalid name for zone ${zone.id}`);
            }
            if (zoneNames.includes(zone.name)) {
              throw Error(`Found zone name ${zone.name} more than once`);
            }
            zoneNames.push(zone.name);
            if (!Number.isInteger(zone.samplePeriod) || zone.samplePeriod < 1 || zone.samplePeriod > 60) {
              throw Error(`Invalid sample period for ${zone.id}`);
            }
            if (!Array.isArray(zone.sensorIds)) {
              throw Error(`sensors not an array for zone ${zone.id}`);
            }
            zone.sensorIds.forEach((sensorId) => {
              if (!Number.isInteger(sensorId)) {
                throw Error(`Invalid sensorId in zone ${zone.id}`);
              }
              if (!sensorIds.includes(sensorId)) {
                throw Error(`Unknown sensor in zone ${zone.id}`);
              }
              if (zoneSensors.includes(sensorId)) {
                throw Error(`Found sensor ${sensorId} in zones more than once`);
              }
              zoneSensors.push(sensorId);
            });
          });

          // Collect counts
          sensorCount = config.sensors.length;
          relayCount = config.relays.length;
          analogOutCount = config.analogOuts.length;
          zoneCount = config.zones.length;
        } catch (e) {
          if (e instanceof SyntaxError) {
            errorConfig = 'Malformed JSON';
          } else if (e instanceof Error) {
            errorConfig = e.message;
          }
        }
        this.setState({
          analogOutCount,
          config,
          errorConfig,
          fileName: fileConfig.name,
          relayCount,
          sensorCount,
          zoneCount,
        });
      };
      reader.readAsText(fileConfig);
    }
  }

  handleSelectConfigFile() {
    if (this.inputConfigRef.current) {
      this.inputConfigRef.current.value = null;
      this.inputConfigRef.current.click();
    }
  }

  render() {
    const { classes, open } = this.props;
    const {
      analogOutCount, errorConfig, fileName, relayCount, sensorCount, zoneCount,
    } = this.state;

    return (
      <ThemeProvider theme={themeLight}>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>Upload</DialogTitle>
          <DialogContent className={classes.content}>
            <Button
              className={classes.button}
              disableElevation
              fullWidth
              onClick={this.handleSelectConfigFile}
              size="large"
              variant="contained"
            >
              <FileUploadIcon className={classes.icon} />
              {fileName !== null ? (
                <Typography className={classes.file} noWrap variant="body2">
                  {fileName}
                </Typography>
              ) : (
                  <Typography variant="body2">Select configuration file</Typography>
                )}
            </Button>
            {errorConfig !== null && (
              <FormHelperText error margin="dense">
                {errorConfig}
              </FormHelperText>
            )}
            {sensorCount !== null && (
              <FormHelperText margin="dense">
                {`${sensorCount} sensor${sensorCount !== 1 ? 's' : ''}, ${relayCount} relay${
                  relayCount !== 1 ? 's' : ''
                  }, ${analogOutCount} analog out${analogOutCount !== 1 ? 's' : ''}, ${zoneCount} zone${
                  zoneCount !== 1 ? 's' : ''
                  }`}
              </FormHelperText>
            )}
            <input
              className={classes.input}
              onChange={this.handleInputConfigChange}
              ref={this.inputConfigRef}
              accept=".json,application/json"
              type="file"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelClick}>Cancel</Button>
            <Button
              color="secondary"
              disabled={fileName === null || errorConfig !== null}
              onClick={this.handleConfirmClick}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

UploadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(UploadDialog);

import PropTypes from 'prop-types';
import React from 'react';
import AddKeyboard from './components/AddKeyboard';
import AddRadio from './components/AddRadio';
import RelaysEdit2 from './RelaysEdit2';
import { apiGetZones } from './utils/api';
import {
  optionsChannel,
  optionsChannel16,
  optionsLane,
  optionsLatching,
  optionsNormalState,
  optionsRelayType,
} from './utils/options';
import { patternNumber } from './utils/patterns';
import { relayLatchingValue, relayStateValue } from './utils/utils';

class RelaysAddSingle extends React.Component {
  static minStep() {
    return 1;
  }

  static maxStep() {
    return 7;
  }

  static stepCurrent(step, type) {
    if (type === 'Local' && [6, 7].includes(step)) {
      return step - 2;
    }

    return step;
  }

  static stepsTotal(type) {
    return type === 'Local' ? this.maxStep() - 2 : this.maxStep();
  }

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      channel: '',
      lane: '',
      latching: 'Non-latching',
      normalState: 'De-energized',
      step: 1,
      type: '',
      zones: [],
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.options = this.options.bind(this);
    this.label = this.label.bind(this);
    this.value = this.value.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    apiGetZones().then((zones) => {
      if (this.mounted) {
        this.setState({
          zones: zones.map(value => ({
            id: value.id,
            name: value.name,
            alarms: value.alarms.map(alarm => ({
              id: alarm.id,
              checked: false,
            })),
            faultRelay: false,
          })),
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleBackClick() {
    const { step, type } = this.state;

    if (type === 'Local' && step === 6) {
      this.setState({ step: step - 3 });
    } else {
      this.setState({ step: step - 1 });
    }
  }

  handleChange(value) {
    const { step } = this.state;

    if (step === 1) {
      this.setState({ type: value });
    } else if (step === 2) {
      this.setState({ normalState: value });
    } else if (step === 3) {
      this.setState({ latching: value });
    } else if (step === 4) {
      this.setState({ lane: Number(value) });
    } else if (step === 5) {
      this.setState({ address: value });
    } else if (step === 6) {
      this.setState({ channel: Number(value) });
    } else if (step === 7) {
      this.setState({ zones: value });
    }
  }

  handleNextClick() {
    const { step, type } = this.state;

    if (type === 'Local' && step === 3) {
      this.setState({ step: step + 3 });
    } else {
      this.setState({ step: step + 1 });
    }
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const {
      address, channel, lane, latching, normalState, type, zones,
    } = this.state;

    handleSaveClickProp({
      address: Number(address),
      channel,
      lane,
      latching: relayLatchingValue(latching),
      normalState: relayStateValue(normalState),
      type,
      zones: zones.reduce(
        // eslint-disable-next-line max-len
        (accumulator, item) => (item.faultRelay || item.alarms.reduce((accumulator2, item2) => accumulator2 || item2.checked, false)
          ? accumulator.concat({
            alarms: item.alarms.reduce(
              (accumulator2, item2) => (item2.checked ? accumulator2.concat(item2.id) : accumulator2),
              [],
            ),
            faultRelay: item.faultRelay,
            id: item.id,
          })
          : accumulator),
        [],
      ),
    });
  }

  inputCheck(value) {
    const { step } = this.state;

    if (step === 5) {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    return null;
  }

  inputPattern() {
    const { step } = this.state;

    if (step === 5) {
      return patternNumber;
    }

    return undefined;
  }

  label() {
    const { step, type } = this.state;

    switch (step) {
      case 1:
        return 'Device';
      case 2:
        return 'Normal state';
      case 3:
        return 'Type';
      case 4:
        return 'Modbus lane';
      case 5:
        return 'Modbus address';
      case 6:
        return type === 'Local' ? 'Local channel' : 'Modbus channel';
      default:
        return 'Unknown';
    }
  }

  options() {
    const { step, type } = this.state;

    switch (step) {
      case 1:
        return optionsRelayType;
      case 2:
        return optionsNormalState;
      case 3:
        return optionsLatching;
      case 4:
        return optionsLane;
      case 6:
        return type === 'Local' ? optionsChannel16 : optionsChannel;
      default:
        return [];
    }
  }

  value() {
    const {
      address, channel, lane, latching, normalState, step, type, zones,
    } = this.state;

    switch (step) {
      case 1:
        return type;
      case 2:
        return normalState;
      case 3:
        return latching;
      case 4:
        return lane;
      case 5:
        return address;
      case 6:
        return channel;
      case 7:
        return zones;
      default:
        return '';
    }
  }

  render() {
    const { handleCloseClick } = this.props;
    const { step, type } = this.state;

    return (
      <>
        {[1, 2, 3, 4, 6].includes(step) && (
          <AddRadio
            disabledBack={step === RelaysAddSingle.minStep()}
            handleBackClick={this.handleBackClick}
            handleChange={this.handleChange}
            handleCloseClick={handleCloseClick}
            handleNextClick={step === RelaysAddSingle.maxStep() ? undefined : this.handleNextClick}
            handleSaveClick={step === RelaysAddSingle.maxStep() ? this.handleSaveClick : undefined}
            label={this.label()}
            options={this.options()}
            subtitle={
              !type
                ? 'Step 1'
                : `Step ${RelaysAddSingle.stepCurrent(step, type)} of ${RelaysAddSingle.stepsTotal(type)}`
            }
            title="Add relay"
            value={this.value()}
          />
        )}
        {step === 5 && (
          <AddKeyboard
            disabledBack={step === RelaysAddSingle.minStep()}
            handleBackClick={this.handleBackClick}
            handleChange={this.handleChange}
            handleCloseClick={handleCloseClick}
            handleNextClick={step === RelaysAddSingle.maxStep() ? undefined : this.handleNextClick}
            handleSaveClick={step === RelaysAddSingle.maxStep() ? this.handleSaveClick : undefined}
            inputCheck={this.inputCheck}
            inputPattern={this.inputPattern()}
            label={this.label()}
            subtitle={`Step ${RelaysAddSingle.stepCurrent(step, type)} of ${RelaysAddSingle.stepsTotal(type)}`}
            title="Add relay"
            value={this.value()}
          />
        )}
        {step === 7 && (
          <RelaysEdit2
            disabledBack={step === RelaysAddSingle.minStep()}
            handleBackClick={this.handleBackClick}
            handleChange={this.handleChange}
            handleCloseClick={handleCloseClick}
            handleNextClick={step === RelaysAddSingle.maxStep() ? undefined : this.handleNextClick}
            handleSaveClick={step === RelaysAddSingle.maxStep() ? this.handleSaveClick : undefined}
            label={this.label()}
            subtitle={`Step ${RelaysAddSingle.stepCurrent(step, type)} of ${RelaysAddSingle.stepsTotal(type)}`}
            title="Add relay"
            zones={this.value()}
          />
        )}
      </>
    );
  }
}

RelaysAddSingle.propTypes = {
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default RelaysAddSingle;

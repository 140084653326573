import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import Container from './Container';
import SignIn from './SignIn';
import { apiOnUserChanged } from './utils/api';
import { themeLight } from './utils/theme';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { user: undefined };

    this.handleUserChanged = this.handleUserChanged.bind(this);
  }

  componentDidMount() {
    apiOnUserChanged(this.handleUserChanged);
  }

  componentWillUnmount() {
    apiOnUserChanged();
  }

  handleUserChanged(user) {
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        {user !== undefined && (user ? <Container /> : <SignIn />)}
      </ThemeProvider>
    );
  }
}

export default App;

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import AppBar2 from './AppBar2';
import Scroll from './Scroll';

const styles = theme => ({
  form: {
    width: 356,
    paddingTop: theme.spacing(6),
  },
  formSubtitle: {
    paddingTop: theme.spacing(3),
  },
  formWrapper: {
    display: 'flex',
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(4)}px)`,
    justifyContent: 'center',
    ...theme.mixins.gutters(),
  },
  label: {
    lineHeight: 1,
    alignSelf: 'flex-start',
    ...theme.mixins.gutters(),
  },
  noValue: {
    fontStyle: 'italic',
  },
  radio: {
    marginRight: theme.spacing(4),
  },
  scroll: {
    height: `calc(100% - ${theme.spacing(8)}px)`,
    flex: '1 1 auto',
    padding: theme.spacing(0, 3),
    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
  },
  subtitle: {
    marginBottom: 13,
  },
  wrapper: {
    height: '100%',
  },
});

class AddRadio extends React.Component {
  static scrollTop(options, value) {
    const index = options.findIndex(item => item.value === value);
    return index < 4 ? 0 : (index - 2) * 42;
  }

  constructor(props) {
    super(props);

    this.keys = [];

    this.disabledNext = this.disabledNext.bind(this);
    this.disabledSave = this.disabledSave.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.scrollKey = this.scrollKey.bind(this);
    this.updateKeys = this.updateKeys.bind(this);
  }

  disabledNext() {
    const {
      disabledNext, handleNextClick, options, value,
    } = this.props;

    return disabledNext || (handleNextClick && !options.find(item => item.value === value));
  }

  disabledSave() {
    const {
      disabledSave, handleSaveClick, options, value,
    } = this.props;

    return disabledSave || (handleSaveClick && !options.find(item => item.value === value));
  }

  handleBackClick() {
    const { handleBackClick: handleBackClickProp } = this.props;

    this.updateKeys();
    handleBackClickProp();
  }

  handleKeyDown(event) {
    const { handleNextClick, handleSaveClick } = this.props;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.disabledNext() && handleNextClick) this.handleNextClick();
      if (!this.disabledSave() && handleSaveClick) handleSaveClick();
    }
  }

  handleNextClick() {
    const { handleNextClick: handleNextClickProp } = this.props;

    this.updateKeys();
    if (handleNextClickProp) handleNextClickProp();
  }

  scrollKey() {
    const { label } = this.props;

    const key = this.keys.find(item => item.label === label);
    return key === undefined ? label : `${key.label}-${key.value}`;
  }

  updateKeys() {
    const { label, options, value } = this.props;

    if (options.length > 7) {
      const key = this.keys.find(item => item.label === label);
      if (key === undefined) {
        this.keys.push({ label, value });
      } else {
        key.value = value;
      }
    }
  }

  render() {
    const {
      classes,
      disabledBack,
      handleBackClick,
      handleChange,
      handleCloseClick,
      handleNextClick,
      handleSaveClick,
      label,
      options,
      subtitle,
      title,
      value,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <AppBar2
          disabledBack={disabledBack}
          disabledNext={this.disabledNext()}
          disabledSave={this.disabledSave()}
          handleBackClick={handleBackClick ? this.handleBackClick : undefined}
          handleCloseClick={handleCloseClick}
          handleNextClick={handleNextClick ? this.handleNextClick : undefined}
          handleSaveClick={handleSaveClick}
          title={title}
        />
        <div className={classes.formWrapper}>
          <div className={clsx(classes.form, subtitle && classes.formSubtitle)}>
            {subtitle && (
              <Typography align="center" className={classes.subtitle} variant="h6">
                {subtitle}
              </Typography>
            )}
            <Typography className={classes.label} color="textSecondary" component="span">
              {label}
            </Typography>
            <Scroll
              className={classes.scroll}
              key={this.scrollKey()}
              initialScrollTop={AddRadio.scrollTop(options, value)}
            >
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  name={label}
                  onChange={(event) => {
                    handleChange(event.target.value);
                  }}
                  value={value}
                >
                  {options.map(item => (
                    <FormControlLabel
                      control={<Radio className={classes.radio} color="secondary" />}
                      key={item.value.toString()}
                      label={(
                        <Typography className={clsx(item.value === '' && classes.noValue)} component="span">
                          {item.label}
                        </Typography>
                      )}
                      value={item.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Scroll>
          </div>
        </div>
      </div>
    );
  }
}

AddRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  disabledSave: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

AddRadio.defaultProps = {
  disabledBack: false,
  disabledNext: false,
  disabledSave: false,
  handleBackClick: undefined,
  handleNextClick: undefined,
  handleSaveClick: undefined,
  subtitle: undefined,
};

export default withStyles(styles)(AddRadio);

import PropTypes from 'prop-types';
import React from 'react';
import AnalogOutsDetails from './AnalogOutsDetails';
import EditDialog from './components/EditDialog';
import EditKeyboard from './components/EditKeyboard';
import { apiDeleteAnalogOut, apiGetZones, apiModifyAnalogOut } from './utils/api';
import has from './utils/has';
import history from './utils/history';
import {
  optionsAnalogOutType, optionsChannel, optionsLane, optionsRange,
} from './utils/options';
import { patternDecimal1Pos, patternNumber } from './utils/patterns';
import { valueText } from './utils/utils';

class AnalogOuts extends React.Component {
  constructor(props) {
    super(props);

    const { analogOut } = props;

    this.state = {
      analogOut,
      edit: undefined,
      metadata: null,
      options: [],
    };

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditItemClick = this.handleEditItemClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.value = this.value.bind(this);
    this.valueDialog = this.valueDialog.bind(this);

    history.defaultState({ metdata: null });
  }

  componentDidMount() {
    this.mounted = true;

    history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    this.mounted = false;

    history.removeListener(this.handleLocationChanged);
  }

  handleDeleteClick() {
    const { handleBackClick, id } = this.props;

    apiDeleteAnalogOut(id).then(() => {
      if (this.mounted) {
        this.setState({ edit: false });
      }
    });

    handleBackClick();
  }

  handleEditItemClick(item) {
    const { index } = item;

    if (index === 'analogTypeId') {
      this.setState({ edit: true, metadata: item, options: optionsRange });
    } else if (index === 'channel') {
      this.setState({ edit: true, metadata: item, options: optionsChannel });
    } else if (index === 'lane') {
      this.setState({ edit: true, metadata: item, options: optionsLane });
    } else if (index === 'type') {
      this.setState({ edit: true, metadata: item, options: optionsAnalogOutType });
    } else if (index === 'zone') {
      apiGetZones().then((zones) => {
        if (this.mounted) {
          const options = zones.map(value => ({ label: value.name, value: value.id }));
          options.push({ label: 'None', value: '' });
          this.setState({
            edit: true,
            metadata: item,
            options,
          });
        }
      });
    } else {
      history.pushAppend({ metadata: item, options: [] });
    }
  }

  handleLocationChanged(value) {
    const { metadata, options } = value;

    if (metadata) {
      this.setState({ edit: true, metadata, options });
    } else {
      this.setState({ edit: false });
    }
  }

  handleChangeItem(value, item) {
    const { id } = this.props;
    const { analogOut, options } = this.state;

    const closeDialogOrGoBack = () => {
      if (options.length !== 0) {
        this.setState({ edit: false });
      } else {
        history.goBack();
      }
    };

    if (!item) {
      closeDialogOrGoBack();
      return;
    }

    const { index } = item;
    let value2 = {};

    if (index === 'zone') {
      const newValue = value === '' ? null : Number(value);
      if (
        (newValue === null && analogOut.config[index] === null)
        || (analogOut.config[index] !== null && newValue === analogOut.config[index].id)
      ) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { zoneId: newValue };
    } else {
      const newValue = ['enabled', 'type'].includes(index) ? value : Number(value);
      if (newValue === analogOut.config[index]) {
        closeDialogOrGoBack();
        return;
      }
      value2 = { [index]: newValue };
    }

    apiModifyAnalogOut(id, value2).then((data) => {
      if (this.mounted) {
        if (data) this.setState({ analogOut: data });
        if (index !== 'enabled') closeDialogOrGoBack();
      }
    });
  }

  handleSaveClick(value) {
    const { metadata } = this.state;

    return this.handleChangeItem(value, metadata);
  }

  inputCheck(value) {
    const { metadata } = this.state;

    if (metadata.index === 'address') {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    return null;
  }

  inputPattern() {
    const { metadata } = this.state;

    if (metadata) {
      if (metadata.index === 'address') {
        return patternNumber;
      }
      if (metadata.index === 'scaleFactor') {
        return patternDecimal1Pos;
      }
    }

    return undefined;
  }

  value() {
    const { analogOut, metadata } = this.state;

    if (metadata && analogOut) {
      const { index } = metadata;
      return index === 'scaleFactor' ? valueText(analogOut.config[index], 1) : analogOut.config[index];
    }

    return '';
  }

  valueDialog() {
    const { analogOut, metadata, options } = this.state;

    if (metadata && analogOut && options.length !== 0) {
      const { index } = metadata;
      if (index === 'zone') {
        return analogOut.config[index] === null ? '' : analogOut.config[index].id;
      }
      return analogOut.config[index] === null
        || analogOut.config[index] === undefined
        || analogOut.config[index].length === 0
        ? ''
        : analogOut.config[index];
    }

    return '';
  }

  render() {
    const { handleBackClick, id } = this.props;
    const {
      analogOut, edit, metadata, options,
    } = this.state;

    if (edit === undefined) return null;

    return (
      <>
        {edit && options.length === 0 && (
          <EditKeyboard
            handleCloseClick={() => {
              history.goBack();
            }}
            handleSaveClick={this.handleSaveClick}
            inputCheck={this.inputCheck}
            inputPattern={this.inputPattern()}
            label={metadata.label}
            title={`Edit Analog output ${id}`}
            value={this.value()}
          />
        )}
        {(!edit || (edit && options.length !== 0)) && (
          <AnalogOutsDetails
            handleBackClick={handleBackClick}
            handleChangeItem={this.handleChangeItem}
            handleDeleteClick={this.handleDeleteClick}
            handleEditItemClick={this.handleEditItemClick}
            id={id}
            analogOut={analogOut}
          />
        )}
        <EditDialog
          handleCloseClick={() => {
            this.setState({ edit: false });
          }}
          handleSaveClick={this.handleSaveClick}
          label={metadata && has.call(metadata, 'label') ? metadata.label : ''}
          open={edit && options.length !== 0}
          options={options}
          value={this.valueDialog()}
        />
      </>
    );
  }
}

AnalogOuts.propTypes = {
  analogOut: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default AnalogOuts;

import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Scroll from './components/Scroll';
import { Auth } from './utils/auth';
import { iconFabSize, tableHead } from './utils/styles';

const styles = theme => ({
  fab: {
    backgroundColor: theme.palette.background.default,
    bottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
    color: theme.palette.secondary.main,
    position: 'absolute',
    right: theme.spacing(2),
  },
  iconFabSize,
  noValue: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.background.paper,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0, 0.5),
  },
  secondaryText: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  tableCell: {
    '&:first-child': {
      paddingLeft: theme.spacing(3),
    },
  },
  tableFooter: {
    paddingBottom: theme.spacing(4),
  },
  tableHead,
  tableValue: {
    lineHeight: '22px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  tableWrapper: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  wrapper: {
    height: '100%',
  },
});

function getMaxAlarms(zones) {
  const ids = [];
  if (zones) {
    const maxCount = zones.reduce((accum, item) => (item.alarms.length > accum ? item.alarms.length : accum), 0);
    for (let i = 0; i < maxCount; i += 1) {
      ids.push(i + 1);
    }
  }
  return ids;
}

function ZonesList(props) {
  const {
    classes, handleAddClick, handleListItemClick, handleSaveScrollTop, initialScrollTop, zones,
  } = props;

  const maxAlarms = getMaxAlarms(zones);
  const zonesAlarms = zones.map((item) => {
    const alarms = [];
    item.alarms.forEach(value => alarms.push(value));
    for (let i = 0, maxCount = maxAlarms.length, alarmCount = item.alarms.length; i < maxCount - alarmCount; i += 1) {
      alarms.push(null);
    }
    return { id: item.id, alarms };
  });

  return (
    <div className={classes.wrapper}>
      <Scroll
        className={classes.tableWrapper}
        handleSaveScrollTop={handleSaveScrollTop}
        initialScrollTop={initialScrollTop}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>Name</TableCell>
              {maxAlarms.map(item => (
                <TableCell className={clsx(classes.tableHead, classes.secondaryText)} key={item.toString()}>
                  {`Alarm ${item}`}
                </TableCell>
              ))}
              <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>
                Analogs
              </TableCell>
              <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>
                Fault relay
              </TableCell>
              <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>
                Sensor count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zones.map(item => (
              <TableRow hover key={item.id.toString()} onClick={() => handleListItemClick(item.id)}>
                <TableCell className={clsx(classes.tableCell, classes.tableValue)}>{item.name}</TableCell>
                {zonesAlarms
                  .find(value => value.id === item.id)
                  .alarms.map((alarm, index) => (alarm && alarm.relays.length !== 0 ? (
                    <TableCell className={clsx(classes.tableCell, classes.tableValue)} key={index.toString()}>
                      {alarm.relays.length === 1 ? `Relay ${alarm.relays[0]}` : `Relays ${alarm.relays.join(', ')}`}
                    </TableCell>
                  ) : (
                    <TableCell className={clsx(classes.tableCell, classes.tableValue)} key={index.toString()} />
                  )))}
                {item.analogOuts.length !== 0 ? (
                  <TableCell className={clsx(classes.tableCell, classes.tableValue)}>
                    {item.analogOuts.length === 1
                      ? `Analog out ${item.analogOuts[0]}`
                      : `Analog outs ${item.analogOuts.join(', ')}`}
                  </TableCell>
                ) : (
                  <TableCell className={clsx(classes.tableCell, classes.tableValue)} />
                )}
                <TableCell className={clsx(classes.tableCell, classes.tableValue)}>
                  {item.faultRelay ? `Relay ${item.faultRelay}` : ''}
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.tableValue)}>
                  {item.sensorIds.length || (
                    <Typography className={classes.noValue} variant="caption">
                      NONE
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableFooter} />
          </TableFooter>
        </Table>
      </Scroll>
      <Auth do="create" on="zone">
        <Fab className={classes.fab} onClick={handleAddClick}>
          <AddIcon className={classes.iconFabSize} />
        </Fab>
      </Auth>
    </div>
  );
}

ZonesList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(ZonesList);

import PropTypes from 'prop-types';
import React from 'react';
import AddKeyboard from './components/AddKeyboard';
import AddRadio from './components/AddRadio';
import {
  optionsAnalogOutType, optionsChannel, optionsLane, optionsRange,
} from './utils/options';
import { patternDecimal1Pos, patternNumber } from './utils/patterns';

class AnalogOutsAddSingle extends React.Component {
  static minStep() {
    return 1;
  }

  static maxStep() {
    return 7;
  }

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      analogTypeId: '',
      channel: '',
      lane: '',
      scaleFactor: '1.0',
      step: 1,
      type: 'BC8AOI',
      zoneId: 0,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.inputCheck = this.inputCheck.bind(this);
    this.inputPattern = this.inputPattern.bind(this);
    this.options = this.options.bind(this);
    this.label = this.label.bind(this);
    this.value = this.value.bind(this);
  }

  handleBackClick() {
    const { step } = this.state;

    this.setState({ step: step - 1 });
  }

  handleChange(value) {
    const { step } = this.state;

    if (step === 1) {
      this.setState({ type: value });
    } else if (step === 2) {
      this.setState({ analogTypeId: Number(value) });
    } else if (step === 3) {
      this.setState({ scaleFactor: value });
    } else if (step === 4) {
      this.setState({ lane: Number(value) });
    } else if (step === 5) {
      this.setState({ address: value });
    } else if (step === 6) {
      this.setState({ channel: Number(value) });
    } else if (step === 7) {
      this.setState({ zoneId: value === '' ? value : Number(value) });
    }
  }

  handleNextClick() {
    const { step } = this.state;

    this.setState({ step: step + 1 });
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const {
      address, analogTypeId, channel, lane, scaleFactor, type, zoneId,
    } = this.state;

    handleSaveClickProp({
      address: Number(address),
      analogTypeId,
      channel,
      lane,
      scaleFactor: Number(scaleFactor),
      type,
      zoneId: zoneId === '' ? null : zoneId,
    });
  }

  inputCheck(value) {
    const { step } = this.state;

    if (step === 5) {
      const address = Number(value);
      if (address < 1 || address > 247) {
        return 'Invalid modbus address. Valid range is 1 to 247.';
      }
    }

    return null;
  }

  inputPattern() {
    const { step } = this.state;

    if (step === 3) {
      return patternDecimal1Pos;
    }

    if (step === 5) {
      return patternNumber;
    }

    return undefined;
  }

  label() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return 'Device';
      case 2:
        return 'Range';
      case 3:
        return 'Scale factor';
      case 4:
        return 'Modbus lane';
      case 5:
        return 'Modbus address';
      case 6:
        return 'Modbus channel';
      case 7:
        return 'Zone';
      default:
        return 'Unknown';
    }
  }

  options() {
    const { zones } = this.props;
    const { step } = this.state;

    switch (step) {
      case 1:
        return optionsAnalogOutType;
      case 2:
        return optionsRange;
      case 4:
        return optionsLane;
      case 6:
        return optionsChannel;
      case 7:
        return zones.map(value => ({ label: value.name, value: value.id })).concat([{ label: 'None', value: '' }]);
      default:
        return [];
    }
  }

  value() {
    const {
      address, analogTypeId, channel, lane, scaleFactor, step, type, zoneId,
    } = this.state;

    switch (step) {
      case 1:
        return type;
      case 2:
        return analogTypeId;
      case 3:
        return scaleFactor;
      case 4:
        return lane;
      case 5:
        return address;
      case 6:
        return channel;
      case 7:
        return zoneId;
      default:
        return '';
    }
  }

  render() {
    const { handleCloseClick } = this.props;
    const { step } = this.state;

    return [3, 5].includes(step) ? (
      <AddKeyboard
        disabledBack={step === AnalogOutsAddSingle.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === AnalogOutsAddSingle.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === AnalogOutsAddSingle.maxStep() ? this.handleSaveClick : undefined}
        inputCheck={this.inputCheck}
        inputPattern={this.inputPattern()}
        label={this.label()}
        subtitle={`Step ${step} of ${AnalogOutsAddSingle.maxStep()}`}
        title="Add analog out"
        value={this.value()}
      />
    ) : (
      <AddRadio
        disabledBack={step === AnalogOutsAddSingle.minStep()}
        handleBackClick={this.handleBackClick}
        handleChange={this.handleChange}
        handleCloseClick={handleCloseClick}
        handleNextClick={step === AnalogOutsAddSingle.maxStep() ? undefined : this.handleNextClick}
        handleSaveClick={step === AnalogOutsAddSingle.maxStep() ? this.handleSaveClick : undefined}
        label={this.label()}
        options={this.options()}
        subtitle={`Step ${step} of ${AnalogOutsAddSingle.maxStep()}`}
        title="Add analog out"
        value={this.value()}
      />
    );
  }
}

AnalogOutsAddSingle.propTypes = {
  handleCloseClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AnalogOutsAddSingle;

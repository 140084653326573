import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const FileUploadOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M9,10V16H15V10H19L12,3L5,10H9M12,5.8L14.2,8H13V14H11V8H9.8L12,5.8M19,18H5V20H19V18Z" />
  </SvgIcon>
);

export default FileUploadOutlined;

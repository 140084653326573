import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import DeleteIcon from '../images/Delete';
import { iconSize } from '../utils/styles';
import { themeDark } from '../utils/theme';

const styles = theme => ({
  buttonBack: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-2),
  },
  disabled: {
    color: themeDark.palette.action.disabled,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(-1.5),
  },
  iconSize,
  spacing: {
    marginRight: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
});

function AppBar(props) {
  const {
    buttonText,
    classes,
    disabledDelete,
    elevation,
    handleBackClick,
    handleButtonClick,
    handleDeleteClick,
    handleInfoClick,
    title,
  } = props;

  return (
    <MuiAppBar color="primary" elevation={elevation} position="relative">
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <IconButton className={classes.buttonBack} color="inherit" onClick={handleBackClick}>
            <ArrowBackIcon className={classes.iconSize} />
          </IconButton>
          <Typography color="inherit" variant="h6">
            {title}
          </Typography>
        </div>
        <div className={clsx(classes.iconContainer)}>
          {handleButtonClick !== undefined && buttonText !== undefined && (
            <Button className={classes.spacing} color="inherit" onClick={handleButtonClick} variant="outlined">
              {buttonText}
            </Button>
          )}
          {handleInfoClick !== undefined && (
            <IconButton color="inherit" onClick={handleInfoClick}>
              <InfoIcon className={classes.iconSize} />
            </IconButton>
          )}
          {handleDeleteClick !== undefined && (
            <IconButton color="inherit" disabled={disabledDelete} onClick={handleDeleteClick}>
              <DeleteIcon className={clsx(classes.iconSize, disabledDelete && classes.disabled)} />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabledDelete: PropTypes.bool,
  elevation: PropTypes.number,
  handleBackClick: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleInfoClick: PropTypes.func,
  title: PropTypes.any,
};

AppBar.defaultProps = {
  buttonText: undefined,
  disabledDelete: false,
  elevation: 0,
  handleBackClick: undefined,
  handleButtonClick: undefined,
  handleDeleteClick: undefined,
  handleInfoClick: undefined,
  title: null,
};

export default withStyles(styles)(AppBar);

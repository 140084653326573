import { createMuiTheme } from '@material-ui/core/styles';
import {
  paletteDark, paletteLight, paletteSelect, paletteButton,
} from './palette';
import shadowsLight from './shadows';
import typography from './typography';

export const themeButton = createMuiTheme({
  palette: {
    ...paletteButton,
  },
  typography,
});

export const themeDark = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  palette: {
    ...paletteDark,
  },
  typography,
});

export const themeLight = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  palette: {
    ...paletteLight,
  },
  shadowsLight,
  typography,
});

export const themeSelect = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  palette: {
    ...paletteSelect,
  },
  shadowsLight,
  typography,
});

import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

export const SelectInputLight = withStyles(theme => ({
  input: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.primary,
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.primary,
    },
  },
}))(InputBase);

export const SelectInputDark = withStyles(theme => ({
  input: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid rgba(255, 255, 255, 0.38)',
    color: theme.palette.common.white,
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
      borderColor: 'rgba(255, 255, 255, 0.38)',
      color: theme.palette.common.white,
    },
  },
}))(InputBase);

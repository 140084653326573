import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const styles = theme => ({
  enter: {
    opacity: 0,
    transform: 'translateY(16px)',
  },
  enterActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  enterDone: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exit: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exitActive: {
    opacity: 0,
    transform: 'translateY(16px)',
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  exitDone: {
    opacity: 0,
    transform: 'translateY(16px)',
  },
});

function FadeSlideReplace(props) {
  const {
    children, classes, transitionKey, ...other
  } = props;

  return (
    <SwitchTransition>
      <CSSTransition
        addEndListener={(node, done) => {
          const eventListener = (event) => {
            node.removeEventListener('transitionend', eventListener, false);
            done(event);
          };
          node.addEventListener('transitionend', eventListener, false);
        }}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          enterDone: classes.enterDone,
          exit: classes.exit,
          exitActive: classes.exitActive,
          exitDone: classes.exitDone,
        }}
        key={transitionKey}
        {...other}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
}

FadeSlideReplace.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  transitionKey: PropTypes.string.isRequired,
};

export default withStyles(styles)(FadeSlideReplace);

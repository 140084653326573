import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import ListSubheader from './components/ListSubheader';
import SensorTypeName from './components/SensorTypeName';
import Separator from './components/Separator';
import { AbilityContext } from './utils/auth';
import has from './utils/has';
import { aggregationTypeText, valueText } from './utils/utils';

const styles = theme => ({
  alignItems: {
    alignItems: 'flex-start',
  },
  divider: {
    padding: theme.spacing(0, 2),
  },
  list: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listButton: {
    width: '100%',
    textAlign: 'left',
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    overflow: 'auto',
    paddingBottom: theme.spacing(1.5),
  },
  noValue: {
    fontStyle: 'italic',
  },
  paper: {
    width: 352,
  },
  sensorsNone: {
    color: theme.palette.warning.main,
    paddingBottom: theme.spacing(1.5),
  },
  tableCell: {
    paddingLeft: 0,
    borderBottom: 'none',
  },
  tableFooter: {
    height: theme.spacing(4),
  },
  tableText: {
    height: 32,
  },
  tableWrapper: {
    height: '100%',
    overflow: 'auto',
  },
  wrapper: {
    height: '100%',
  },
});

function ZoneSensorLists(zone) {
  if (!zone || !zone.sensors) {
    return [[], []];
  }

  const a = [];
  const b = [];

  zone.sensors.forEach((item) => {
    if (a.length === 0) {
      a.push(item);
    } else if (a[0].sensorTypeName === item.sensorTypeName) {
      a.push(item);
    } else if (b.length === 0) {
      b.push(item);
    } else if (b[0].sensorTypeName === item.sensorTypeName) {
      b.push(item);
    }
  });
  // More than 2 sensor types as single column
  if (a.length + b.length !== zone.sensors.length) {
    return [zone.sensors, []];
  }
  // Balance arrays
  if (b.length !== 0) {
    if (a.length < b.length) {
      while (a.length !== b.length) {
        a.push(null);
      }
    } else if (b.length < a.length) {
      while (a.length !== b.length) {
        b.push(null);
      }
    }
  }
  // Return order per sensor type
  if (b.length !== 0) {
    if (a[0].sensorTypeName > b[0].sensorTypeName) {
      return [b, a];
    }
  }
  return [a, b];
}

class ZonesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.scrollTarget = undefined;

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteCancelClick() {
    this.setState({ open: false });
  }

  handleDeleteConfirmClick() {
    const { handleDeleteClick: handleDeleteClickProp, id } = this.props;

    handleDeleteClickProp(id);
    this.setState({ open: false });
  }

  render() {
    const {
      classes, handleBackClick, handleEditItemClick, zone,
    } = this.props;
    const { open } = this.state;

    const sensorLists = ZoneSensorLists(zone);

    return (
      <AbilityContext.Consumer>
        {(ability) => {
          const disabled = !ability.can('update', 'device');
          const color = ability.can('update', 'device') ? 'initial' : 'textSecondary';
          return (
            <>
              <div className={classes.wrapper}>
                <ElevationScroll target={this.scrollTarget}>
                  <AppBar
                    handleBackClick={handleBackClick}
                    handleDeleteClick={disabled ? undefined : this.handleDeleteClick}
                    title={zone && zone.name ? `Zone ${zone.name}` : 'Zone'}
                  />
                </ElevationScroll>
                <div
                  className={classes.listWrapper}
                  ref={(node) => {
                    if (node) {
                      this.scrollTarget = node;
                    }
                  }}
                >
                  {zone ? (
                    <>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>General settings</ListSubheader>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Name',
                                index: 'name',
                              })
                              }
                            >
                              <ListItemText primary="Name" primaryTypographyProps={{ color }} secondary={zone.name} />
                            </ButtonBase>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Aggregation type',
                                index: 'aggregationType',
                              })
                              }
                            >
                              <ListItemText
                                primary="Aggregation type"
                                primaryTypographyProps={{ color }}
                                secondary={aggregationTypeText(zone.aggregationType)}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Sample period',
                                index: 'samplePeriod',
                              })
                              }
                            >
                              <ListItemText
                                primary="Sample period"
                                primaryTypographyProps={{ color }}
                                secondary={valueText(zone.samplePeriod, 0, 'sec')}
                              />
                            </ButtonBase>
                          </ListItem>
                        </div>
                      </List>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>Outputs</ListSubheader>
                          {zone.alarms.length !== 0 && (
                            <>
                              <ListItem>
                                {zone.alarms.slice(0, 2).map(item => (
                                  <ButtonBase
                                    className={classes.listItem}
                                    disabled={disabled}
                                    key={item.id.toString()}
                                    onClick={() => handleEditItemClick({
                                      index: 'alarms',
                                      indexId: item.id,
                                      index2: 'relays',
                                    })
                                    }
                                  >
                                    <ListItemText
                                      className={classes.listItem}
                                      disableTypography
                                      primary={<Typography color={color}>{`Alarm ${item.id}`}</Typography>}
                                      secondary={
                                        item.relays.length !== 0 ? (
                                          <Typography color="textSecondary" variant="body2">
                                            {item.relays.length === 1
                                              ? `Relay ${item.relays[0]}`
                                              : `Relays ${item.relays.join(', ')}`}
                                          </Typography>
                                        ) : (
                                          <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                            None
                                          </Typography>
                                        )
                                      }
                                    />
                                  </ButtonBase>
                                ))}
                              </ListItem>
                              <>
                                {zone.alarms.length > 2 && (
                                  <ListItem>
                                    {zone.alarms.slice(2).map(item => (
                                      <ButtonBase
                                        className={classes.listItem}
                                        disabled={disabled}
                                        key={item.id.toString()}
                                        onClick={() => handleEditItemClick({
                                          index: 'alarms',
                                          indexId: item.id,
                                          index2: 'relays',
                                        })
                                        }
                                      >
                                        <ListItemText
                                          className={classes.listItem}
                                          disableTypography
                                          primary={<Typography color={color}>{`Alarm ${item.id}`}</Typography>}
                                          secondary={
                                            item.relays.length !== 0 ? (
                                              <Typography color="textSecondary" variant="body2">
                                                {item.relays.length === 1
                                                  ? `Relay ${item.relays[0]}`
                                                  : `Relays ${item.relays.join(', ')}`}
                                              </Typography>
                                            ) : (
                                              <Typography
                                                className={classes.noValue}
                                                color="textSecondary"
                                                variant="body2"
                                              >
                                                None
                                              </Typography>
                                            )
                                          }
                                        />
                                      </ButtonBase>
                                    ))}
                                  </ListItem>
                                )}
                              </>
                            </>
                          )}
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ index: 'analogOuts' })}
                            >
                              <ListItemText
                                primary="Analogs"
                                primaryTypographyProps={{ color }}
                                secondary={
                                  zone.analogOuts.length !== 0 ? (
                                    <Typography color="textSecondary" variant="body2">
                                      {zone.analogOuts.length === 1
                                        ? `Analog out ${zone.analogOuts[0]}`
                                        : `Analog outs ${zone.analogOuts.join(', ')}`}
                                    </Typography>
                                  ) : (
                                    <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                      None
                                    </Typography>
                                  )
                                }
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Fault relay',
                                index: 'faultRelay',
                              })
                              }
                            >
                              <ListItemText
                                primary="Fault relay"
                                primaryTypographyProps={{ color }}
                                secondary={
                                  has.call(zone, 'faultRelay') && zone.faultRelay !== null ? (
                                    <Typography color="textSecondary" variant="body2">
                                      {`Relay ${zone.faultRelay}`}
                                    </Typography>
                                  ) : (
                                    <Typography className={classes.noValue} color="textSecondary" variant="body2">
                                      None
                                    </Typography>
                                  )
                                }
                              />
                            </ButtonBase>
                          </ListItem>
                        </div>
                      </List>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      <ButtonBase
                        className={classes.listButton}
                        disabled={disabled}
                        onClick={() => handleEditItemClick({ index: 'sensors' })}
                      >
                        <List className={classes.list}>
                          <div className={classes.listContent}>
                            <ListSubheader disableSticky>{`Sensors (${zone.sensors.length})`}</ListSubheader>
                            <ListItem className={clsx(classes.flexWrap, classes.alignItems)}>
                              {zone.sensors.length !== 0 ? (
                                <>
                                  <div className={clsx(classes.tableWrapper, classes.listItem)}>
                                    <Table size="small" stickyHeader>
                                      <TableBody>
                                        {sensorLists[0].map((item, index) => (
                                          <TableRow key={index.toString()}>
                                            {item ? (
                                              <TableCell className={classes.tableCell}>
                                                <Typography className={classes.tableText} color={color} variant="body2">
                                                  {`Sensor ${item.id}`}
                                                  <Separator />
                                                  <SensorTypeName name={item.sensorTypeName} />
                                                </Typography>
                                              </TableCell>
                                            ) : (
                                              <TableCell className={classes.tableCell} />
                                            )}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                      <TableFooter>
                                        <TableRow className={classes.tableFooter} />
                                      </TableFooter>
                                    </Table>
                                  </div>
                                  <div className={clsx(classes.tableWrapper, classes.listItem)}>
                                    <Table size="small" stickyHeader>
                                      <TableBody>
                                        {sensorLists[1].map((item, index) => (
                                          <TableRow key={index.toString()}>
                                            {item ? (
                                              <TableCell className={classes.tableCell}>
                                                <Typography className={classes.tableText} color={color} variant="body2">
                                                  {`Sensor ${item.id}`}
                                                  <Separator />
                                                  <SensorTypeName name={item.sensorTypeName} />
                                                </Typography>
                                              </TableCell>
                                            ) : (
                                              <TableCell className={classes.tableCell} />
                                            )}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                      <TableFooter>
                                        <TableRow className={classes.tableFooter} />
                                      </TableFooter>
                                    </Table>
                                  </div>
                                </>
                              ) : (
                                <Typography className={clsx(classes.noValue, classes.sensorsNone)}>None</Typography>
                              )}
                            </ListItem>
                          </div>
                        </List>
                      </ButtonBase>
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <Dialog open={open} onClose={this.handleDeleteCancelClick} PaperProps={{ className: classes.paper }}>
                <DialogTitle>Delete this zone?</DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleDeleteCancelClick}>Cancel</Button>
                  <Button color="secondary" onClick={this.handleDeleteConfirmClick}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </AbilityContext.Consumer>
    );
  }
}

ZonesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleEditItemClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  zone: PropTypes.object,
};

ZonesDetails.defaultProps = {
  zone: undefined,
};

export default withStyles(styles)(ZonesDetails);

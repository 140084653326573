import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './components/AppBar';
import ElevationScroll from './components/ElevationScroll';
import ListSubheader from './components/ListSubheader';
import { AbilityContext } from './utils/auth';
import has from './utils/has';
import {
  minimumTimeText, relayLatchingText, relayStateText, timeText,
} from './utils/utils';

const styles = theme => ({
  divider: {
    padding: theme.spacing(0, 2),
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listContent: {
    marginLeft: theme.spacing(5),
  },
  listItem: {
    width: '50%',
    textAlign: 'left',
  },
  listWrapper: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    overflow: 'auto',
    paddingBottom: theme.spacing(1.5),
  },
  noValue: {
    fontStyle: 'italic',
  },
  paper: {
    width: 352,
  },
  switch: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 60,
    width: '50%',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  warningText: {
    lineHeight: '1em',
    marginLeft: theme.spacing(2),
  },
  wrapper: {
    height: '100%',
  },
});

class RelaysDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.scrollTarget = undefined;

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteCancelClick() {
    this.setState({ open: false });
  }

  handleDeleteConfirmClick() {
    const { handleDeleteClick: handleDeleteClickProp, id } = this.props;

    handleDeleteClickProp(id);
    this.setState({ open: false });
  }

  render() {
    const {
      classes, handleBackClick, handleChangeItem, handleEditItemClick, id, relay,
    } = this.props;
    const { open } = this.state;

    return (
      <AbilityContext.Consumer>
        {(ability) => {
          const disabled = !ability.can('update', 'device');
          const color = disabled ? 'textSecondary' : 'initial';
          return (
            <>
              <div className={classes.wrapper}>
                <ElevationScroll target={this.scrollTarget}>
                  <AppBar
                    handleBackClick={handleBackClick}
                    handleDeleteClick={disabled ? undefined : this.handleDeleteClick}
                    title={`Relay ${id}`}
                  />
                </ElevationScroll>
                <div
                  className={classes.listWrapper}
                  ref={(node) => {
                    if (node) {
                      this.scrollTarget = node;
                    }
                  }}
                >
                  {relay ? (
                    <>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>General settings</ListSubheader>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({ index: 'zones' })}
                            >
                              <ListItemText
                                className={classes.listItem}
                                disableTypography
                                primary={(
                                  <Typography color={color}>
                                    {relay.config.zones.length > 1 ? 'Zones' : 'Zone'}
                                  </Typography>
                                )}
                                secondary={
                                  relay.config.zones.length !== 0 || relay.config.id === relay.settings.fault.relay ? (
                                    <Typography color="textSecondary" variant="body2">
                                      {relay.config.zones.map(value => value.name).join(', ')}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className={clsx(classes.noValue, classes.warning)}
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      None
                                    </Typography>
                                  )
                                }
                              />
                            </ButtonBase>
                            <span className={classes.switch}>
                              <Typography color={color}>Enabled</Typography>
                              <Switch
                                checked={relay.config.enabled}
                                color="secondary"
                                disabled={disabled}
                                onChange={event => handleChangeItem(event.target.checked, {
                                  index: 'enabled',
                                })
                                }
                              />
                            </span>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Device',
                                index: 'type',
                              })
                              }
                            >
                              <ListItemText
                                primary="Device"
                                primaryTypographyProps={{ color }}
                                secondary={relay.config.type}
                              />
                            </ButtonBase>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Normal state',
                                index: 'normalState',
                              })
                              }
                            >
                              <ListItemText
                                primary="Normal state"
                                primaryTypographyProps={{ color }}
                                secondary={relayStateText(relay.config.normalState)}
                              />
                            </ButtonBase>
                          </ListItem>
                          <ListItem>
                            <ButtonBase
                              className={classes.listItem}
                              disabled={disabled}
                              onClick={() => handleEditItemClick({
                                label: 'Type',
                                index: 'latching',
                              })
                              }
                            >
                              <ListItemText
                                primary="Type"
                                primaryTypographyProps={{ color }}
                                secondary={relayLatchingText(relay.config.latching)}
                              />
                            </ButtonBase>
                            {(
                              <ButtonBase
                                className={classes.listItem}
                                disabled={disabled}
                                onClick={() => handleEditItemClick({
                                  label: 'Minimum run time',
                                  index: 'minimumRunTime',
                                })
                                }
                              >
                                <ListItemText
                                  primary="Minimum run time"
                                  primaryTypographyProps={{ color }}
                                  secondary={minimumTimeText(relay.config.minimumRunTime)}
                                />
                              </ButtonBase>
                            )}
                          </ListItem>
                          {(
                            <ListItem>
                              <ButtonBase
                                className={classes.listItem}
                                disabled={disabled}
                                onClick={() => handleEditItemClick({
                                  label: 'Post run time',
                                  index: 'postRunTime',
                                })
                                }
                              >
                                <ListItemText
                                  primary="Post run time"
                                  primaryTypographyProps={{ color }}
                                  secondary={timeText(relay.config.postRunTime)}
                                />
                              </ButtonBase>
                            </ListItem>
                          )}
                        </div>
                      </List>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      <List className={classes.list}>
                        <div className={classes.listContent}>
                          <ListSubheader disableSticky>Communications</ListSubheader>
                          {has.call(relay.config, 'lane') ? (
                            <>
                              <ListItem>
                                <ButtonBase
                                  className={classes.listItem}
                                  disabled={disabled}
                                  onClick={() => handleEditItemClick({
                                    label: 'Modbus lane',
                                    index: 'lane',
                                  })
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItem}
                                    disableTypography
                                    primary={<Typography color={color}>Modbus lane</Typography>}
                                    secondary={(
                                      <Typography
                                        className={clsx(relay.config.duplicate && classes.warning)}
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        {relay.config.lane}
                                      </Typography>
                                    )}
                                  />
                                </ButtonBase>
                                <ButtonBase
                                  className={classes.listItem}
                                  disabled={disabled}
                                  onClick={() => handleEditItemClick({
                                    label: 'Modbus address',
                                    index: 'address',
                                  })
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItem}
                                    disableTypography
                                    primary={<Typography color={color}>Modbus address</Typography>}
                                    secondary={(
                                      <Typography
                                        className={clsx(relay.config.duplicate && classes.warning)}
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        {relay.config.address}
                                      </Typography>
                                    )}
                                  />
                                </ButtonBase>
                              </ListItem>
                              <ListItem>
                                <ButtonBase
                                  className={classes.listItem}
                                  disabled={disabled}
                                  onClick={() => handleEditItemClick({
                                    label: 'Modbus channel',
                                    index: 'channel',
                                  })
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItem}
                                    disableTypography
                                    primary={<Typography color={color}>Modbus channel</Typography>}
                                    secondary={(
                                      <Typography
                                        className={clsx(relay.config.duplicate && classes.warning)}
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        {relay.config.channel}
                                      </Typography>
                                    )}
                                  />
                                </ButtonBase>
                              </ListItem>
                              {relay.config.duplicate && (
                                <Typography className={clsx(classes.warning, classes.warningText)} variant="caption">
                                  * Duplicate modbus lane, address or channel
                                </Typography>
                              )}
                            </>
                          ) : (
                            <>
                              <ListItem>
                                <ButtonBase
                                  className={classes.listItem}
                                  disabled={disabled}
                                  onClick={() => handleEditItemClick({
                                    label: 'Local channel',
                                    index: 'channel',
                                    type: relay.config.type,
                                  })
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItem}
                                    disableTypography
                                    primary={<Typography color={color}>Local channel</Typography>}
                                    secondary={(
                                      <Typography
                                        className={clsx(relay.config.duplicate && classes.warning)}
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        {relay.config.channel}
                                      </Typography>
                                    )}
                                  />
                                </ButtonBase>
                              </ListItem>
                              {relay.config.duplicate && (
                                <Typography className={clsx(classes.warning, classes.warningText)} variant="caption">
                                  * Duplicate local channel
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                      </List>
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <Dialog open={open} onClose={this.handleDeleteCancelClick} PaperProps={{ className: classes.paper }}>
                <DialogTitle>Delete this relay?</DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleDeleteCancelClick}>Cancel</Button>
                  <Button color="secondary" onClick={this.handleDeleteConfirmClick}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </AbilityContext.Consumer>
    );
  }
}

RelaysDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleChangeItem: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleEditItemClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  relay: PropTypes.object,
};

RelaysDetails.defaultProps = {
  relay: undefined,
};

export default withStyles(styles)(RelaysDetails);

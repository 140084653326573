export const defaultDb = {
  analogOuts: [],
  analogTypes: [
    {
      fullScale: 10,
      id: 1,
      units: 'V',
      zeroScale: 0,
    },
    {
      fullScale: 20,
      id: 2,
      units: 'mA',
      zeroScale: 4,
    },
    {
      fullScale: 10,
      id: 3,
      units: 'V',
      zeroScale: 2,
    },
    {
      fullScale: 20,
      id: 4,
      units: 'mA',
      zeroScale: 0,
    },
  ],
  lanes: [
    {
      baud: 9600,
      bytesize: 8,
      device: '/dev/ttymxc3',
      id: 1,
      parity: 'E',
      stopbits: 1,
    },
    {
      baud: 9600,
      bytesize: 8,
      device: '/dev/ttymxc1',
      id: 2,
      parity: 'E',
      stopbits: 1,
    },
    {
      baud: 9600,
      bytesize: 8,
      device: '/dev/ttymxc0',
      id: 3,
      parity: 'E',
      stopbits: 1,
    },
    {
      baud: 9600,
      bytesize: 8,
      device: '/dev/ttymxc2',
      id: 4,
      parity: 'E',
      stopbits: 1,
    },
  ],
  relays: [],
  roles: [
    {
      ability: [
        {
          actions: 'view',
          subject: 'lock',
        },
        {
          actions: 'view',
          subject: 'calibration',
        },
        {
          actions: 'update',
          subject: 'time',
        },
        {
          actions: 'create',
          subject: 'device',
        },
        {
          actions: 'delete',
          subject: 'device',
        },
        {
          actions: 'update',
          subject: 'device',
        },
        {
          actions: 'create',
          subject: 'sensorType',
        },
        {
          actions: 'delete',
          subject: 'sensorType',
        },
        {
          actions: 'update',
          subject: 'sensorType',
        },
        {
          actions: 'update',
          subject: 'settings',
        },
        {
          actions: 'create',
          subject: 'zone',
        },
        {
          actions: 'delete',
          subject: 'zone',
        },
        {
          actions: 'update',
          subject: 'zone',
        },
      ],
      id: 'customer',
    },
    {
      ability: [
        {
          actions: 'view',
          subject: 'lock',
        },
        {
          actions: 'view',
          subject: 'calibration',
        },
        {
          actions: 'update',
          subject: 'time',
        },
        {
          actions: 'create',
          subject: 'device',
        },
        {
          actions: 'delete',
          subject: 'device',
        },
        {
          actions: 'update',
          subject: 'device',
        },
        {
          actions: 'create',
          subject: 'sensorType',
        },
        {
          actions: 'delete',
          subject: 'sensorType',
        },
        {
          actions: 'update',
          subject: 'sensorType',
        },
        {
          actions: 'update',
          subject: 'settings',
        },
        {
          actions: 'create',
          subject: 'zone',
        },
        {
          actions: 'delete',
          subject: 'zone',
        },
        {
          actions: 'update',
          subject: 'zone',
        },
      ],
      id: 'service',
    },
  ],
  sensorTypes: [
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 25.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 100.0,
        },
      ],
      amc400Id: 0,
      description: 'Carbon monoxide',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 1,
      name: 'CO',
      units: 'ppm',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 3.0,
        },
      ],
      amc400Id: 1,
      description: 'Nitrogen dioxide',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 2,
      name: 'NO_2',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 20.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 40.0,
        },
        {
          delay: 0,
          id: 3,
          setPoint: 85.0
        }
      ],
      amc400Id: 2,
      description: 'Methane',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 3,
      name: 'CH_4',
      units: '%LEL',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 20.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 40.0,
        },
      ],
      amc400Id: 3,
      description: 'Propane',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 4,
      name: 'C_3H_8',
      units: '%LEL',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 20.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 40.0,
        },
      ],
      amc400Id: 9,
      description: 'Hydrogen',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 5,
      name: 'H_2',
      units: '%LEL',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.625,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 10.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 15.0,
        },
      ],
      amc400Id: 4,
      description: 'Hydrogen sulfide',
      faultLimit: -3.125,
      fullScale: 25.0,
      id: 6,
      name: 'H_2S',
      units: 'ppm',
      zeroBuffer: 0.5,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.625,
      alarmType: 'decreasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 19.5,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 17.0,
        },
      ],
      amc400Id: 7,
      description: 'Oxygen',
      faultLimit: -3.125,
      fullScale: 25.0,
      id: 7,
      name: 'O_2',
      units: '%vol',
      zeroBuffer: 0.5,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.125,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.5,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 1.0,
        },
      ],
      amc400Id: 6,
      description: 'Chlorine',
      faultLimit: -0.625,
      fullScale: 5.0,
      id: 8,
      name: 'Cl_2',
      units: 'ppm',
      zeroBuffer: 0.1,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 25.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 35.0,
        },
      ],
      amc400Id: 5,
      description: 'Ammonia',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 9,
      name: 'NH_3',
      units: 'ppm',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 125.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1000.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 5000.0,
        },
      ],
      amc400Id: 13,
      description: 'Carbon dioxide',
      faultLimit: -625.0,
      fullScale: 5000.0,
      id: 10,
      name: 'CO_2',
      units: 'ppm',
      zeroBuffer: 100.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 20.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 40.0,
        },
      ],
      amc400Id: 14,
      description: 'Acetylene',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 11,
      name: 'C_2H_2',
      units: '%LEL',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 20.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 40.0,
        },
      ],
      amc400Id: 11,
      description: 'Combustible (general)',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 12,
      name: 'Exp',
      units: '%LEL',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 200.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 500.0,
        },
      ],
      amc400Id: 8,
      description: 'Fuel vapour',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 13,
      name: 'Fuel',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [],
      description: 'Relative humidity',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 14,
      name: 'RelHu',
      units: '%RH',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 30.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 100.0,
        },
      ],
      description: 'Pressure',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 15,
      name: 'Press',
      units: 'psi',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [],
      description: 'Differential pressure',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 16,
      name: 'DiffP',
      units: '" WC',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 30.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 100.0,
        },
      ],
      description: 'Vacuum',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 17,
      name: 'Vac',
      units: 'psi',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [],
      amc400Id: 21,
      description: 'Ambient temperature',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 18,
      name: 'AmbT',
      units: 'DegC',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 2.5,
      alarmType: 'increasing',
      alarms: [],
      description: 'Surface temperature',
      faultLimit: -12.5,
      fullScale: 100.0,
      id: 19,
      name: 'SurfT',
      units: 'DegC',
      zeroBuffer: 2.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.025,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.1,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 0.3,
        },
      ],
      description: 'Chlorine dioxide',
      faultLimit: -0.125,
      fullScale: 1.0,
      id: 20,
      name: 'ClO_2',
      units: 'ppm',
      zeroBuffer: 0.02,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 5.0,
        },
      ],
      description: 'Ethylene oxide (ETO)',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 21,
      name: 'C_2H_4O',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 2.0,
        },
      ],
      description: 'Fluorine',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 22,
      name: 'F_2',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.025,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.2,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 0.5,
        },
      ],
      description: 'Germane',
      faultLimit: -0.125,
      fullScale: 1.0,
      id: 23,
      name: 'GeH_4',
      units: 'ppm',
      zeroBuffer: 0.02,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 4.0,
        },
      ],
      amc400Id: 17,
      description: 'Hydrogen chloride',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 24,
      name: 'HCl',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.5,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 2.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 4.8,
        },
      ],
      amc400Id: 18,
      description: 'Hydrogen cyanide',
      faultLimit: -2.5,
      fullScale: 20.0,
      id: 25,
      name: 'HCN',
      units: 'ppm',
      zeroBuffer: 0.4,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.125,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.5,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 2.0,
        },
      ],
      description: 'Hydrogen floride',
      faultLimit: -0.625,
      fullScale: 5.0,
      id: 26,
      name: 'HF',
      units: 'ppm',
      zeroBuffer: 0.1,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.125,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.5,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 5.0,
        },
      ],
      description: 'Mercaptan',
      faultLimit: -0.625,
      fullScale: 5.0,
      id: 27,
      name: 'MERCA',
      units: 'ppm',
      zeroBuffer: 0.1,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 1.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 25.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 30.0,
        },
      ],
      amc400Id: 20,
      description: 'Nitrogen oxide',
      faultLimit: -6.25,
      fullScale: 50.0,
      id: 28,
      name: 'NO',
      units: 'ppm',
      zeroBuffer: 1.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 50.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 1000.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 2000.0,
        },
      ],
      description: 'Nitrous oxide',
      faultLimit: -250.0,
      fullScale: 2000.0,
      id: 29,
      name: 'N_2O',
      units: 'ppm',
      zeroBuffer: 40.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.025,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.1,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 0.3,
        },
      ],
      amc400Id: 19,
      description: 'Ozone',
      faultLimit: -0.125,
      fullScale: 1.0,
      id: 30,
      name: 'O_3',
      units: 'ppm',
      zeroBuffer: 0.02,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.025,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.1,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 0.2,
        },
      ],
      description: 'Phosgene',
      faultLimit: -0.125,
      fullScale: 1.0,
      id: 31,
      name: 'COCl_2',
      units: 'ppm',
      zeroBuffer: 0.02,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.025,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 0.1,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 0.3,
        },
      ],
      description: 'Phosphine',
      faultLimit: -0.125,
      fullScale: 1.0,
      id: 32,
      name: 'PH_3',
      units: 'ppm',
      zeroBuffer: 0.02,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 5.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 10.0,
        },
      ],
      description: 'Silane',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 33,
      name: 'SiH_4',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 0.25,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 2.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 5.0,
        },
      ],
      amc400Id: 15,
      description: 'Sulfer dioxide',
      faultLimit: -1.25,
      fullScale: 10.0,
      id: 34,
      name: 'SO_2',
      units: 'ppm',
      zeroBuffer: 0.2,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 100.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 200.0,
        },
      ],
      description: 'Volatile organic compound (general)',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 35,
      name: 'VOC',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R22',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 36,
      name: 'R22',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 25.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 50.0,
        },
      ],
      description: 'Refrigerant R123',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 37,
      name: 'R123',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R134a',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 38,
      name: 'R134a',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R410a',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 39,
      name: 'R410a',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R404a',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 40,
      name: 'R404a',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R407a',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 41,
      name: 'R407a',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R125',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 42,
      name: 'R125',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant R507a',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 43,
      name: 'R507a',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant SF6',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 44,
      name: 'SF_6',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
    {
      alarmHysteresis: 25.0,
      alarmType: 'increasing',
      alarms: [
        {
          delay: 0,
          id: 1,
          setPoint: 400.0,
        },
        {
          delay: 0,
          id: 2,
          setPoint: 800.0,
        },
      ],
      description: 'Refrigerant 1234yf',
      faultLimit: -125.0,
      fullScale: 1000.0,
      id: 45,
      name: '1234yf',
      units: 'ppm',
      zeroBuffer: 20.0,
      zeroScale: 0.0,
    },
  ],
  sensors: [],
  settings: {
    bacnet: {
      deviceId: 677103,
      deviceName: 'AMC-1DCx Monitor',
      port: 47808,
    },
    displayCalibration: true,
    fault: {
      delay: 10,
      relay: null,
    },
    network: {
      address: '',
      gateway: '',
      method: 'auto',
    },
    defaultTimeZone: 'UTC',
    powerUpAlarmDelay: 60,
    siteName: null,
  },
  zones: [],
};

export const defaultAnalogOut = {
  address: null,
  enabled: true,
  id: null,
  lane: null,
  latching: false,
  minimumRunTime: 0,
  normalState: null,
  postRunTime: 0,
  type: null,
  zone: null,
};

export const defaultRelay = {
  address: null,
  enabled: true,
  id: null,
  lane: null,
  latching: false,
  minimumRunTime: 0,
  normalState: null,
  postRunTime: 0,
  type: null,
  zones: [],
};

export const defaultSensor = {
  address: null,
  alarmPoints: null,
  alarms: [],
  channel: undefined,
  enabled: true,
  id: null,
  lane: null,
  sensorType: null,
  sensorTypeId: null,
  type: null,
  zone: null,
};

export const defaultSensorType = {
  alarmHysteresis: 0,
  alarmType: 'increasing',
  alarms: [],
  amc400Id: null,
  custom: true,
  description: null,
  faultLimit: 0.0,
  fullScale: null,
  id: null,
  name: null,
  sensorCount: 0,
  units: null,
  zeroBuffer: 2.0,
  zeroScale: 0,
};

export const defaultZone = {
  aggregationType: null,
  alarms: [],
  analogOuts: [],
  faultRelay: null,
  id: null,
  name: null,
  samplePeriod: null,
  sensorIds: [],
  sensors: [],
};

export function sortIds(arr) {
  arr.sort((a, b) => (a.id < b.id ? -1 : 1));
}

export function newId(arr) {
  return arr.reduce((acc, item) => (acc === item.id ? acc + 1 : acc), 1);
}

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import { patternNumber } from '../utils/patterns';
import { themeLight } from '../utils/theme';

const styles = {
  paper: {
    width: 416,
  },
};

class AddFabDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addCount: '1',
    };

    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleConfirmClick() {
    const { handleConfirmClick: handleConfirmClickProp } = this.props;
    const { addCount } = this.state;

    handleConfirmClickProp(Number(addCount));
  }

  handleChange(event) {
    const { value } = event.target;

    if (value.length === 0 || patternNumber.test(value)) {
      this.setState({ addCount: value });
    }
  }

  handleKeyDown(event) {
    const { open } = this.props;

    if (open) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.handleConfirmClick();
      }
    }
  }

  render() {
    const {
      classes, handleCancelClick, open, type,
    } = this.props;
    const { addCount } = this.state;

    return (
      <>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <ThemeProvider theme={themeLight}>
          <Dialog open={open} onClose={handleCancelClick} PaperProps={{ className: classes.paper }}>
            <DialogTitle>{`Create ${type}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>{`Please enter the number of ${type} to create.`}</DialogContentText>
              <TextField
                autoComplete="off"
                autoFocus
                color="secondary"
                fullWidth
                id="name"
                label={`Number of ${type}`}
                margin="dense"
                onChange={this.handleChange}
                value={addCount}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button color="secondary" onClick={this.handleConfirmClick}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

AddFabDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(AddFabDialog);

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import AnalogOuts from './AnalogOuts';
import AnalogOutsAdd from './AnalogOutsAdd';
import AnalogOutsList from './AnalogOutsList';
import CommunicationsList from './CommunicationsList';
import FadeSlideReplace from './components/FadeSlideReplace';
import { SelectInputDark } from './components/SelectInput';
import SensorTypeName from './components/SensorTypeName';
import SidebarNav from './components/SidebarNav';
import SpacedText from './components/SpacedText';
import Title from './components/Title';
import Relays from './Relays';
import RelaysAdd from './RelaysAdd';
import RelaysList from './RelaysList';
import Sensors from './Sensors';
import SensorsAdd from './SensorsAdd';
import SensorsList from './SensorsList';
import Settings from './Settings';
import UploadDialog from './UploadDialog';
import {
  apiDownloadConfig,
  apiGetAnalogOut,
  apiGetAnalogOuts,
  apiGetFileName,
  apiGetRelay,
  apiGetRelays,
  apiGetSensor,
  apiGetSensors,
  apiGetSettings,
  apiGetZone,
  apiGetZones,
  apiSetFileName,
  apiUploadConfig,
  apiGetModbus,
} from './utils/api';
import { AbilityContext, getAbility } from './utils/auth';
import { darkPrimary } from './utils/colors';
import has from './utils/has';
import history from './utils/history';
import { optionsLaneFilter, optionsSensorType } from './utils/options';
import { iconSize } from './utils/styles';
import { themeDark, themeSelect } from './utils/theme';
import Zones from './Zones';
import ZonesAdd from './ZonesAdd';
import ZonesList from './ZonesList';

const drawerWidth = 240;

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacing: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  counts: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 0.5,
  },
  drawerPaper: {
    backgroundColor: darkPrimary,
    borderRightColor: darkPrimary,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  formDevices: {
    minWidth: 138,
  },
  formLanes: {
    minWidth: 84,
  },
  formSensorTypes: {
    minWidth: 96,
  },
  formZone: {
    minWidth: 92,
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  iconSize,
  listContainer: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
    marginLeft: theme.spacing(-2),
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuItem: {
    width: 132,
  },
  root: {
    display: 'flex',
  },
  select: {
    fontSize: '16px',
    lineHeight: '22px',
    padding: theme.spacing(0, 2),
  },
  spacing: {
    marginRight: theme.spacing(1.5),
  },
  spacingForm: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: theme.spacing(3), // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
});

class Container extends React.Component {
  static addLayout(index) {
    return [13, 15, 17, 19].includes(index);
  }

  static appBarText(classes, text1, text2) {
    return (
      <SpacedText
        text1={text1}
        text2={(
          <Typography className={classes.counts} component="span">
            {text2}
          </Typography>
        )}
      />
    );
  }

  static detailsLayout(index) {
    return [3, 5, 7, 9, 10, 12].includes(index);
  }

  constructor(props) {
    super(props);

    this.state = {
      ability: getAbility(),
      addCount: null,
      analogOuts: [],
      commDeviceFilter: '',
      commLaneFilter: '',
      commZoneFilter: '',
      deviceFilter: '',
      fileName: 'monitor.json',
      id: null,
      index: undefined,
      laneFilter: '',
      listItem: undefined,
      open: false,
      openUpload: false,
      relays: [],
      sensors: [],
      sensorTypeFilter: '',
      settings: undefined,
      zoneFilter: '',
      zones: [],
      lanes: [],
    };

    this.devices = [];
    this.optionsSensorTypes = new Set();
    this.scrollTop = {};

    this.getData = this.getData.bind(this);
    this.getScrollTop = this.getScrollTop.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleCommunicationsClick = this.handleCommunicationsClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleSaveScrollTop = this.handleSaveScrollTop.bind(this);
    this.handleSelectCommDeviceChange = this.handleSelectCommDeviceChange.bind(this);
    this.handleSelectCommLaneChange = this.handleSelectCommLaneChange.bind(this);
    this.handleSelectCommZoneChange = this.handleSelectCommZoneChange.bind(this);
    this.handleSelectDeviceChange = this.handleSelectDeviceChange.bind(this);
    this.handleSelectLaneChange = this.handleSelectLaneChange.bind(this);
    this.handleSelectSensorTypeChange = this.handleSelectSensorTypeChange.bind(this);
    this.handleSelectZoneChange = this.handleSelectZoneChange.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handleUploadClose = this.handleUploadClose.bind(this);
    this.handleUploadConfirmClick = this.handleUploadConfirmClick.bind(this);
    this.setDevices = this.setDevices.bind(this);

    history.defaultState({ addCount: null, id: null, index: 2 });
  }

  componentDidMount() {
    this.mounted = true;

    apiGetFileName().then((reply) => {
      if (this.mounted) {
        if (reply && reply.fileName !== null) {
          this.setState({ fileName: reply.fileName });
        }
      }
    });

    history.addListener(this.handleLocationChanged);
    this.getData();
  }

  componentWillUnmount() {
    this.mounted = false;

    history.removeListener(this.handleLocationChanged);
  }

  getData() {
    apiGetSensors().then((sensors) => {
      if (this.mounted) {
        this.setState({ sensors }, () => {
          sensors.forEach((item) => {
            this.optionsSensorTypes.add(item.sensorType.name);
          });
          this.setDevices();
        });
      }
    });
    apiGetRelays().then((relays) => {
      if (this.mounted) {
        this.setState({ relays }, () => {
          this.setDevices();
        });
      }
    });
    apiGetAnalogOuts().then((analogOuts) => {
      if (this.mounted) {
        this.setState({ analogOuts }, () => {
          this.setDevices();
        });
      }
    });
    apiGetZones().then((zones) => {
      if (this.mounted) {
        this.setState({ zones });
      }
    });
    apiGetSettings().then((settings) => {
      if (this.mounted) {
        this.setState({ settings });
      }
    });
    apiGetModbus().then((lanes) => {
      if (this.mounted) {
        this.setState({ lanes });
      }
    });
  }

  getScrollTop(key) {
    return (arg) => {
      if (arg) {
        return this.getScrollTop(`${key}-${arg}`);
      }
      return this.scrollTop[key] || 0;
    };
  }

  setDevices() {
    const { analogOuts, relays, sensors } = this.state;

    this.devices = [];

    sensors.forEach((sensor) => {
      const device = {};
      device.address = sensor.address;
      device.duplicate = sensor.duplicate;
      device.instance = { id: sensor.id, type: 'sensor' };
      device.lane = sensor.lane;
      device.type = sensor.type;
      device.zone = sensor.zone;
      if (has.call(sensor, 'channel')) {
        device.channel = sensor.channel;
      }
      this.devices.push(device);
    });
    relays.forEach((relay) => {
      const device = {};
      if (has.call(relay, 'address')) {
        device.address = relay.address;
      }
      device.duplicate = relay.duplicate;
      device.instance = { id: relay.id, type: 'relay' };
      if (has.call(relay, 'lane')) {
        device.lane = relay.lane;
      }
      device.type = relay.type;
      device.zones = relay.zones;
      device.settings = relay.settings;
      if (has.call(relay, 'channel')) {
        device.channel = relay.channel;
      }
      this.devices.push(device);
    });
    analogOuts.forEach((analogOut) => {
      const device = {};
      device.address = analogOut.address;
      device.duplicate = analogOut.duplicate;
      device.instance = { id: analogOut.id, type: 'analogOut' };
      device.lane = analogOut.lane;
      device.type = analogOut.type;
      device.zone = analogOut.zone;
      if (has.call(analogOut, 'channel')) {
        device.channel = analogOut.channel;
      }
      this.devices.push(device);
    });

    this.devices.sort((a, b) => {
      if (a.lane !== b.lane) {
        return a.lane < b.lane ? -1 : 1;
      }
      if (a.address !== b.address) {
        return a.address < b.address ? -1 : 1;
      }
      return a.channel < b.channel ? -1 : 1;
    });
  }

  handleBackClick() {
    history.goBack();
    this.getData();
  }

  handleCommunicationsClick() {
    this.setState({ commDeviceFilter: '', commLaneFilter: '', commZoneFilter: '' });
    this.handleSaveScrollTop('communicationsList')(0);
    history.push({ index: 10 });
  }

  handleDownloadClick() {
    apiDownloadConfig().then((config) => {
      if (this.mounted) {
        const { fileName } = this.state;
        const blob = new Blob([JSON.stringify(config, null, 2)], { type: 'application/json' });
        saveAs(blob, fileName);
      }
    });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleLocationChanged(value) {
    const { addCount, id, index } = value;

    const getListItem = () => {
      if (index === 3) return apiGetSensor(id);
      if (index === 5) return apiGetRelay(id);
      if (index === 7) return apiGetAnalogOut(id);
      if (index === 9) return apiGetZone(id);
      return Promise.resolve();
    };

    if (id && index) {
      getListItem().then((listItem) => {
        if (this.mounted) {
          if (listItem) {
            this.setState({ id, index, listItem });
          } else {
            history.goBack();
          }
        }
      });
    } else if (index === 12) {
      apiGetSettings().then((settings) => {
        if (this.mounted) {
          this.setState({ index, settings });
        }
      });
    } else {
      this.getData();
      this.setState({ addCount, id, index });
    }
  }

  handleSaveScrollTop(key) {
    return (arg) => {
      if (typeof arg === 'string') {
        return this.handleSaveScrollTop(`${key}-${arg}`);
      }
      this.scrollTop[key] = arg;
      return null;
    };
  }

  handleSelectCommDeviceChange(event) {
    const { value } = event.target;

    this.setState({ commDeviceFilter: value });
  }

  handleSelectCommLaneChange(event) {
    const { value } = event.target;

    this.setState({ commLaneFilter: value });
  }

  handleSelectCommZoneChange(event) {
    const { value } = event.target;

    this.setState({ commZoneFilter: value });
  }

  handleSelectDeviceChange(event) {
    const { value } = event.target;

    this.setState({ deviceFilter: value });
  }

  handleSelectLaneChange(event) {
    const { value } = event.target;

    this.setState({ laneFilter: value });
  }

  handleSelectSensorTypeChange(event) {
    const { value } = event.target;

    this.setState({ sensorTypeFilter: value });
  }

  handleSelectZoneChange(event) {
    const { value } = event.target;

    this.setState({ zoneFilter: value });
  }

  handleUploadClick() {
    this.setState({ openUpload: true });
  }

  handleUploadClose() {
    this.setState({ openUpload: false });
  }

  handleUploadConfirmClick(config, fileName) {
    return apiUploadConfig(config).then(() => {
      if (this.mounted) {
        this.setState({ fileName });
        apiSetFileName({ fileName });
        this.getData();
      }
      return Promise.resolve();
    });
  }

  render() {
    const { classes } = this.props;
    const {
      ability,
      addCount: addCountState,
      analogOuts,
      commLaneFilter,
      commDeviceFilter,
      commZoneFilter,
      deviceFilter,
      id: idState,
      index,
      laneFilter,
      listItem,
      open,
      openUpload,
      relays,
      sensors,
      sensorTypeFilter,
      settings,
      zoneFilter,
      zones,
      lanes,
    } = this.state;

    if (index === undefined) return null;

    if (Container.detailsLayout(index)) {
      return (
        <AbilityContext.Provider value={ability}>
          <FadeSlideReplace transitionKey="details">
            <div className={classes.root}>
              <div className={classes.content}>
                {index === 3 && <Sensors handleBackClick={this.handleBackClick} id={idState} sensor={listItem} />}
                {index === 5 && <Relays handleBackClick={this.handleBackClick} id={idState} relay={listItem} />}
                {index === 7 && <AnalogOuts handleBackClick={this.handleBackClick} id={idState} analogOut={listItem} />}
                {index === 9 && <Zones handleBackClick={this.handleBackClick} id={idState} zone={listItem} />}
                {index === 10 && (
                  <CommunicationsList
                    deviceFilter={commDeviceFilter}
                    devices={this.devices}
                    handleBackClick={this.handleBackClick}
                    handleListItemClick={(item) => {
                      const { id, type } = item.instance;

                      if (type === 'sensor') {
                        history.push({ id, index: 3 });
                      } else if (type === 'relay') {
                        history.push({ id, index: 5 });
                      } else if (type === 'analogOut') {
                        history.push({ id, index: 7 });
                      }
                    }}
                    handleSaveScrollTop={this.handleSaveScrollTop('communicationsList')}
                    handleSelectDeviceChange={this.handleSelectCommDeviceChange}
                    handleSelectLaneChange={this.handleSelectCommLaneChange}
                    handleSelectZoneChange={this.handleSelectCommZoneChange}
                    initialScrollTop={this.getScrollTop('communicationsList')()}
                    laneFilter={commLaneFilter}
                    settings={settings}
                    zoneFilter={commZoneFilter}
                    zones={zones}
                  />
                )}
                {index === 12 && (
                  <Settings
                    getScrollTop={this.getScrollTop('settings')}
                    handleBackClick={this.handleBackClick}
                    handleSaveScrollTop={this.handleSaveScrollTop('settings')}
                    settings={settings}
                    lanes={lanes}
                  />
                )}
              </div>
            </div>
          </FadeSlideReplace>
        </AbilityContext.Provider>
      );
    }

    if (Container.addLayout(index)) {
      return (
        <AbilityContext.Provider value={ability}>
          <FadeSlideReplace transitionKey="details">
            <div className={classes.root}>
              <div className={classes.content}>
                {index === 13 && (
                  <SensorsAdd
                    addCount={addCountState}
                    handleCloseClick={() => {
                      history.goBack();
                    }}
                    handleSaveClick={(id) => {
                      this.handleSaveScrollTop('sensorsList')(-1);
                      if (addCountState === 1) {
                        history.replace({ id, index: 3 });
                      } else {
                        history.replace({ index: 2 });
                      }
                    }}
                  />
                )}
                {index === 15 && (
                  <RelaysAdd
                    addCount={addCountState}
                    handleCloseClick={() => {
                      history.goBack();
                    }}
                    handleSaveClick={(id) => {
                      this.handleSaveScrollTop('relaysList')(-1);
                      if (addCountState === 1) {
                        history.replace({ id, index: 5 });
                      } else {
                        history.replace({ index: 4 });
                      }
                    }}
                  />
                )}
                {index === 17 && (
                  <AnalogOutsAdd
                    addCount={addCountState}
                    handleCloseClick={() => {
                      history.goBack();
                    }}
                    handleSaveClick={(id) => {
                      this.handleSaveScrollTop('analogOutsList')(-1);
                      if (addCountState === 1) {
                        history.replace({ id, index: 7 });
                      } else {
                        history.replace({ index: 6 });
                      }
                    }}
                  />
                )}
                {index === 19 && (
                  <ZonesAdd
                    handleCloseClick={() => {
                      history.goBack();
                    }}
                    handleSaveClick={(id) => {
                      this.handleSaveScrollTop('zonesList')(-1);
                      history.replace({ id, index: 9 });
                    }}
                    zones={zones}
                  />
                )}
              </div>
            </div>
          </FadeSlideReplace>
        </AbilityContext.Provider>
      );
    }

    let filtered = [];
    if (index === 2) {
      filtered = sensors
        .filter(item => zoneFilter === '' || (item.zone !== null && item.zone.name === zoneFilter))
        .filter(item => laneFilter === '' || item.lane === laneFilter)
        .filter(item => deviceFilter === '' || item.type === deviceFilter)
        .filter(item => sensorTypeFilter === '' || item.sensorType.name === sensorTypeFilter);
    } else if (index === 4) {
      filtered = relays
        .filter(item => zoneFilter === '' || item.zones.map(value => value.name).includes(zoneFilter))
        .filter(
          item => laneFilter === '' || item.lane === laneFilter || (!has.call(item, 'lane') && laneFilter === 'Local'),
        );
    } else if (index === 6) {
      filtered = analogOuts
        .filter(item => zoneFilter === '' || (item.zone !== null && item.zone.name === zoneFilter))
        .filter(item => laneFilter === '' || item.lane === laneFilter);
    } else if (index === 8) {
      filtered = zones.filter(item => zoneFilter === '' || item.name === zoneFilter);
    }

    return (
      <AbilityContext.Provider value={ability}>
        <FadeSlideReplace transitionKey="list">
          <ThemeProvider theme={themeDark}>
            <CssBaseline />
            <Title
              title={settings && settings.siteName ? `Site - ${settings.siteName}` : 'Monitor Configuration Tool'}
            />
            <div className={classes.root}>
              <AppBar className={clsx(classes.appBar, open && classes.appBarShift)} elevation={0} position="absolute">
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    color="inherit"
                    edge="start"
                    onClick={this.handleDrawerOpen}
                  >
                    <MenuIcon className={classes.iconSize} />
                  </IconButton>
                  <Typography className={classes.title} color="inherit" component="h1" noWrap variant="h6">
                    {(index === 2
                      && Container.appBarText(
                        classes,
                        'Sensors',
                        zoneFilter || laneFilter || sensorTypeFilter || deviceFilter
                          ? `(${filtered.length} of ${sensors.length})`
                          : `(${filtered.length})`,
                      ))
                      || (index === 4
                        && Container.appBarText(
                          classes,
                          'Relays',
                          zoneFilter || laneFilter
                            ? `(${filtered.length} of ${relays.length})`
                            : `(${filtered.length})`,
                        ))
                      || (index === 6
                        && Container.appBarText(
                          classes,
                          'Analog outputs',
                          zoneFilter || laneFilter
                            ? `(${filtered.length} of ${analogOuts.length})`
                            : `(${filtered.length})`,
                        ))
                      || (index === 8
                        && Container.appBarText(
                          classes,
                          'Zones',
                          zoneFilter ? `(${filtered.length} of ${zones.length})` : `(${filtered.length})`,
                        ))
                      || (index === 12 && 'Settings')}
                  </Typography>
                  {index === 2 && (
                    <>
                      <FormControl className={clsx(classes.formSensorTypes, classes.spacingForm)} variant="outlined">
                        <ThemeProvider theme={themeSelect}>
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            input={<SelectInputDark />}
                            onChange={this.handleSelectSensorTypeChange}
                            renderValue={value => (
                              <Typography className={classes.select}>
                                <SensorTypeName name={value} />
                              </Typography>
                            )}
                            value={sensorTypeFilter}
                          >
                            <MenuItem value="">
                              <em>All types</em>
                            </MenuItem>
                            {Array.from(this.optionsSensorTypes)
                              .sort()
                              .map(item => (
                                <MenuItem key={item} value={item}>
                                  <SensorTypeName name={item} />
                                </MenuItem>
                              ))}
                          </Select>
                        </ThemeProvider>
                      </FormControl>
                      <FormControl className={clsx(classes.formDevices, classes.spacingForm)} variant="outlined">
                        <ThemeProvider theme={themeSelect}>
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            input={<SelectInputDark />}
                            onChange={this.handleSelectDeviceChange}
                            renderValue={value => <Typography className={classes.select}>{value}</Typography>}
                            value={deviceFilter}
                          >
                            <MenuItem value="">
                              <em>All devices</em>
                            </MenuItem>
                            {optionsSensorType.map(item => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </ThemeProvider>
                      </FormControl>
                    </>
                  )}
                  {[2, 4, 6].includes(index) && (
                    <FormControl className={clsx(classes.formLanes, classes.spacingForm)} variant="outlined">
                      <ThemeProvider theme={themeSelect}>
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          input={<SelectInputDark />}
                          onChange={this.handleSelectLaneChange}
                          renderValue={value => <Typography className={classes.select}>{value}</Typography>}
                          value={laneFilter}
                        >
                          <MenuItem value="">
                            <em>All lanes</em>
                          </MenuItem>
                          {optionsLaneFilter.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </ThemeProvider>
                    </FormControl>
                  )}
                  {[2, 4, 6, 8].includes(index) && (
                    <FormControl className={classes.formZone} variant="outlined">
                      <ThemeProvider theme={themeSelect}>
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          input={<SelectInputDark />}
                          onChange={this.handleSelectZoneChange}
                          renderValue={value => (
                            <Typography className={classes.select} variant="body2">
                              {value}
                            </Typography>
                          )}
                          value={zoneFilter}
                        >
                          <MenuItem value="">
                            <em>All zones</em>
                          </MenuItem>
                          {zones.map(item => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </ThemeProvider>
                    </FormControl>
                  )}
                </Toolbar>
              </AppBar>
              <Drawer
                classes={{
                  paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
                variant="permanent"
              >
                <div className={classes.toolbarIcon}>
                  <IconButton onClick={this.handleDrawerClose}>
                    <ChevronLeftIcon className={classes.iconSize} />
                  </IconButton>
                </div>
                <SidebarNav
                  handleAnalogOutsClick={() => {
                    history.replace({ index: 6 });
                  }}
                  handleCommunicationsClick={this.handleCommunicationsClick}
                  handleDownloadClick={this.handleDownloadClick}
                  handleRelaysClick={() => {
                    history.replace({ index: 4 });
                  }}
                  handleSensorsClick={() => {
                    history.replace({ index: 2 });
                  }}
                  handleSettingsClick={() => {
                    this.handleSaveScrollTop('settings')(0);
                    history.push({ index: 12 });
                  }}
                  handleUploadClick={this.handleUploadClick}
                  handleZonesClick={() => {
                    history.replace({ index: 8 });
                  }}
                  index={index}
                  warnings={{
                    devices: this.devices.some(item => item.duplicate),
                    sensors: sensors.some(item => item.zone === null),
                    relays: relays.some(
                      item => item.zones.length === 0 && !(settings && settings.fault.relay === item.id),
                    ),
                    analogOuts: analogOuts.some(item => item.zone === null),
                    zones: zones.some(item => item.sensorIds.length === 0),
                  }}
                />
              </Drawer>
              <FadeSlideReplace transitionKey={index.toString()}>
                <div className={classes.content}>
                  <div className={classes.appBarSpacing} />
                  <div className={classes.listContainer}>
                    {(index === 2 && (
                      <SensorsList
                        handleAddClick={(value) => {
                          history.push({ addCount: value, index: 13 });
                        }}
                        handleListItemClick={(id) => {
                          history.push({ id, index: 3 });
                        }}
                        handleSaveScrollTop={this.handleSaveScrollTop('sensorsList')}
                        initialScrollTop={this.getScrollTop('sensorsList')()}
                        sensors={filtered}
                      />
                    ))
                      || (index === 4 && (
                        <RelaysList
                          handleAddClick={(value) => {
                            history.push({ addCount: value, index: 15 });
                          }}
                          handleListItemClick={(id) => {
                            history.push({ id, index: 5 });
                          }}
                          handleSaveScrollTop={this.handleSaveScrollTop('relaysList')}
                          initialScrollTop={this.getScrollTop('relaysList')()}
                          relays={filtered}
                        />
                      ))
                      || (index === 6 && (
                        <AnalogOutsList
                          analogOuts={filtered}
                          handleAddClick={(value) => {
                            history.push({ addCount: value, index: 17 });
                          }}
                          handleListItemClick={(id) => {
                            history.push({ id, index: 7 });
                          }}
                          handleSaveScrollTop={this.handleSaveScrollTop('analogOutsList')}
                          initialScrollTop={this.getScrollTop('analogOutsList')()}
                        />
                      ))
                      || (index === 8 && (
                        <ZonesList
                          handleAddClick={() => {
                            history.push({ index: 19 });
                          }}
                          handleListItemClick={(id) => {
                            history.push({ id, index: 9 });
                          }}
                          handleSaveScrollTop={this.handleSaveScrollTop('zonesList')}
                          initialScrollTop={this.getScrollTop('zonesList')()}
                          zones={filtered}
                        />
                      ))}
                  </div>
                </div>
              </FadeSlideReplace>
              <UploadDialog
                handleClose={this.handleUploadClose}
                handleConfirmClick={this.handleUploadConfirmClick}
                open={openUpload}
              />
            </div>
          </ThemeProvider>
        </FadeSlideReplace>
      </AbilityContext.Provider>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);

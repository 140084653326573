import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AddFabDialog from './components/AddFabDialog';
import Scroll from './components/Scroll';
import Separator from './components/Separator';
import { Auth } from './utils/auth';
import has from './utils/has';
import { iconFabSize, tableHead } from './utils/styles';
import { relayLatchingText, relayStateText } from './utils/utils';

const styles = theme => ({
  activeState: {
    fontStyle: 'italic',
    lineHeight: '22px',
  },
  fab: {
    backgroundColor: theme.palette.background.default,
    bottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
    color: theme.palette.secondary.main,
    position: 'absolute',
    right: theme.spacing(2),
  },
  iconFabSize,
  normalState: {
    lineHeight: '22px',
  },
  noValue: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.background.paper,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0, 0.5),
  },
  noWrapZone: {
    maxWidth: 596,
  },
  secondaryText: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  tableCell: {
    '&:first-child': {
      paddingLeft: theme.spacing(3),
    },
  },
  tableFooter: {
    height: theme.spacing(4),
  },
  tableHead,
  tableValue: {
    lineHeight: '22px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  tableWrapper: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  wrapper: {
    height: '100%',
  },
});

class RelaysList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
  }

  handleAddClick() {
    this.setState({ open: true });
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  handleConfirmClick(value) {
    const { handleAddClick: handleAddClickProp } = this.props;

    handleAddClickProp(value);
    this.setState({ open: false });
  }

  render() {
    const {
      classes, handleListItemClick, handleSaveScrollTop, relays, initialScrollTop,
    } = this.props;
    const { open } = this.state;

    return (
      <>
        <div className={classes.wrapper}>
          <Scroll
            className={classes.tableWrapper}
            handleSaveScrollTop={handleSaveScrollTop}
            initialScrollTop={initialScrollTop}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.tableCell, classes.tableHead, classes.secondaryText)}>
                    ID
                  </TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Zones</TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Type</TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Normal state</TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Device</TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Lane</TableCell>
                  <TableCell className={clsx(classes.tableHead, classes.secondaryText)}>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relays.map(item => (
                  <TableRow hover key={item.id.toString()} onClick={() => handleListItemClick(item.id)}>
                    <TableCell className={clsx(classes.tableCell, classes.tableValue)}>{item.id}</TableCell>
                    <TableCell className={classes.tableValue}>
                      {item.zones.length !== 0 || item.id === item.settings.fault.relay ? (
                        <Typography className={classes.noWrapZone} noWrap variant="body2">
                          {item.zones.map(value => value.name).join(', ')}
                        </Typography>
                      ) : (
                        <Typography className={classes.noValue} variant="caption">
                          NONE
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableValue}>{relayLatchingText(item.latching)}</TableCell>
                    <TableCell className={classes.tableValue}>{relayStateText(item.normalState)}</TableCell>
                    <TableCell className={classes.tableValue}>{item.type}</TableCell>
                    <TableCell className={clsx(classes.tableValue, item.duplicate && classes.warning)}>
                      {item.lane}
                    </TableCell>
                    <TableCell className={clsx(classes.tableValue, item.duplicate && classes.warning)}>
                      {has.call(item, 'channel') ? (
                        <>
                          {has.call(item, 'address') && (
                            <>
                              {`${item.address}`}
                              <Separator />
                            </>
                          )}
                          {`${item.channel}`}
                        </>
                      ) : (
                        item.address
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.tableFooter} />
              </TableFooter>
            </Table>
          </Scroll>
          <Auth do="create" on="device">
            <Fab className={classes.fab} onClick={this.handleAddClick}>
              <AddIcon className={classes.iconFabSize} />
            </Fab>
          </Auth>
        </div>
        <AddFabDialog
          handleCancelClick={this.handleCancelClick}
          handleConfirmClick={this.handleConfirmClick}
          open={open}
          type="relays"
        />
      </>
    );
  }
}

RelaysList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSaveScrollTop: PropTypes.func.isRequired,
  initialScrollTop: PropTypes.number.isRequired,
  relays: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(RelaysList);
